import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Formik, Field } from "formik";

//component imports
import { tokens } from '../../theme';
import AlertMessage from '../../components/AlertMessage';
import { UserEmailContext, UserIDContext } from '../../App';
import SuccessMessage from '../../components/SuccessMessage';

function TeamLogInAccess() {
    //constants
    const location = useLocation();
    const {email, name, id, phoneNumber, role} = location.state;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const userEmail = useContext(UserEmailContext)
    const parentId = useContext(UserIDContext)

    //states
    const [userIdToRequest, setUserIdToRequest] = useState()
    const [successMessage, setSuccessMessage] = useState()

    //useEffects
    useEffect(() => {

        const listofL = []
        const listUsers = async(nextToken)=>{
          const token = nextToken || ''
            try{
              const users = await API.graphql(graphqlOperation(`query MyQuery {
                listUsers(
                  filter: {email: {eq: "${email}"}},
                  limit: 1000
                  ${token}
                ) {
                  items {
                    id
                  }
                  nextToken
                }
              }
              `));
              if(users.data.listUsers.items){
                listofL.push(users.data.listUsers.items);
                if(users.data.listUsers.items.nextToken){
                  listUsers(`,nextToken: "${users.data.listUsers.items.nextToken}"`)
                }else{
                  const mergedList = [].concat(...listofL);
                  if(mergedList.length < 1){
                    setUserIdToRequest('none');                
                  }else{
                    setUserIdToRequest(mergedList[0])
                  }
                }
              }
            }catch(e){
              console.log('Error getting users: ',e)
            }
        }
        listUsers()
    }, [])

    function handleFormSubmit(values) {
        const {accessLevel} = values

        const updateUser = async()=>{
            try{
              const updatedUser = await API.graphql(graphqlOperation(`mutation MyMutation {
                updateUser(input: {
                    id: "${userIdToRequest?.id}", 
                    userAttribue2: "${accessLevel};${userEmail};${parentId};${id}",
                    status: "" 
                }) {
                  id
                }
              }
              `));
              if(updatedUser){
                setSuccessMessage(`Request sent. Please ask ${name} to log in and Approve`);   
              }
            }catch(e){
              console.log('Error submitting request: ',e)
            }
        }
        updateUser()
    }

    const initialValues = {accessLevel: 'viewer'}

  return (
    <Box>
        {successMessage && <SuccessMessage message={successMessage}/>}
        {userIdToRequest === 'none' && <Box mt="30px" maxWidth={'800px'} 
            display={'flex'} gap="20px" flexDirection={'column'}>
            <Typography variant="h4" color={colors.redAccent[300]}>
                {`LoanTabs does not have any users with email adddress`}
                <Typography variant="h4" color={colors.blueAccent[300]}>{email}</Typography></Typography>
            <Typography variant="h4" color={colors.redAccent[300]}>
                Only Team Members with existing LoanTabs Accounts can be granted access to your business</Typography>
            <Typography variant="h4" color={colors.greenAccent[300]}> {`Does`}
                <span style={{color: colors.blueAccent[300], marginRight: '7px', marginLeft: "7px"}}>
                    {`${name}`}</span>{`already have a LoanTabs account?`}</Typography>
            <Box display="flex" flexDirection={'column'} pl="15px">
                <Typography variant="h5" color={colors.blueAccent[200]}>YES. {name} has a LoanTabs Account.</Typography>
                <Box border="1px solid white" mt="5px" p="20px" display="flex" flexDirection={'column'} gap="15px">
                    <Typography> STEP 1: Select "Manage Team" on the left menu.</Typography>
                    <Typography> STEP 2: Select {name} and Enable Editing.</Typography>
                    <Typography> STEP 3: Update {name}'s email address to ensure it is the same that they used to register for LoanTabs. </Typography>
                    <Typography> STEP 4: Then click "ENABLE LOG IN ACCESS FOR {name.toUpperCase()}"</Typography>
                </Box>
                <Typography variant="h5" mt="20px" color={colors.blueAccent[200]}>
                    NO. {name} does not have a LoanTabs Account.</Typography>
                <Box border="1px solid white" mt="5px" p="20px" display="flex" flexDirection={'column'} gap="15px">
                    <Typography> STEP 1: Ask {name} to go to www.LoanTabs.com and register an account using {email}</Typography>
                    <Typography> STEP 2: After {name} has created their own LoanTabs account, Log in to your LoanTabs Account, and select "Manage Team" on the left menu.</Typography>
                    <Typography> STEP 3: Select {name} and then click "ENABLE LOG IN ACCESS FOR {name.toUpperCase()}"</Typography>
                </Box>
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap="40px" pb="80px">
                <Button variant="outlined" color="info" onClick={
                    ()=> navigate(`/createUserAdmin/${id}/${name}/${email}/${phoneNumber}/${role}`)}>
                        Go Back</Button>
            </Box>
        </Box>}
        {userIdToRequest?.id && <Box>
            <Typography variant="h2">Enable Log In Access for {name}</Typography>
            <Typography variant="h5" mt="20px" mb="10px" color={colors.greenAccent[300]}>
                What Access Level do you want to allow {name}</Typography>
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                >
                {
                    ({handleSubmit})=>(
                        <form onSubmit={handleSubmit}>
                        <Box gap="10px" sx={{display: 'flex', flexDirection: 'column', }}>
                            <Box>
                                <Field type="radio" name="accessLevel" value="editor" />
                                {`Editor`}
                                <AlertMessage buttonText={'Explain more'}
                                message={<Box>
                                <Typography>Editors can modify any details (create loans, add payments, delete borrowers etc.).</Typography>
                                <Typography>Ideal access level for Administrators and Team Leaders.</Typography>
                                </Box>}/>
                            </Box>
                            <Box pb="20px">
                                <Field type="radio" name="accessLevel" value="viewer" />
                                {`Viewer`}
                                <AlertMessage buttonText={'Explain more'}
                                message={<Box>
                                <Typography>Viewers have "Read Only" access. They can view and print loan statements but cannot modify them.</Typography>
                                <Typography>Ideal access level for Sales Agents and Account Managers.</Typography>
                                </Box>}/>
                            </Box>
                            <Button type='submit' variant="outlined" color="success">
                                Send Request to {name}</Button>
                        </Box>
                        </form>
                    )
                }
            </Formik>
        </Box>}
    </Box>

  )
}

export default TeamLogInAccess