import React, { useEffect, useContext } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Header from "../../components/Header";
import { UserIDContext } from '../../App';
import { API, graphqlOperation} from 'aws-amplify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import SuccessMessage from "../../components/SuccessMessage";
import PointsManager from "../../components/PointsManager";
import './loans.css'

const NewLoan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userID = useContext(UserIDContext)
  const navigate = useNavigate();
  
  const {firstname, othername} = useParams()

  const [borrowers, setBorrowers] = useState([
    {id: 1, fullname: 'Loading...', phoneNumber: 'Loading...'}])
  const [createBorrower, setCreateBorrower] = useState()
  const [width, setWidth] = React.useState('800px');

  React.useEffect(() => {
    function handleWindowResize() {
      const wide = Math.min(window.innerWidth, 800) - 60
      setWidth(`${wide}px`);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(()=>{

    const listofB = []

    const getBorrowers = async(nextToken)=>{
      const token = nextToken || ''
      try{
        const listOfBorrowers = await API.graphql(graphqlOperation(`query MyQuery {
          listBorrowers(
            filter: {userBorrowersId: {eq: "${userID}"}},
            limit: 1000
            ${token}
          ) {
            nextToken
            items {
              firstname
              id
              othername
              phoneNumber
              otherPhoneNumber
            }
          }
        }`));
        if(listOfBorrowers.data.listBorrowers.items) {
          const borrowers2 = listOfBorrowers.data.listBorrowers.items.map(obj => ({
            ...obj,
            label: "Create Loan",
            fullname: obj?.firstname + " " + obj?.othername
          }));
          listofB.push(borrowers2)
          if(listOfBorrowers.data.listBorrowers.nextToken){
            getBorrowers(`,nextToken: "${listOfBorrowers.data.listBorrowers.nextToken}"`)
          }else{
            const mergedList = [].concat(...listofB);
            mergedList.length >0 ? setBorrowers(mergedList) : setCreateBorrower(true)
          }
        }
      }catch(e){
        console.log('Error: ',e)
      }
    }
    getBorrowers()
    // eslint-disable-next-line
  },[])

  const handleRowClick =(params)=>{
    if(params.row.fullname !== 'Loading...'){
      navigate(`/newLoanByBorrower/:${params.id}/${params.row.fullname}`)
    }
  }

  const columns = [
    
    {
      field: "fullname",
      headerName: "Borrower",
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      cellClassName: "mobileHidden",
      headerClassName: "mobileHidden"
    },
    {
      field: "label",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (params.row.fullname !== 'Loading...'
          ?<Box    
          >
            <Button icon={<AddCircleOutlineIcon />}
              color="success" variant="contained">Create a Loan</Button>
          </Box>
          : ''
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          gap: "10px"
        }}
      >
        <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE A NAME TO SEARCH: </Typography>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box mt='20px' p="20px" display="flex" flexDirection={'column'}>
      <PointsManager />
      {firstname && <SuccessMessage 
        message={`New Borrower ${firstname.slice(1)} ${othername.slice(1)} sussessfully created!`}/>}
      {createBorrower && <Box display="flex" flexDirection={'column'} gap="20px">
        <Typography variant="h3">No Borrowers Found. </Typography>
        <Typography variant="h5">You need to create a Borrower first then you can assign them a loan
        </Typography>
        <Button color="info" variant="outlined"
          onClick={()=>{navigate('/createBorrower')}}>Create Borrower</Button>
      </Box>}
      {borrowers && !createBorrower && <Box display="flex" flexDirection={'column'} >
        <Header title="Create a New Loan"
          subtitle="Click on a Borrower to create a new loan for that borrower" />
        <Box
          m="40px 0 0 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              // borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <Box sx={{ pb: '40px'}}>
            <Box width={width}>
                <DataGrid rows={borrowers} columns={columns}
                  onRowClick={handleRowClick}
                  slots={{ toolbar: QuickSearchToolbar }}
                  initialState={{
                    sorting: {
                        sortModel: [{field: 'fullname', sort: 'asc'}],
                    },
                  }}
                  />
            </Box>
          </Box>
        </Box>
      </Box>}
    </Box>
  );
};

export default NewLoan;