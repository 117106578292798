import React, { useContext, useEffect, useState } from "react";
import { Box,  Button,  Typography,  useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { tokens } from "../theme";
import '../assets/custom.css'
import '../index.css'
import { dateToday } from "../assets/getDate";
import { UserTypeContext } from "../App";
import generatePDF, { Margin } from "react-to-pdf";

const LoanSchedule = ({loanData, LoanAmount, InterestRate, startDate, LoanEndDate, loanCurrency,
  Installment, noOfInstallments, Period, editLoan, createLoan, Fees, borrower,
  durationToDisplay, durationFormatToDisplay}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const gridRef = useGridApiRef()
    const [printWidth, setPrintWidth] = useState(145)
    const [printHeaderGap, setPrintHeaderGap] = useState('60px')
    // eslint-disable-next-line
    const [printState, setPrintState] = useState(true)
    const [pdfSaving, setPdfSaving] = useState()
    
    const {businessName} = useContext(UserTypeContext)
    const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 

    const printBlackHeader = ()=> printWidth === 145 ? colors.grey[100] : '#000'
   
  const DataGridTitle = ()=>{
    return(
      <Box className="dataGridTitle" pb='30px'
        
      >
        <Box display='flex' flexDirection='column' alignItems='space-between'>
          {printWidth !== 145 && <Box display='flex' justifyContent='space-between' gap='20px' 
            mt={printWidth === 105 ? '-70px' : 0} 
            mb='10px'>
              <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
              <p style={{color: 'grey'}}>Printed on: {dateToday}</p>
          </Box>}
          <Box display='flex' flexDirection={'column'} mb="20px">
            {printWidth !== 145 && <Typography variant="h2" color={'black'}  
              sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
              {`${hasBusinessName.toUpperCase()}`}</Typography>}
            <Typography variant='h3' color={printBlackHeader()}> LOAN PAYMENT SCHEDULE</Typography>
          </Box>
          <Box display='flex' justifyContent='flex-start' 
            sx={{
              flexDirection: printWidth === 125 ? 'row' : {xs: 'column', md: 'row'},
              gap: printWidth !== 145 ? '45px' :  {xs: '20px', md: printHeaderGap},
            }}
            className='schedulePrintRow'
            >
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}} >
              <Typography color={printBlackHeader()} >Borrower:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{borrower.toUpperCase()}</Typography></Typography>
              <Typography color={printBlackHeader()} >Loan Amount:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${LoanAmount}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' 
              sx={{flexDirection: printWidth === 125 ? 'column' : {xs: 'row', md: 'column'}}}
              className='schedulePrintColumn'>
              <Typography color={printBlackHeader()} >Loan Start Date:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{startDate}</Typography></Typography>
              <Typography color={printBlackHeader()} >Interest Rate:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{InterestRate}% {Period}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' 
              sx={{flexDirection: printWidth === 125 ? 'column' : {xs: 'row', md: 'column'}}}
              className='schedulePrintColumn'>
            <Typography color={printBlackHeader()} >Loan End Date:<br/> 
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{LoanEndDate}</Typography></Typography>
              <Typography color={printBlackHeader()} >Instalment Amount:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${Installment}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' 
              sx={{flexDirection: printWidth === 125 ? 'column' : {xs: 'row', md: 'column'}}}
              className='schedulePrintColumn'>
            <Typography color={printBlackHeader()} >Loan Duration:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{durationToDisplay} {durationFormatToDisplay}</Typography></Typography>
              {Fees !== '0' ? <Typography color={printBlackHeader()} >Loan Fees:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${Fees}`}</Typography></Typography> : <Box>-</Box>}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const columns = [
    
    {
      field: "id",
      headerClassName: "dgHeaderPrint",
      headerName: "#",
      cellClassName: "name-column--cell",
      width: 35
    },
    {
      field: "date",
      headerClassName: "dgHeaderPrint",
      headerName: "Instalment Date",
      width: printWidth,
      cellClassName: "name-column--cell",
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "balance",
      headerClassName: "dgHeaderPrint",
      headerName: "Beginning Balance",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "printWithFlex",
    },
    {
      field: "principal",
      headerClassName: "dgHeaderPrint",
      headerName: "Principal Payment",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "printWithFlex",
    },
    {
      field: "interest",
      headerClassName: "dgHeaderPrint",
      headerName: "Interest Payment",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "printWithFlex",
    },
    {
      field: "totalPayment",
      headerClassName: "dgHeaderPrint",
      headerName: "Amount to Pay",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "printWithFlex",
    },
    {
      field: "closingBalance",
      headerClassName: "dgHeaderPrint",
      headerName: "Closing Balance",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "printWithFlex",
    },
  ];

  const columnsWithFlex = ()=>{
    return columns.map((field, index)=>{
      if(index >0){
        field.flex = printWidth === 145 ? 1 : '';
        return field;
      }else return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columnsWithFlex() : columns

  const downloadPDF = () => {

    setPdfSaving(true)
    setPrintWidth(125)
    setTimeout(()=>{
      generatePDF(() => document.getElementById("schedule"), {
        method: "save",
        filename: `${borrower.toUpperCase()} LOAN SCHEDULE.pdf`,
        page: { margin: Margin.MEDIUM },
      });
      setTimeout(()=>{
        setPdfSaving(false)
        setPrintWidth(145)
      },200)
    }, 500)
  };
  
  return (
    <Box mt="20px" id='schedule'
      sx={{ 
        width: printWidth === 125 ? '900px' : '100%',
      }}
      >
        {!pdfSaving && <Box display='flex' justifyContent="flex-start" flexWrap={'wrap'}
          sx={{gap: {xs: "20px", md: "30px"}, m: {xs: 0, md: '-53px 0 0 230px'}}}
          className='printNot'>
          <Button
            onClick={downloadPDF} color="info" variant="outlined"
            >Download PDF</Button>
          <Button
            className="desktopOnly"
            onClick={()=>{
              setPrintWidth(105)
              setTimeout(()=>window.print(), 500) 
              setTimeout(()=>{setPrintWidth(145); setPrintHeaderGap('60px');}, 550) 
            }}
            type="button" variant="outlined" color='info'>
            PRINT PAYMENT SCHEDULE</Button>
          <Button onClick={createLoan} type="button" color="success" variant="contained">
            CONFIRM & SAVE LOAN
          </Button>
        </Box>}
        <Box
          m="40px 0 0 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
            },
            "& .name-column--cell": {
              color: printWidth === 125 ? 'black !important' : colors.greenAccent[300],
            },
            "& .printWithFlex": {
              color: printWidth === 125 && 'black !important',
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: printWidth === 125 ? 'white' : colors.blueAccent[700],
              color: printWidth === 125 && 'black !important',
              fontWeight: printWidth === 125 && `700 !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: printWidth === 125 ? 'white' : colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: printWidth === 125 ? 'white' : colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: printWidth === 125 ? 'black !important' : `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGridTitle />
          <Box >
            {initialColumns && <DataGrid rows={loanData} columns={initialColumns}
              apiRef={gridRef}
              autoHeight={true}
              hideFooter={true}
              sx={{
                '@media print': {
                  '.MuiDataGrid-main': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                },
                '@media (max-width: 900px)': {
                  width: "100%",
                  overflowX: 'auto'
                }
              }}
              columnVisibilityModel={{
                principal: printState,
                interest: printState
              }}
            />}  
          </Box>
      </Box>
    </Box>
  );
};

export default LoanSchedule;