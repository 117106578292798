import moment from "moment"

const configureTransactions = (transactions, startDate, endDate) =>{

    const iRows = []
    const eRows = [{id: "E", item: 'EXPENSES'}]
    if(transactions.length > 0){
        //get stand alone loan fees
        const filteredLoanFees1 = transactions.map((transaction) => {
            return transaction.loanFees.items.filter((item) => {
                return moment(item?.loanFees?.loan?.startDate).isSameOrAfter(moment(startDate))
                && moment(item?.loanFees?.loan?.startDate).isSameOrBefore(moment(endDate))
            })
        })
        const loanFees1 = filteredLoanFees1.map((transaction)=>{
            return transaction.reduce((acc, item)=>(
                acc + item?.loanFees?.amount
            ),0)
        }).reduce((acc, item)=> acc + item , 0)
        //get loanFees from loans
        const filteredLoanFees2 = transactions.map((transaction) => {
            return transaction.loans.items.filter((item) => {
                return moment(item?.loan?.startDate).isSameOrAfter(moment(startDate))
                && moment(item?.loan?.startDate).isSameOrBefore(moment(endDate))
            })
        })
        const loanFees2 = filteredLoanFees2.map((loans)=>{
            return loans ? 
            loans.reduce((acc, item)=>{
               return item ? acc + item?.loan?.fees : 0 + acc
            },0)
            : 0
        }).reduce((acc, item)=> acc + item , 0)

        //get other incomes and expenses
        const incomes = {otherIncome: 0}
        const expenses = {otherExpense: 0}
        transactions.forEach((transaction)=>{
            transaction.transactions.items.forEach((item)=>{
                if(item?.type === 'income'){
                    if(item?.attribute1){
                        if(!incomes[item.attribute1]){
                            incomes[item.attribute1] = item.amount
                        }else {
                            incomes[item.attribute1] += item.amount
                        }
                    }else{
                        incomes.otherIncome += item.amount
                    }
                }
                if(item?.type === 'expense'){
                    if(item?.attribute1){
                        if(!expenses[item.attribute1]){
                            expenses[item.attribute1] = item.amount
                        }else {
                            expenses[item.attribute1] += item.amount
                        }
                    }else{
                        expenses.otherExpense += item.amount
                    }
                }
            })
        })
                
        //push to iRows
        iRows.push({
            id: 'I2', 
            item: 'Income from Loan Fees',
            amount: loanFees1 + loanFees2
        })
        //push incomes
        let iCount = 3; 
        for(const item in incomes){
            if(incomes[item] >0){
                iRows.push({
                    id: `I${iCount}`,
                    item: item === 'otherIncome' ? 'Miscellaneous Incomes' : item,
                    amount: incomes[item]
                })
                iCount += 1;
            }
        }

        //push expenses
        let eCount = 1; 
        for(const item in expenses){
            if(expenses[item] >0){
                eRows.push({
                    id: `E${eCount}`,
                    item: item === 'otherExpense' ? 'Miscellaneous Expenses' : item,
                    amount: expenses[item]
                })
                eCount += 1;
            }
        }
        return {iRows, eRows}
    }
}
export { configureTransactions}