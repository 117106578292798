import React from 'react'
import { Box, Button, Typography } from '@mui/material'
  import { useNavigate } from 'react-router-dom'

function ViewerLimitNotice() {

  const navigate = useNavigate()
  return (
    <Box maxWidth={'100%'}>
      <Box m='20px' gap="20px" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <img 
              src="https://www.loantabs.com/wp-content/uploads/2023/09/blocked.png" alt="blocked" 
              style={{ width: '200px', height: 'auto'}} 
            />
        <Box m='20px' gap="20px" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
          <h1>ACCESS DENIED</h1>
          <Typography>Your account has not been granted access to this feature.</Typography>
          <Typography>Please contact your System Administrator / Parent Account Manager.</Typography>
          <Button onClick={()=>navigate('/')} sx={{width: '150px'}}
          color='info' variant='contained'>Return to Dashboard</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ViewerLimitNotice