import React from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Button } from '@mui/material';
import { useContext } from 'react'
import { UserIDContext } from '../App';
import {UserEmailContext} from '../App'
import { UserTypeContext } from '../App';
import { UserTypeChangeDateContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { API, graphqlOperation} from 'aws-amplify';

import moment from 'moment';
import { useState } from 'react';
import { loanTabsPackages } from '../assets/packages';

const FlutterwaveHook =({paymentAmount, buttonText, cardTitle, buttonColor, 
  currency, disabled})=> {

  const userID = useContext(UserIDContext)
  const userEmail = useContext(UserEmailContext)
  const {setUserTypeAWS, setUserTypeLocal, setDaysLeft} = useContext(UserTypeContext)
  const {daysLeft} = useContext(UserTypeChangeDateContext)
  const navigate = useNavigate()
  const today = moment();
  const {PRO, CORPORATE} = loanTabsPackages

  const subscriptionDuration = (duration) =>{
    const expiryDate = daysLeft >0 
      ? today.add(daysLeft +duration, 'days').format('LL') 
      : today.add(duration, 'days').format('LL');
  
    const todayDate = moment()
    const expiryDateMoment = moment(expiryDate)
    return {expiryDate, subscriptionDays: expiryDateMoment.diff(todayDate, 'days')}
  }

  const config = {
      public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
      tx_ref: Date.now(),
      amount: paymentAmount,
      currency: currency,
      payment_options: 'card,mobilemoney,ussd',
      customer: { 
        email: userEmail,
        name: userEmail
      },
      customizations: {
        title: `LoanTabs ${cardTitle} Subscription`,
        description: `Payment for ${cardTitle}`,
        logo: 'https://www.loantabs.com/wp-content/uploads/2023/09/loanbooks_co-loan-management-system-logo.png',
      },
      meta: {
        appSyncId: userID
      }
  };

  const currencies = new Set(['USD', 'INR', 'BWP'])
  if(currencies.has(currency)) {  
    switch (paymentAmount) {
      case PRO[`${currency}_monthly`]:
        config.payment_plan = PRO[`${currency}_monthly_planId`];
        break;
      case CORPORATE[`${currency}_monthly`]:
        config.payment_plan = CORPORATE[`${currency}_monthly_planId`];
        break;
      default:
        break;
    }
  }

  const paidForPro = async(duration) => {
    const {expiryDate, subscriptionDays} = subscriptionDuration(duration);
    try{
      const proUser = await API.graphql(graphqlOperation(`mutation MyMutation {
        updateUser(input: {
          id: "${userID}", 
          userType: "PRO", 
          userTypeChangeDate: "${expiryDate}", 
        }) 
        {
          id
        }
      }
      `))     
      if(proUser){
        setDaysLeft(subscriptionDays)
        setUserTypeAWS('PRO')
        setUserTypeLocal('PRO')
        setTimeout(()=> {
          navigate(`/`)
        }, 2000)
      }
    }
    catch(e){
      console.log('User Upgrade Failed', e)
    }
  }

  const paidForCorporate = async(duration) =>{
    const {expiryDate, subscriptionDays} = subscriptionDuration(duration);
    try{
      const proUser = await API.graphql(graphqlOperation(`mutation MyMutation {
        updateUser(input: {
          id: "${userID}", 
          userType: "CORPORATE", 
          userTypeChangeDate: "${expiryDate}"
        }) 
        {
          id
        }
      }
      `))     
      if(proUser){
        setDaysLeft(subscriptionDays)
        setUserTypeAWS('CORPORATE')
        setUserTypeLocal('CORPORATE')
        setTimeout(()=> {
          navigate(`/`)
        }, 2000)
      }
    }
    catch(e){
      console.log('User Upgrade Failed', e)
    }
  }

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <Button
      sx={{backgroundColor: buttonColor, width: '100%'}}
      disabled={disabled}
      variant='contained'
      onClick={() => {
        handleFlutterPayment({
          callback: (response) => {
              if(response){
                  if(response.amount === PRO[`${currency}_monthly`]){
                    paidForPro(31)
                  }
                  if(response.amount === CORPORATE[`${currency}_monthly`]){
                    paidForCorporate(31)
                  }
                  if(response.amount === (PRO[`${currency}_yearly`] * 12)){
                    paidForPro(365)
                  }
                  if(response.amount === (CORPORATE[`${currency}_yearly`] * 12)){
                    paidForCorporate(365)
                  }
              }
              closePaymentModal() // this will close the modal programmatically
          },
          onClose: () => {},
        });
      }}
    >
      {buttonText}
    </Button>
  );
}

export default FlutterwaveHook