import * as React from 'react';
import {useTheme, Box, Typography} from '@mui/material';
import {
  DataGrid,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useState, useEffect, useContext } from 'react';
//component imports
import { getAllTransactions } from '../../../assets/configureAccounts';
import { tokens } from '../../../theme';
import { UserTypeContext } from '../../../App';

export default function ViewAllTransactions ({accounts, informationHeading}) {
  const [rows, setRows] = useState();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {decimalPoints} = useContext(UserTypeContext);

  useEffect(()=>{
    if(accounts){
      setRows(getAllTransactions(accounts))
     }
  },[]) 

  const columns = [
    {
        field: "date",
        sortable: true,
        headerName: "Date",
        flex: 0.8,
        type: 'date',
        cellClassName: "name-column--cell",
        renderCell: (params)=>{
          const options = { day: "2-digit", month: "short", year: "2-digit" };
          return params.row.date ? params.row.date.toLocaleDateString("en-UK", options) : ''
        },
    },
    {
        field: "details",
        sortable: false,
        headerName: "Transaction Details",
        flex: 1.5,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: "transactionType",
        sortable: true,
        headerName: "Transaction Type",
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'text',
    },
    {
        field: "client",
        sortable: true,
        headerName: "Client / Staff",
        flex: 1,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: "debit",
        sortable: false,
        headerName: "Debit",
        flex: 1,
        align: 'right',
        cellClassName: 'debit',
        headerAlign: 'right',
        renderCell: (params)=>{
          return params?.row?.debit ? params.row.debit.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
}) : ''
        }
    },
    {
        field: "credit",
        sortable: false,
        headerName: "Credit",
        cellClassName: 'credit',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params)=>{
          return params?.row?.credit ? params.row.credit.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
}) : ''
        }
    },
    {
      field: "account",
      sortable: true,
      headerName: "Account",
      flex: 1.2,
      align: 'left',
      headerAlign: 'left',
  },
  ];

  const columnsWithoutFlex = ()=>{
    return columns.map((field, index)=>{
      field.key = index;
      field.flex = '';
      return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          ml: "20px",
          gap: "10px"
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // height: 500,
        width: '100%',
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal"
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          height: "unset !important",
        },
        "& .MuiDataGrid-root": {
          
          // borderColor: colors.grey[100],
        },
        "& .MuiDataGrid-cell": {
          borderTop: '0.2px solid',
          borderColor: colors.grey[300],
          paddingTop: "3px",
          // minHeight: "50px !important",
          paddingBottom: "3px",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .normalLineHeight": {
          height: `200px !important`,
        },
        " .debit": {
          color: colors.greenAccent[200],
        },
        " .credit": {
          color: colors.redAccent[200],
        },
      }}
    >  
      <Box display="flex" justifyContent={'space-between'} flexDirection={'column'} mb="60px">
        <Typography variant="h3">All Transactions</Typography>
        {informationHeading}
      </Box> 
      {rows &&
      <DataGrid
        rows={rows}
        columns={initialColumns}
        slots={{ toolbar: QuickSearchToolbar }}
        initialState={{
            sorting: {
                sortModel: [{field: 'date', sort: 'desc'}],
                sortable: false,
            },
        }}
        sx={{
          '@media (max-width: 900px)': {
            width: "100%",
            overflowX: 'auto'
          }
        }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />}
    </Box>
  );
}