import React, { useEffect, useContext } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { UserIDContext } from '../../App';
import { API, graphqlOperation} from 'aws-amplify';
import { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import SuccessMessage from "../../components/SuccessMessage";
import '../../assets/custom.css'
import { configureAccounts, updateAccounts, harvestTokens } from "../../assets/configureAccounts";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewAccounts from "./AccountCategories/ViewAccounts";
import GridTextInput from "../../components/GridTextInput";
import EditIcon from '@mui/icons-material/Edit';
import ViewAllTransactions from "./AccountCategories/ViewAllTransactions";
import { UserTypeContext } from "../../App";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
};
}

const ManageAccounts = () => {
  const [accounts, setAccounts] = useState()
  const [value, setValue] = useState(0);
  const [panelHidden, setPanelHidden] = useState()
  const [accountNameEdit, setAccountNameEdit] = useState()
  const [transactions, setTransactions] = useState()
  const [accountName, setAccountName] = useState()
  const [accountId, setAccountId] = useState()
  const [openingBalance, setOpeningBalance] = useState()
  const [createdAt, setCreatedAt] = useState()

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userID = useContext(UserIDContext)
  const {accountSummary} = useParams()
  const [accountNotice, setAccountNotice] = useState()
  const [nextTokens, setNextTokens] = useState()
  const navigate = useNavigate()
  const {decimalPoints} = useContext(UserTypeContext);

  useEffect(()=>{
    const listAccounts = async(depositNextToken1, transactionsNextToken1, expensesNextToken1,
      loanFeesNextToken1, investmentsNextToken1, loansNextToken1,
      paymentsNextToken1, withdrawalNextToken1)=>{

        const depositNextToken = depositNextToken1 || ''
        const transactionsNextToken = transactionsNextToken1 || ''
        const expensesNextToken = expensesNextToken1 || ''
        const loanFeesNextToken = loanFeesNextToken1 || ''
        const investmentsNextToken = investmentsNextToken1 || ''
        const loansNextToken = loansNextToken1 || ''
        const paymentsNextToken = paymentsNextToken1 || ''
        const withdrawalNextToken = withdrawalNextToken1 || ''
      
        try{
        const listOfAccounts = await API.graphql(graphqlOperation(`query MyQuery {
          listAccounts(
            filter: {userAccountsId: {eq: "${userID}"}},
            limit: 1000
          ) {
            items {
              accountName
              currency
              createdAt
              id
              openingBalance
              accountStatus
              deposits(filter: {id: {attributeExists: true}}, limit: 1000
                ${depositNextToken}
                ) {
                nextToken
                items {
                  amount
                  depositDetails
                  depositDate
                  id
                }
              }
              transactions(filter: {id: {attributeExists: true}}, limit: 1000
                ${transactionsNextToken}
                ) {
                nextToken
                items {
                  date
                  details
                  id
                  type
                  amount
                }
              }
              expenses(filter: {id: {attributeExists: true}}, limit: 1000
                ${expensesNextToken}
                ) {
                nextToken
                items {
                  expense {
                    amount
                    expenseDetails
                    expenseDate
                    id
                  }
                }
              }
              loanFees(
                filter: {loanFeesId: {attributeExists: true}, id: {attributeExists: true}},
                limit: 1000
                ${loanFeesNextToken}
              ) {
                nextToken
                items {
                  loanFees {
                    amount
                    loan {
                      startDate
                      principal
                      borrower {
                        firstname
                      }
                    }
                  }
                }
              }
              investments(filter: {id: {attributeExists: true}}, limit: 1000
                ${investmentsNextToken}
                ) {
                nextToken
                items {
                  investment {
                    principal
                    startDate
                    investor {
                      firstname
                      othername
                    }
                  }
                }
              }
              loans(filter: {loanId: {attributeExists: true}}, limit: 1000
                ${loansNextToken}
                ) {
                nextToken
                items {
                  loan {
                    fees
                    principal
                    startDate
                    borrower {
                      firstname
                      othername
                    }
                  }
                }
              }
              payments(filter: {id: {attributeExists: true}}, limit: 1000
                ${paymentsNextToken}
                ) {
                nextToken
                items {
                  payment {
                    amount
                    paymentDate
                    loan {
                      principal
                      borrower {
                        firstname
                        othername
                      }
                    }
                  }
                }
              }
              withdrawals(filter: {id: {attributeExists: true}}, limit: 1000
                ${withdrawalNextToken}
                ) {
                nextToken
                items {
                  amount
                  id
                  withdrawalDate
                  withdrawalDetails
                }
              }
            }
          }
        }
        `));
        if(listOfAccounts.data.listAccounts.items.length > 0) {
          setAccounts(configureAccounts(listOfAccounts.data.listAccounts.items))
          setNextTokens(harvestTokens(listOfAccounts.data.listAccounts.items))
        }
      }catch(e){
        console.log('Error Listing Accounts: ',e)
        if(e.data.listAccounts.items.length > 0) {
          setAccounts(configureAccounts(e.data.listAccounts.items))
        }
      }
    }
    listAccounts() 
    if(accountSummary) {
      setAccountNotice(true)
      setTimeout(()=> setAccountNotice(false), 5000)
    }
    // eslint-disable-next-line
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const hidePanel =(val) => {
    setPanelHidden(val)
    setTransactions(val)
  }; 

  //include new Transaction
  const includeNewTransaction = (accountId, transaction) => {
      const updatedTransactions = accounts.map(account =>{
        if(account.accountId === accountId){
          account.closingBalance = account.closingBalance + parseFloat(transaction.debit)
          - parseFloat(transaction.credit)
          account.transactions.push(transaction)
          account.transactions.sort((a, b) => a.date - b.date)
          return account
        }else return account
      })
      setAccounts(updatedTransactions)
  }
  
  //update Transactions
  const updateTransactions = (accountId, transaction) =>{
    const updatedTransactions = accounts.map(account =>{
      if(account.accountId === accountId){
        const newTransactions = account.transactions.map(oldTransaction => {
          if(oldTransaction.id === transaction.id){
            return transaction
          }else return oldTransaction
        })
        newTransactions.sort((a, b) => a.date - b.date)
        account.transactions = newTransactions
        //updated closing balance
        account.closingBalance = account.openingBalance 
        + newTransactions.reduce((acc, transaction) => acc + transaction?.debit - transaction?.credit, 0)

        return account
      }else return account
    })
    setAccounts(updatedTransactions)
  }

  //delete Transactions
  const deleteTransaction = ({accountId, transactionId, debit, credit}) => {
    const updatedTransactions = accounts.map(account =>{
      if(account.accountId === accountId){
        account.closingBalance = account.closingBalance - debit + credit
        const transactions = [...account.transactions]
        account.transactions = transactions.filter(
          transaction => transaction.id !== transactionId)
        return account
      }else return account
    })
    setAccounts(updatedTransactions)
  }

  const activeAccounts = () => {
    return accounts.filter(account => account.accountStatus !== 'inactive')
  }
  const inactiveAccounts = () => {
    return accounts.filter(account => account.accountStatus === 'inactive')
  }
  const updateAccount = async(accountId, accountStatus, accountName)=>{
    const nameUpdate = accountName ? `accountName: "${accountName}",` : "" ;
    const statusUpdate = accountStatus ? `accountStatus: "${accountStatus}"` : "" ;
    try{
      const updatedAccount = await API.graphql(graphqlOperation(`
        mutation MyMutation {
          updateAccount(input: {
            id: "${accountId}", 
            ${nameUpdate}
            ${statusUpdate}
          }){
            id
          }
        }
      `));
      if(updatedAccount) {
        setAccounts(updateAccounts(accounts, accountId, accountStatus, accountName));
        setAccountNameEdit(false)
      }
    }catch(e){
      console.log('Error Updating Accounts: ',e)
    }
  }

  const columns = [
    {
      field: "accountName",
      headerName: "Account Name",
      flex: 2.2,
      cellClassName: "name-column--cell",
      renderCell: (params)=>{
        return(
          <Box>
            {accountNameEdit !== params.row.accountId && <Box display="flex" flexDirection={'row'} gap="5px">
              <Typography>{params.row.accountName}</Typography>
              <EditIcon className="printNot" fontSize="small" color="warning" 
                cursor="pointer" onClick={()=>{
                  setAccountNameEdit(params.row.accountId)
                }} />
            </Box>}
            {accountNameEdit === params.row.accountId && <GridTextInput 
                updateValue={(values)=>updateAccount(params.row.accountId, null, values.formerValue)} 
                cancelEdit={()=>{
                  setAccountNameEdit(false)
                }} 
                deletePayment={null}
                oldValue={params.row.accountName} type="text" />}
          </Box>
        )
      }
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "openingBalance",
      headerName: "Opening Balance",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params)=>{
        return params.row.openingBalance.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
});
      }
    },
    {
      field: "closingBalance",
      headerName: "Balance",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params)=>{
        return params.row.closingBalance.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
});
      }
    },
    {
      field: "label",
      headerName: "",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <Box p="5px">
            <Button variant="contained" color="info" 
              onClick={()=> {
                setAccountName(params.row.accountName)
                setOpeningBalance(params.row.openingBalance)
                setCreatedAt(params.row.createdAt)
                setTransactions(params.row.transactions)
                setAccountId(params.row.accountId)
                setPanelHidden(true)
              }}
              size="small"
            >
              Transactions
            </Button>
          </Box>
        )
      },
    },
    {
      field: "activeButton",
      headerName: "",
      flex: 1.2,
      renderCell: (params) => {
        if(params.row.accountStatus !== 'default'){
          return (
            <Box p="5px">
              {params.row.accountStatus !== 'inactive' && <Button variant="outlined" color="error" 
                onClick={()=> updateAccount(params.row.accountId, 'inactive', null)}
                size="small"
              >Deactivate</Button>}
              {params.row.accountStatus === 'inactive' && <Button variant="outlined" color="success" 
                onClick={()=> updateAccount(params.row.accountId, 'active', null)}
                size="small"
              >Activate</Button>}
            </Box>
          )
        }
      },
    },
  ];
  
  const columnsWithoutFlex = ()=>{
    return columns.map((field, index)=>{
      field.key = index;
      field.flex = '';
      return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  return (   
    <Box display='flex' flexDirection='column' mt="20px"
      sx={{width: {xs: '100%', md: '900px'}}}
    >
      {accountNotice && <SuccessMessage 
        message={`New Account ${accountSummary} created successfully`}/>}
      <Box display={'flex'} alignItems={'center'} gap={'20px'} mb='20px' justifyContent={'space-between'}
        sx={{
          display: panelHidden? 'none': 'flex',
          flexWrap: 'wrap',
        }}>
        <Typography sx={{fontSize: '30px', fontWeight: 600}}
          >ACCOUNTS PANEL</Typography>
        <Button color='success' variant='contained' 
          onClick={()=> navigate('/createAccount')}>ADD NEW ACCOUNT</Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', 
        display: panelHidden? 'none': 'flex'}}>
        <Tabs value={value} onChange={handleChange} textColor="secondary"
          indicatorColor="secondary" variant="scrollable"
          scrollButtons="auto">
          <Tab sx={{fontSize: 16}} label="ALL TRANSACTIONS" {...a11yProps(0)} />
          <Tab sx={{fontSize: 16}} label="ACTIVE ACCOUNTS" {...a11yProps(1)} />
          <Tab sx={{fontSize: 16}} label="INACTIVE ACCOUNTS" {...a11yProps(2)} />
          <Tab sx={{fontSize: 16}} label="ALL ACCOUNTS" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {accounts && <ViewAllTransactions accounts={accounts}
            informationHeading={<Typography  mt="20px" color={colors.greenAccent[300]} variant="h5">
              To add a new transaction (expense, deposit, withdrawal), please use the "Active Accounts" tab.
              </Typography>
            }/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {accounts && <ViewAccounts accounts={activeAccounts()} columns={initialColumns}
            hidePanel={hidePanel} updateAccount={updateAccount} transactions={transactions}
            accountName={accountName} openingBalance={openingBalance} createdAt={createdAt}
            accountId={accountId} includeNewTransaction={includeNewTransaction} 
            deleteTransaction={deleteTransaction} updateTransactions={updateTransactions}
            informationHeading={<Typography>
              <Typography>Active accounts can be used for transactions and expenses
              (disbursing loans, receiving payments, deposits, withdrawals etc.)</Typography>
              <Typography mt="20px" color={colors.greenAccent[300]} variant="h5">
                Select an account to View Transactions, register Expenses, make Deposits or Withdrawals</Typography>
            </Typography>              
            }/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {accounts && <ViewAccounts accounts={inactiveAccounts()} columns={initialColumns}
            hidePanel={hidePanel} updateAccount={updateAccount} transactions={transactions}
            accountName={accountName} openingBalance={openingBalance} createdAt={createdAt} 
            informationHeading={<Typography>
              Inactive accounts can no longer be used for any transactions unless reactivated
            </Typography>              
            }/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      {accounts && <ViewAccounts accounts={accounts} columns={initialColumns}
            hidePanel={hidePanel} updateAccount={updateAccount} transactions={transactions}
            accountName={accountName} openingBalance={openingBalance} createdAt={createdAt} />}
      </CustomTabPanel>
    </Box>
  );
};

export default ManageAccounts;