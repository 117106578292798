import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import moment from 'moment';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
//componet imports
import ReportDateRange from '../../components/ReportDateRange';
import { tokens } from '../../theme';
import { LoansContext } from '../../App';
import { UserTypeContext } from '../../App';

function BorrowersReport() {

    //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const {loansContext} = useContext(LoansContext)
    const {decimalPoints} = useContext(UserTypeContext);

    //states
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [rows, setRows] = useState()
    const [numberOfBorrowers, setNumberOfBorrowers] = useState()
    const [borrowers, setBorrowers] = useState()
    const [selectedBorrower, setSelectedBorrower] = useState()

    //useEffect
    useEffect(() => {
        const configureReport = () => {
            const loans = [...loansContext]
            const borrower = {}
            loans.forEach((loan, i) => {
                if(!borrower[loan.borrowerName]){
                    borrower[loan.borrowerName] = {
                        name: loan.borrowerName,
                        numberOfLoans: 1,
                        loans: [loan],
                        id: i
                    }
                }else{
                    borrower[loan.borrowerName].numberOfLoans++;
                    borrower[loan.borrowerName].loans.push(loan)
                }
            })
            const borrowerLoans = Object.values(borrower)
            borrowerLoans.sort((a, b)=> a.name.localeCompare(b.name))
            setBorrowers(borrowerLoans)
            setSelectedBorrower(borrowerLoans[0].name)
            createBorrowerReport(borrowerLoans[0].loans, borrowerLoans[0].numberOfLoans)            
            setNumberOfBorrowers(borrowerLoans.length)
        }
        loansContext && configureReport()
    },[startDate, endDate, loansContext])
    
    const createBorrowerReport = (loans, numberOfLoans) => {
        const getLoanValue = (value, status)=>{
            let sum = 0
            loans.forEach((loan)=>{
                if(status){
                    if(loan.status.includes(status)){
                        sum += loan[value]
                    }
                }else sum += loan[value]
            })
            return sum; 
        }
 
        const firstRow = {
            id: 0,
            status: 'All Loans',
            numberOfLoans,
            principal: getLoanValue('principal'),
            interest: getLoanValue('totalInterest'),
            payments: getLoanValue('totalPaid'),
        }
        firstRow.balance = firstRow.principal + firstRow.interest - firstRow.payments
        const rowsArray = [firstRow]

        const createRow = (status, id) => {
            const filteredLoans = loans.filter((loan) => loan.status.includes(status))
            if(filteredLoans.length > 0){
                const row = {
                    id,
                    status: status.toUpperCase(),
                    numberOfLoans: filteredLoans.length,
                    principal: getLoanValue('principal', status),
                    amountDue: getLoanValue('amountDue', status),
                    payments: getLoanValue('totalPaid', status),
                    balance: getLoanValue('balance', status)
                }
                row.interest = row.balance + row.payments - row.principal
                rowsArray.push(row)
                //second row
            }else{
                rowsArray.push({id, status: status.toUpperCase(), numberOfLoans: 0, 
                    principal: 0, interest: 0, payments: 0, balance: 0})
            }
        }
        let count = 1;
        createRow('current', count++)
        createRow('payment due', count++)
        createRow('overdue', count++)
        createRow('cleared', count++)
        
        setRows(rowsArray);
    }
    //columns
    const columns = [
        {
            field: "status",
            sortable: false,
            headerName: "Loan Status",
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            type: 'text',
        },
        {
            field: "numberOfLoans",
            sortable: false,
            headerName: "No. of Loans",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.numberOfLoans?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "principal",
            sortable: false,
            headerName: "Principal",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.principal?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "interest",
            sortable: false,
            headerName: "Interest + Penalties",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.interest?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "payments",
            sortable: false,
            headerName: "Payments",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.payments?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "balance",
            sortable: false,
            headerName: "Balance",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.balance?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
    ]

    const handleRowClick =(params)=>{
        const borrower = params.row
        setSelectedBorrower(borrower.name);
        createBorrowerReport(borrower.loans, borrower.numberOfLoans)
    }

    const tabColumns = [
        {
            field: "name",
            headerName: "Borrowers",
            flex: 1
        }
    ]
    function QuickSearchToolbar() {
        return (<GridToolbarQuickFilter />);
      }

      const columnsWithoutFlex = ()=>{
        return columns.map((field, index)=>{
          field.key = index; 
          field.flex = ''
          return field;
        })
      }
      
    const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();


  return (
    <Box  display="flex" flexDirection={'column'} mt="20px"
    sx={{ 
        width: {xs:'100%', md:'900px'},
      }}
    >
        <Box display={'flex'} alignItems={'center'} gap={'20px'} flexWrap={'wrap'}
            mb='20px' justifyContent={'space-between'}>
            <Typography variant="h2" >BORROWERS REPORT</Typography>
            <Button color='error' variant='outlined' 
                onClick={()=>navigate('/reportsPanel')}>Close Report</Button>
        </Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
            <Typography variant='h5' mr="10px" color={colors.greenAccent[200]}
                >Total Number of Borrowers: </Typography>
            <Typography variant='h4' mr="20px">{numberOfBorrowers}</Typography>
        </Box>
        <hr style={{width: '100%', marginTop: '20px', marginBottom: "20px"}}/>
        <Box display={'flex'} mt='20px' 
            sx={{flexDirection: {xs: 'column', md: 'row'}}}
            >
            <Box display={'flex'} flexDirection={'column'} width="200px" pr="5px"
                maxHeight={'60vh'} pb="20px"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        height: "20px",
                        border: "none",
                        borderBottom: `1px solid ${colors.grey[500]}`,
                    },
                    "& .Mui-selected" : {
                        backgroundColor: `${colors.blueAccent[800]} !important`,
                    }
                }}>
                {borrowers &&<DataGrid 
                    rows={borrowers} columns={tabColumns} 
                    hideFooter={true}
                    slots={{
                        columnHeaders: () => null,
                        toolbar: QuickSearchToolbar
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'name', sort: 'asc'}],
                        },
                    }}
                    onRowClick={handleRowClick}
                />}
            </Box>
            {rows && <Box width="720px" pb="60px" pl="40px"
                sx={{
                    '@media (max-width: 900px)': {
                        width: "100%",
                        pb: 0, pl: 0
                    },
                "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important"
                },
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    height: "30px",
                    border: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                ".balanceRow": {
                    borderBottom: "3px solid white",
                    color: colors.redAccent[200]
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .normalLineHeight": {
                    height: `200px !important`,
                },
                ".paymentRow": {
                    color: colors.blueAccent[200],
                }
                }}>
              <Box display={'flex'} flexDirection={'column'} >
                    <Box mb="10px" display="flex" flexDirection={'row'} alignItems={'baseline'}>
                        <Typography variant='h5' mr="5px"
                            color={colors.greenAccent[200]} >Borrower: </Typography>
                        <Typography variant='h4'>{selectedBorrower}</Typography>
                    </Box>
                    {initialColumns && <DataGrid rows={rows} columns={initialColumns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    autoHeight={true}
                    hideFooter={true}
                    getRowClassName={(params) => {
                        switch(params.row.status) {
                            case "Payments made":
                                return 'paymentRow';
                            case "Unpaid balance":
                                return 'balanceRow';
                            default: return ''
                        }
                    }}
                    sx={{
                        '@media (max-width: 900px)': {
                            width: "100%",
                            overflowX: 'auto'
                        }
                    }}
                    />}
              </Box>
            </Box>}
        </Box>
    </Box>
  )
}

export default BorrowersReport