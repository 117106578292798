import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useContext, useEffect } from "react";
import {UserIDContext} from "../../../App"
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { DatePickerField } from "../../../components/DatePicker";
import {countries} from "../../../assets/listOfCountries"
import Header from "../../../components/Header";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";

const CreateBorrowerForm = ({initialBorrower, readyToEdit, editedBorrower}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [processing, setProcessing] = useState()
  const userID = useContext(UserIDContext)
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [borrower, setBorrower] = useState()
  const [checked, setChecked] = useState(!initialBorrower)

  //useEffects
  useEffect(()=>{
    if(initialBorrower){
      setBorrower(initialBorrower)
      setProcessing('Save Changes')
      setChecked(false)
    }else{
      setProcessing('Create New Borrower')
    }
  },[initialBorrower])

  //functions
  const enableEditing =(e)=>{
    setChecked(e.target.checked);
  }
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a'

  const initialValues = {
    firstname: initialBorrower?.firstname  || "",
    phoneNumber: initialBorrower?.phoneNumber || "",
    othername: initialBorrower?.othername || "",
    uniqueIdNumber: initialBorrower?.uniqueIdNumber || "",
    businessName: initialBorrower?.businessName || "",
    typeOfBusiness: initialBorrower?.typeOfBusiness || "",
    gender: initialBorrower?.gender || 'male', 
    dateOfBirth: initialBorrower?.dateOfBirth ? moment(initialBorrower?.dateOfBirth).format('DD-MMM-YYYY') : "",
    otherPhoneNumber: initialBorrower?.otherPhoneNumber || "",
    email: initialBorrower?.email || "",
    nationality: initialBorrower?.nationality || "--- Select Country ---",
    address: initialBorrower?.address || "",
    city: initialBorrower?.city || "",
    state: initialBorrower?.state || "",
    zipcode: initialBorrower?.zipcode || "",
  };

  const saveToAmplify =  async(values) =>{
    const {
      address, businessName ,city ,dateOfBirth ,email ,firstname ,gender ,nationality
      ,otherPhoneNumber ,othername ,phoneNumber ,state ,typeOfBusiness ,uniqueIdNumber
      ,zipcode
    } = values
    values.id = values.phoneNumber
    try{
      const createdBorrower = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          createBorrower(input: {
            userBorrowersId: "${userID}",
            address: "${address}",
            businessName: "${businessName}",
            city: "${city}",
            dateOfBirth: "${dateOfBirth}",
            email: "${email}",
            firstname: "${firstname}",
            gender: "${gender}",
            nationality: "${nationality}",
            phoneNumber: "${phoneNumber}",
            otherPhoneNumber: "${otherPhoneNumber}",
            othername: "${othername}",
            state: "${state}",
            typeOfBusiness: "${typeOfBusiness}",
            uniqueIdNumber: "${uniqueIdNumber}",
            zipcode: "${zipcode}",
          }) 
          {
            id
          }
        }`
      ))
      if(createdBorrower)
      {
        readyToEdit(values);
      }
    }
    catch(e){
      console.log('Borrower Creation Failed', e)
    }
  }

  const updateAmplify =  async(values) =>{
    const {
      address, businessName ,city ,dateOfBirth ,email ,firstname ,gender ,nationality
      ,otherPhoneNumber ,othername ,phoneNumber ,state ,typeOfBusiness ,uniqueIdNumber
      ,zipcode
    } = values
    values.id = initialBorrower.id
    try{
      const updatedBorrower = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          updateBorrower(input: {
            id: "${initialBorrower.id}",
            address: "${address}",
            businessName: "${businessName}",
            city: "${city}",
            dateOfBirth: "${dateOfBirth}",
            email: "${email}",
            firstname: "${firstname}",
            gender: "${gender}",
            nationality: "${nationality}",
            phoneNumber: "${phoneNumber}",
            otherPhoneNumber: "${otherPhoneNumber}",
            othername: "${othername}",
            state: "${state}",
            typeOfBusiness: "${typeOfBusiness}",
            uniqueIdNumber: "${uniqueIdNumber}",
            zipcode: "${zipcode}",
          }) 
          {
            id
          }
        }`
      ))
      if(updatedBorrower)
      {
        editedBorrower(values);
      }
    }
    catch(e){
      console.log('Borrower Editing Failed', e)
    }
  }

  const handleFormSubmit = (values) => {
    setProcessing('Processing...Please Wait...')
    setChecked(false)
    initialBorrower ? updateAmplify(values) : saveToAmplify(values)
  };

  return (
    <Box width="100%" sx={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      {initialBorrower && <FormGroup
        sx={{ mt: '10px'}}>
          <FormControlLabel control={
            <Checkbox 
              color="success"
              defaultChecked={false} 
              checked={checked} 
              onChange={enableEditing}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} 
          label="Enable Editing" />
        </FormGroup>}
      <Box mt="20px" mb="20px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  name="firstname"
                  error={!!touched.firstname && !!errors.firstname}
                  helperText={touched.firstname && errors.firstname}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 2" }}
                />
                <Box mt="-10px" sx={{gridColumn: 'span 4'}}>
                    <Typography color={colors.grey[200]} sx={{fontSize: '12px'}}>
                      You must provide a <span className="myBold">First Name</span> and
                      a <span className="myBold">Phone Number</span>. 
                      Everything else is Optional.<br/>You can NOT use the same phone number for more than 1 borrower.
                    </Typography>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Other Names"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.othername}
                  name="othername"
                  helperText={touched.othername && errors.othername}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Unique Identification Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.uniqueIdNumber}
                  name="uniqueIdNumber"
                  helperText={"You can set a unique number to identify the borrower e.g. National ID Number, Passport Number etc."}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Business Name"
                  onBlur={handleBlur}
                  value={values.businessName}
                  onChange={handleChange}
                  name="businessName"
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Type of Business"
                  onBlur={handleBlur}
                  value={values.typeOfBusiness}
                  onChange={handleChange}
                  name="typeOfBusiness"
                  sx={{ gridColumn: "span 2" }}
                />
                <Box sx={{ gridColumn: "span 2" }}>
                  <Box display='flex' gap="10px">
                    <p>Gender</p>
                    <Field
                      className="formSelect"
                      as="select"
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                    >
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </Field>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: "span 2" }}>
                    <Box display='flex' gap="10px" 
                    sx={{alignItems: 'center'}}>
                      <p>Date of Birth</p>
                      <DatePickerField name="dateOfBirth" />
                    </Box>
                </Box>
                <Box sx={{ gridColumn: "span 4" }} 
                  mt="30px" borderBottom={"0.5px solid"}>
                  <Header subtitle="Borrower Contact Details" />
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Other Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.otherPhoneNumber}
                  name="otherPhoneNumber"
                  // error={!!touched.otherPhoneNumber && !!errors.otherPhoneNumber}
                  // helperText={touched.otherPhoneNumber && errors.otherPhoneNumber}
                  sx={{ gridColumn: "span 2" }}
                />
                <Box sx={{ gridColumn: "span 2" }}>
                  <Box display='flex' gap="10px">
                    <p>Nationality</p>
                    <Field
                      className="formSelect"
                      as="select"
                      label="Nationality"
                      name="nationality"
                      onChange={handleChange}
                    >
                    {countries.map((country, i)=>(
                      <option value={country} key={i}>{country}</option>
                    ))}
                    </Field>
                  </Box>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="City"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    name="city"
                    sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="State/Province"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.state}
                    name="state"
                    sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Zipcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.zipcode}
                    name="zipcode"
                    sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" mt="50px" mb='80px' gap="30px">
                <Button type="submit" color="secondary" variant="contained" disabled={!checked}>
                  {processing}
                </Button>
                <Button onClick={()=> resetForm()} color="error" variant="outlined">
                  CANCEL
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const phoneRegExp = /^(?![A-Za-z]{1,9}$)[^A-Za-z]{9,}$/

const checkoutSchema = yup.object().shape({
  firstname: yup.string().required("required"),
  email: yup.string().email("invalid email"),
  phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required")
});

export default CreateBorrowerForm;