import { Typography, Box, useTheme, Button } from '@mui/material'
import React, { useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
//component imports
import { UserTypeContext } from '../../App'
import { tokens } from '../../theme'
import './dashboard.css'

function DashboardLoans({title, loans}) {
//variables
  const {decimalPoints} = useContext(UserTypeContext);

  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
//functions
    const columnTotal = (column) => {
        return loans.reduce((acc, loan) => {      
        return acc + loan[column]
        },0)
    }

//datgrid columns
const columns = [
    
    {
      field: "borrowerName", 
      headerName: "Borrower",
      flex: 1.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "principal",
      headerName: "Principal Released",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.principal) ? params.row.principal.toLocaleString('en-US', {
           maximumFractionDigits: decimalPoints
        })
        : 0
      }
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.amountDue) ? 
          params.row.amountDue.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
          : 0
      }
    },
    {
      field: "balance",
      headerName: "Loan Balance",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.balance)
        ? params.row.balance.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
        : 0
      }
    }
  ];

  return (
    <Box display="flex" flexDirection={'column'}
      sx={{
        height: '100%',
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
          padding: "0px"
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          height: "unset !important"
        },
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          height: "50px"
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .normalLineHeight": {
          height: `200px !important`,
        },
      }}>
        <Typography variant="h6">{title}</Typography>
        <Box mb="10px" display="flex" gap="10px" flexDirection={'column'} mt="5px"
            sx={{p: "10px", border: ' 1px solid', 
              borderColor: colors.greenAccent[300]}}>
            <Box display="flex" flexDirection="row" gap="10px" mt="10px">
              <Typography  color={colors.greenAccent[500]}>
                Total Principal Released:</Typography>
              <Typography >
                {` ${columnTotal('principal').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" gap="10px">
                <Typography  color={colors.greenAccent[500]}>
                    Total Interest Charged:</Typography>
                <Typography >
                    {` ${(columnTotal('balance') + columnTotal('totalPaid')
                    - columnTotal('principal')).toLocaleString('en-US', {
                    maximumFractionDigits: decimalPoints
                    })}`}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" gap="10px">
                <Typography  color={colors.greenAccent[500]}>
                    Total Amount Due:</Typography>
                <Typography >
                {` ${columnTotal('amountDue').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" gap="10px">
              <Typography  color={colors.greenAccent[500]}>
                Total Loans Balance:</Typography>
              <Typography >
                {` ${columnTotal('balance').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box width='99%'>
              {loans && <DataGrid rows={loans} columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  // disableColumnSorting
                  hideFooter={true}
                  initialState={{
                  sorting: {
                      sortModel: [{field: 'startDate', sort: 'desc'}],
                  },
                  }}
              />}
            </Box>
            
        </Box>
    </Box>
  )
}

export default DashboardLoans