const moment = require('moment'); // You'll need a library like 'moment' for date calculations

const tenPercentWeeklyRate = 1.02411368908445;

const removeCommas = (stringWithCommas)=>{
  const numberWithoutCommas = parseFloat(stringWithCommas.replace(/,/g, ''), 10);
  return numberWithoutCommas
}
const formatLoanIds = (id)=>{
  if(id.length < 21) return (id)
  const cleanedString = id.replace(/\D/g, '');

  // Ensure the cleaned string is at most 10 characters long
  const truncatedString = cleanedString.slice(0, 7);

  // Pad with leading zeros if needed
  return(truncatedString.padStart(7, '0'));
}

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = monthNames[date.getMonth()];
  const formattedDate = `${day}-${monthName}-${year}`;
  return formattedDate;
}
const formatDateString = (dateString) => {
  const day = parseFloat(dateString.slice(0, 2), 10);
  const monthName = dateString.slice(2, 5);
  const year = parseFloat(dateString.slice(5), 10) + 2000; // Assuming 20 is the prefix for the year 2000s

  // Convert the month name to a numerical value
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = months.findIndex((m) => m === monthName);

  // Create the new Date object
  return new Date(year, month, day);
}

const formatGraphQLDate = (dateString)=>{
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "2-digit" };
  const formattedDate = date.toLocaleDateString("en-UK", options);
  return formattedDate;
}

const formatNumber = (number)=>{
  // const newNumber = Math.floor(number)
  return new Intl.NumberFormat().format(number)
}

function calculateAmortizedLoan(startDate, loanAmount, interestRate, durationMonths, 
  repaymentCycle, decimalDigits) {

  const decimalPoints = decimalDigits || 0
  const monthlyInterestRate = interestRate / 100;
  const totalPayments = durationMonths;
  const monthlyPayment = loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments)));
  const amortizedLoan = [];
  const summaryObject = []

  let currentDate = new Date(startDate);

  if(repaymentCycle === 'lumpSum'){
    const interestFactor = 1 + (interestRate / 100);
    const compoundedAmount = loanAmount * Math.pow(interestFactor, durationMonths);
    currentDate.setMonth(currentDate.getMonth() + durationMonths);
    const summaryObj = {
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      monthlyPayment: compoundedAmount
    }
    summaryObject.push(summaryObj)
    return summaryObject;
  }

  for (let i = 0; i < totalPayments; i++) {
    const interestPayment = loanAmount * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;

    const summaryObj = {
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      monthlyPayment: monthlyPayment
    }

    const paymentObj = {
      paymentNumber: i + 1,
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      principalPayment: principalPayment.toFixed(decimalPoints),
      interestPayment: interestPayment.toFixed(decimalPoints),
      remainingBalance: (loanAmount - principalPayment).toFixed(decimalPoints),
      monthlyPayment: monthlyPayment
    };
    summaryObject.push(summaryObj)

    amortizedLoan.push(paymentObj);

    currentDate.setMonth(currentDate.getMonth() + 1);
    loanAmount -= principalPayment;
  }

  return summaryObject;
}
 
const scheduleLoan = (loanAmount, interestRate, duration, startDateString, 
  rateFormat, durationFormat, repaymentCycle, decimalDigits)=>{
  // const startDate = formatDateString(startDateString)
  const decimalPoints = decimalDigits || 0
  const startDate = moment(startDateString, 'DDMMMYY');
  const lsStartDate = moment(startDateString, 'DDMMMYY');
  const startDateFormated = startDate.format('DD-MMM-YYYY')
  var timeline = rateFormat === 'per month' ? 1: 12; 
  const monthlyInterestRate = interestRate / 100 / timeline ;
  var durationInMonthsOrYears = durationFormat === 'months'? 1: 12
  var loanDurationMonths = duration * durationInMonthsOrYears;
  var maxPeriod = false
  if (loanDurationMonths > 96) {
    loanDurationMonths = 96;
    maxPeriod = true 
  }
  let monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -loanDurationMonths));
  if(repaymentCycle === 'flatInstalments'){
    monthlyPayment = ((loanAmount * monthlyInterestRate * loanDurationMonths) + loanAmount)/ loanDurationMonths
  }
  const installmentDetails = [];

  let currentBalance = loanAmount;
  for (let i = 1; i <= loanDurationMonths; i++) {
    const interestPayment = currentBalance * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;
    // const installmentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i, startDate.getDate());
    const installmentDate = startDate.add(1, 'months');

    const installment = {
      id: i,
      // date: formatDate(installmentDate.toString().slice(0, 10)),
      date: installmentDate.format('DD-MMM-YYYY'),
      principal: formatNumber(principalPayment.toFixed(decimalPoints)),
      interest: formatNumber(interestPayment.toFixed(decimalPoints)),
      balance: formatNumber(currentBalance.toFixed(decimalPoints)),
      totalPayment: formatNumber(parseFloat(interestPayment.toFixed(decimalPoints)) + parseFloat(principalPayment.toFixed(decimalPoints)))
    };

    installmentDetails.push(installment); 

    currentBalance -= principalPayment;
  }

// For Simple LumpSum Loans
  if(repaymentCycle === 'simpleLumpSum'){
    const newInterest = loanAmount * interestRate * loanDurationMonths /100
    const amountToPay = loanAmount + newInterest;
    const instalment = [
      {
        id: 1,
        date: lsStartDate.format('DD-MMM-YYYY'),
        principal: formatNumber(loanAmount.toFixed(decimalPoints)),
        amountToPay: formatNumber(amountToPay.toFixed(decimalPoints)),
        interest: formatNumber(newInterest.toFixed(decimalPoints))
      }
    ]
    return [instalment, loanDurationMonths, rateFormat, startDateFormated, maxPeriod, repaymentCycle,
      newInterest];
  }

// For Lumpsum loans
  if(repaymentCycle === 'lumpSum'){
      
      const installments = [];
      let newInterest = loanAmount * interestRate /100
      let amountToPay = loanAmount + newInterest;
      let principal = amountToPay - newInterest; 
      // let currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
      let currentDate = lsStartDate.add(1, 'months');
      
      for (let i = 0; i < duration; i++) {
        const installment = {
          id: i+1,
          date: currentDate.format('DD-MMM-YYYY'),
          principal: formatNumber(principal.toFixed(decimalPoints)),
          amountToPay: formatNumber(amountToPay.toFixed(decimalPoints)),
          interest: formatNumber(newInterest.toFixed(decimalPoints))
        };
        
        // currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i+1, startDate.getDate());
        installments.push(installment);
        currentDate = lsStartDate.add(i+1, 'months')
        newInterest = amountToPay * interestRate /100
        amountToPay += amountToPay * interestRate /100;
        // currentDate.setMonth(currentDate.getMonth() + 1);
        principal = amountToPay - newInterest;  
      }
      return [installments, loanDurationMonths, rateFormat, startDateFormated, maxPeriod, repaymentCycle,
        removeCommas(installments[installments.length - 1].amountToPay) - loanAmount];
  }

  return [installmentDetails, loanDurationMonths, rateFormat, startDateFormated, maxPeriod, repaymentCycle,
    (monthlyPayment * loanDurationMonths) - loanAmount];
}

export {scheduleLoan, formatNumber, formatGraphQLDate, calculateAmortizedLoan, formatLoanIds};

