//class imports
import React, { useEffect, useContext } from 'react'
import { useState } from 'react';
import { Box, Button, Typography, useTheme} from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { API, graphqlOperation} from 'aws-amplify';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

//component imports
import { tokens } from '../../theme'; 
import DueLoans from './LoanCategories/DueLoans';
import { UserIDContext, UserTypeContext } from '../../App';
import { formatGraphQLDate } from '../../assets/loanCalculations';
import { loanCompleter, filterByStatus } from '../../assets/loanPanelCalculations';
import ViewLoansAndPayments from './ViewLoansAndPayments';
import SuccessMessage from '../../components/SuccessMessage';
import CurrentLoans from './LoanCategories/CurrentLoans';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoansReport from './LoansReport';
import { LoansContext } from '../../App';
import { loadingLoansPanel, emptyLoansPanel, emptyBorrowers} from '../../assets/loadingArrays';
import ReportDateRange from '../../components/ReportDateRange';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pb: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
  
export default function LoansPanel({loadReports, loansData, loadLoanIds,
  adminIdToFilter}) {
  //constants
    const userID = useContext(UserIDContext)
    const {userTypeAWS, userAdminId, decimalPoints} = useContext(UserTypeContext)
    const navigate = useNavigate()
    const {principal, firstname} = useParams()
    const {loansContext, setLoansContext} = useContext(LoansContext)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  //stattes
    const [value, setValue] = useState(0);
    const [borrowers, setBorrowers] = useState(loadingLoansPanel)
    const [panelHidden, setPanelHidden] = useState()
    const [loanCreated, setLoanCreated] = useState()
    const [loansReport, setLoansReport] = useState()
    const [noAccessibleLoans, setNoAccessibleLoans] = useState()

  //states for date filtering
  const [startDate, setStartDate] = useState(moment('2020-01-01').format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const [unchangingBorrowers, setUnchangingBorrowers] = useState()

  //useEffect for date filtering
  useEffect(() =>{
    unchangingBorrowers && setBorrowers(
      unchangingBorrowers.filter((loan) => { 
        return moment(loan.startDate).isSameOrAfter(moment(startDate))
            && moment(loan.startDate).isSameOrBefore(moment(endDate))
      })
    )
  }, [startDate, endDate])

  //useEffect 
  
    useEffect(()=>{
      function filterBorrowersWithLoans(arr) {
      
        // Filter borrowers who have loans
        const borrowersWithLoans = arr.filter((borrower) => borrower.loans.items.length > 0);

        if (borrowersWithLoans.length === 0){
          setBorrowers(emptyLoansPanel)
          setUnchangingBorrowers(emptyLoansPanel)
          setLoansContext(emptyLoansPanel)
          return; 
        }
    
        let sortedBorrowers = []
        let counter = 0
        for(let i =0; i<borrowersWithLoans.length; i++){
          for(let j =0; j<borrowersWithLoans[i].loans.items.length; j++){
            const borrowerDetails = {
              borrowerName: borrowersWithLoans[i].firstname + " "+borrowersWithLoans[i].othername,
              status: borrowersWithLoans[i].loans.items[j].loanStatus || 'current',
              principal: borrowersWithLoans[i].loans.items[j].principal,
              uniqueRef: borrowersWithLoans[i].loans.items[j].uniqueRef,
              id: borrowersWithLoans[i].loans.items[j].id,              
              startDate: formatGraphQLDate(borrowersWithLoans[i].loans.items[j].startDate),
              maturityDate: getMaturityDate(borrowersWithLoans[i].loans.items[j].startDate, 
                borrowersWithLoans[i].loans.items[j].duration,
                borrowersWithLoans[i].loans.items[j].durationInterval),
              interestRate: borrowersWithLoans[i].loans.items[j].interestRate + '%',
              rateInterval: borrowersWithLoans[i].loans.items[j].rateInterval,
              duration: borrowersWithLoans[i].loans.items[j].duration,
              fees: (borrowersWithLoans[i].loans.items[j].fees 
                || borrowersWithLoans[i].loans.items[j].loanFees?.items[0]?.amount) 
                || 0,
              durationInterval: borrowersWithLoans[i].loans.items[j].durationInterval,
              loanType: borrowersWithLoans[i].loans.items[j].laonType,
              loanId: borrowersWithLoans[i].loans.items[j].id,
              borrowerID: borrowersWithLoans[i].loans.items[j].borrowerLoansId,
              label: 'View Statement',
              payments: borrowersWithLoans[i].loans.items[j].payments.items,
              penalties: borrowersWithLoans[i].loans.items[j].penalties.items,
              currency: borrowersWithLoans[i].loans.items[j].loanCurrency,
              numberOfPayments: null,
              initialNumberOfPayments: borrowersWithLoans[i].loans.items[j].numberOfPayments,
              paymentFrequency: borrowersWithLoans[i].loans.items[j].paymentFrequency || 'per month',
              counter: counter,
              lateInterestBySystem: borrowersWithLoans[i].loans.items[j].loanAttribute1 === null ? true :
                borrowersWithLoans[i].loans.items[j].loanAttribute1 === 'false' ? false : true
            }
            sortedBorrowers.push(borrowerDetails)
            counter += 1; 
          }
        }
        if(userAdminId){
          filterForUserAdmin(null, sortedBorrowers)
        }else{
          const completeBorrowers = loanCompleter(sortedBorrowers, decimalPoints)
          if(adminIdToFilter){
            filterForUserAdmin(null, completeBorrowers, adminIdToFilter)
          }else {
            setBorrowers(completeBorrowers)
            setUnchangingBorrowers(completeBorrowers)
            setLoansContext(completeBorrowers)
          }
        }
      }

      const listofL = []
 
      const listLoans = async(nextToken)=>{
        const token = nextToken || ''
        try{
          const listOfLoans = await API.graphql(graphqlOperation(`query MyQuery {
            listBorrowers(
              filter: {userBorrowersId: {eq: "${userID}"}},
              limit: 1000
              ${token}
            ) {
              nextToken
              items {
                firstname
                othername
                loans(limit: 1000){
                  items{
                    borrowerLoansId
                    startDate
                    principal
                    interestRate
                    id
                    loanStatus
                    laonType
                    duration
                    loanCurrency
                    numberOfPayments
                    paymentFrequency
                    loanAttribute1
                    durationInterval
                    rateInterval
                    uniqueRef
                    payments(limit: 1000) {
                      items {
                        amount
                        id
                        paymentDate
                      }
                    }
                    penalties(limit: 1000) {
                      items {
                        amount
                        id
                        penaltyDate
                        penaltyAttribute1
                      }
                    }
                    fees
                    loanFees(limit: 1000) {
                      items {
                        amount
                      }
                    }
                  }
                }
              }
            }
          }
          `)); 
          if(listOfLoans.data.listBorrowers.items){
            listofL.push(listOfLoans.data.listBorrowers.items);
            if(listOfLoans.data.listBorrowers.nextToken){
              listLoans(`,nextToken: "${listOfLoans.data.listBorrowers.nextToken}"`)
            }else{
              const mergedList = [].concat(...listofL);
              filterBorrowersWithLoans(mergedList)
            }
          }        
        }catch(e){
          console.log('Error getting loans: ',e)  
        }
      }

      const listofB2 = []

      const filterForUserAdmin = async(nextToken, allBorrowers, userIdProvided)=>{
        const userAdminIdToUse = userIdProvided || userAdminId
        const token = nextToken || ''
        try{
          const borrowerUserAdmins = await API.graphql(graphqlOperation(`query MyQuery {
            listBorrowerUserAdmins(
              filter: { userAdminId: {eq: "${userAdminIdToUse}"}}, 
              limit: 1000 
              ${token}
            ) {
              items {
                borrowerId
                }
              nextToken
            }
          }
          `));
          if(borrowerUserAdmins.data.listBorrowerUserAdmins?.items?.length > 0){
            const listOfBUA = borrowerUserAdmins.data.listBorrowerUserAdmins.items.map((item)=>({
              borrowerId: item.borrowerId,
            }))
            listofB2.push(listOfBUA)
            if(borrowerUserAdmins.data.listBorrowerUserAdmins?.nextToken){
              filterForUserAdmin(`,nextToken: "${borrowerUserAdmins.data.listBorrowerUserAdmins.nextToken}"`, 
              allBorrowers, userAdminIdToUse)
            }else{
              const mergedList = [].concat(...listofB2);
              const arrayOfIds = mergedList.map((item) =>item?.borrowerId)
              const setOfIds = new Set(arrayOfIds)
              const accessibleBorrowers = allBorrowers?.filter((borrower) =>{
                return setOfIds.has(borrower?.borrowerID)
              })
              if(accessibleBorrowers?.length === 0){
                setNoAccessibleLoans(true)
              }else{
                if(userIdProvided){
                  setBorrowers(accessibleBorrowers)
                  setUnchangingBorrowers(accessibleBorrowers)
                  setLoansContext(accessibleBorrowers)
                }else{
                  const completeBorrowers = loanCompleter(accessibleBorrowers, decimalPoints)
                  setBorrowers(completeBorrowers)
                  setUnchangingBorrowers(completeBorrowers)
                  setLoansContext(completeBorrowers)
                }
              }

            }
          }else{
            setBorrowers(emptyLoansPanel)
            setUnchangingBorrowers(emptyLoansPanel)
            setLoansContext(emptyLoansPanel)
          }
        }catch(e){
          console.log('Error getting BorrowerUserAdmins: ',e)
        }
      }

      if(!loansContext){
        listLoans()
      }else{
        if(adminIdToFilter){
          filterForUserAdmin(null, loansContext, adminIdToFilter)
        }else{
          setBorrowers(loansContext)
          setUnchangingBorrowers(loansContext)
        }
      }
      // eslint-disable-next-line 
    },[adminIdToFilter])

    //messaging for when a new loan is created.
    useEffect(()=>{
      if(firstname && principal){
        setLoanCreated(true)
        setTimeout(()=> setLoanCreated(false), 5000)
      }
    },[loansContext])

  //useFffect for loading reports
    useEffect(()=>{
      borrowers && loadReports && navigate('/reportsPanel')
    },[borrowers]) 
  
    //useFffect for creating loans
    useEffect(()=>{
      borrowers && loadLoanIds && navigate('/newLoan')
    },[borrowers]) 
    
  //useFffect for loading dashboard
    useEffect(()=>{
      loansContext && loansData && navigate('/dashboard')
    },[loansContext]) 

  //functions  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const hidePanel =(val) => setPanelHidden(val);

  //get Maturity date
    const getMaturityDate = (startDate, duration, durationInterval) => {
      const sDate = moment(startDate)
      const endDate = sDate.add(duration, durationInterval).format('YYYY-MM-DD');
      return new Date(endDate)
    }

  //update Loans
    const updateLoans = (updatedPayments, loanIndex, loanId, newLoanDetails, status,
      lateInterestBySystem, updatedPenalties) => {
      const newLoans = loanCompleter(unchangingBorrowers.map((loan, ind) =>{
        if(updatedPenalties){
          if(loan.loanId === loanId){
            loan.penalties = updatedPenalties
          }
          return loan
        }
        if(lateInterestBySystem){
          if(loan.loanId === loanId){
            loan.lateInterestBySystem = lateInterestBySystem.value;
          }
          return loan
        }
        if(status){
          if(loan.loanId === loanId){
            loan.status = status;
          }
          return loan
        }
        if(loanId){
          if((loan.loanId === loanId) && newLoanDetails){
            const {principal, interestRate, startDate, duration, rateInterval,
              durationInterval, repaymentCycle, instalmentFrequency, uniqueRef} = newLoanDetails
            loan.principal = principal;
            loan.startDate = startDate;
            loan.interestRate = interestRate;
            loan.loanType = repaymentCycle;
            loan.duration = duration;
            loan.durationInterval = durationInterval;
            loan.rateInterval = rateInterval;
            loan.maturityDate = getMaturityDate(startDate, duration, durationInterval)
            loan.paymentFrequency = instalmentFrequency;
            loan.uniqueRef = uniqueRef;
          }
        }
        return (
          ind === loanIndex 
            ? {...loan, payments: updatedPayments, id: Math.random()*1000} 
            : loan
        )
      }), decimalPoints)
      setBorrowers(newLoans)
      setUnchangingBorrowers(newLoans)
      setLoansContext(newLoans)
    }
    
    const deleteLoan = (loanId) => {
      const newLoans = unchangingBorrowers.filter((loan) => loan.loanId !== loanId)
      setBorrowers(newLoans)
      setUnchangingBorrowers(newLoans)
      setLoansContext(newLoans)
    }
  
  return (<>
      {loansReport && unchangingBorrowers && <LoansReport loans={unchangingBorrowers} closeReport={()=>setLoansReport(false)}
        hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
      {noAccessibleLoans && <Box p='30px' display="flex" flexDirection={'column'} gap="20px">
        <Typography variant="h3" color={colors.redAccent[300]}>
          YOUR ACCOUNT HAS NOT BEEN GRANTED ACCESS TO ANY LOANS</Typography>
        <Typography>Please ask your Parent Account / System Administrator to grant you access.</Typography>
        <Typography>Access can be granted through your Parent Account under "Manage Team" - "View/Edit" - "Assigned Borrowers"</Typography>
      </Box>}
      {!noAccessibleLoans && !loansReport && !loadReports && !loansData && !loadLoanIds &&
        <Box 
        display='flex' flexDirection='column' sx={{width: {xs: '100%', md: '900px'}}}>
        {loanCreated && <SuccessMessage message={
          `New ${principal} loan for ${firstname} created successfully`
        }/>}
        <Box display={'flex'} alignItems={'center'} mb='20px' flexWrap={'wrap'} mt="20px"
          sx={{
            display: panelHidden? 'none': 'flex',
            gap: {xs: '20px', md: '60px'},
            flexDirection: {xs: 'column', md: 'row'}
          }}>
          <Typography sx={{fontSize: '30px', fontWeight: 600}}
            >LOANS PANEL</Typography>
          <Box display={'flex'} flexDirection="row" alignItems={'center'} 
            flexWrap={'wrap'}
            sx={{gap: {xs: '20px', md: '60px'}}}
          >
            {loansContext && <Button color='info' variant='outlined' startIcon={<AddCircleOutlineIcon/>}
              onClick={()=> navigate('/newLoan')}
              > NEW LOAN</Button>}
            <Button color='success' variant='outlined' startIcon={<AssessmentIcon/>}
              onClick={()=> setLoansReport(true)}>LOANS REPORT</Button>
          </Box>
        </Box>
        <Box sx={{display: panelHidden? 'none': 'block'}}>
          {startDate && endDate && <ReportDateRange startDate={startDate} endDate={endDate}
              setStartDate={setStartDate} setEndDate={setEndDate} notReport/>}
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', 
          display: panelHidden? 'none': 'flex'}}>
          <Tabs value={value} onChange={handleChange} textColor="secondary"
            indicatorColor="secondary" variant="scrollable"
            scrollButtons="auto">
            <Tab sx={{fontSize: 16}} label="ALL LOANS" {...a11yProps(0)} />
            <Tab sx={{fontSize: 16}} label="CURRENT" {...a11yProps(1)} />
            <Tab sx={{fontSize: 16}} label="CURRENT WITH MISSED PAYMENTS" {...a11yProps(2)} />
            <Tab sx={{fontSize: 16}} label="PAST-MATURITY / OVERDUE" {...a11yProps(3)} />
            <Tab sx={{fontSize: 16}} label="CLEARED" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {borrowers && <ViewLoansAndPayments loans={borrowers} loansStatus={'ALL LOANS'}
              hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {borrowers && <CurrentLoans loans={borrowers}
              hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {borrowers && <ViewLoansAndPayments loans={filterByStatus(borrowers, 'payment due')}
              loansStatus="CURRENT LOANS WITH MISSED PAYMENTS"
              hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {borrowers && <DueLoans loans={borrowers}
              hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
        </CustomTabPanel>
        
        <CustomTabPanel value={value} index={4}>
          {borrowers && <ViewLoansAndPayments loans={filterByStatus(borrowers, 'cleared')}
              loansStatus="CLEARED LOANS"
              hidePanel={hidePanel} updateLoans={updateLoans} deleteLoan={deleteLoan}/>}
        </CustomTabPanel>
      </Box>}
    </>
  );
}
