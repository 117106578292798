import { ColorModeContext, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes, BrowserRouter as Router, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/Dashboard";
import CreateBorrower from "./scenes/Borrowers/CreateBorrower";
import ManageBorrowers from "./scenes/Borrowers/ManageBorrowers";
import NewLoan from "./scenes/Loans/NewLoan"
import LoansPanel from "./scenes/Loans/LoansPanel";
import CreateAccount from "./scenes/Accounts/CreateAccount";
import ManageAccounts from "./scenes/Accounts/ManageAccounts";
import CreateUserAdmin from "./scenes/UserAdmins/CreateUserAdmin";
import ManageUserAdmins from "./scenes/UserAdmins/ManageUserAdmins";
import MySidebar from "./scenes/global/Sidebar";
import UnderDevelopment from "./scenes/global/UnderDevelopment";
import Guides from "./scenes/Support/Guides";
import ContactSupport from "./scenes/Support/ContactSupport";
import NewLoanByBorrower from "./scenes/Loans/NewLoanByBorrower";
import ViewAndEditBorrower from "./scenes/Borrowers/ViewAndEditBorrower";
import PaymentReceivedPRO from "./appScenes/PaymentReceivedPRO";
import PaymentReceivedCORPORATE from "./appScenes/PaymentReceivedCORPORATE";
import Packages from "./appScenes/Packages";
import { useState } from "react";
import { UserTypeChangeDateContext, UserTypeContext } from "./App";
import { useContext } from "react";
import { useEffect } from "react";
import BlockedUserNotification from "./components/BlockedUserNotification";
import Onboarding from "./scenes/Onboarding";
import Settings from "./appScenes/Settings";
//css
import './app.css'
//expenses
import NewExpense from "./scenes/ExpensesAndIncomes/NewExpense";
import TE from "./scenes/ExpensesAndIncomes/TE";
import LoansReport from "./scenes/Reports/LoansReport";
import PaymentsReport from "./scenes/Reports/PaymentsReport";
import BorrowersReport from "./scenes/Reports/BorrowersReport";
import ReportsPanel from "./scenes/Reports/ReportsPanel";
import IncomeStatement from "./scenes/Reports/IncomeStatement";
import TeamLogInAccess from "./scenes/UserAdmins/TeamLogInAccess";
import Notice from "./components/Notice";
import BecomeChild from "./appScenes/BecomeChild";
import ViewerLimitNotice from "./components/ViewerLimitNotice";
import MobileMenu from "./scenes/global/MobileMenu";
import MyDocument from "./components/PdfRender";
import LoanTabsAdmin from "./appScenes/LoanTabsAdmin/LoanTabsAdmin";

const AppSrc = () =>{
    const[theme, colorMode] = useMode()
    const [userState, setUserState] = useState()
    const {userTypeAWS, requestForParenthood} = useContext(UserTypeContext)
    const [openNotice, setOpenNotice] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        userTypeAWS === 'blocked' && setUserState(true)
        userTypeAWS === 'subscriptionExpired' && setUserState(true)
        userTypeAWS === 'lite' && setUserState(false)
        userTypeAWS === 'viewer' && setUserState(false)
        userTypeAWS === 'editor' && setUserState(false)
        userTypeAWS === 'PRO' && setUserState(false)
        userTypeAWS === 'CORPORATE' && setUserState(false) 
        requestForParenthood && setOpenNotice(true)
    },[userTypeAWS])

    function viewNotice(){
        setOpenNotice(false)
        navigate('/becomeChild')
    }

    const blockedSet = new Set(['blocked, subscriptionExpired'])
    const commonlyAccepted = ['PRO', 'CORPORATE', 'lite']
    const commonlyDenied = ['viewer', 'editor']

    const whatToRender = (element, accepted, denied) =>{
        if(blockedSet.has(userTypeAWS)){
            return <BlockedUserNotification value={userTypeAWS}/>
        }
        const acceptedSet = new Set(accepted)
        const deniedSet = new Set(denied)
        if(acceptedSet.has(userTypeAWS)){
            return element
        }else if(deniedSet.has(userTypeAWS)){
            return <ViewerLimitNotice />
        }
    }

    return(
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <MobileMenu />
                <CssBaseline />
                <MySidebar />
                <Box sx={{ display: 'flex', minHeight: '100vh', paddingTop: "30px"}} 
                    flexDirection={'column'}
                    alignItems={'center'}
                    className="mainBoxContent">
                            <Topbar  />
                            {openNotice && <Notice
                                message={`${requestForParenthood?.parentEmail} has sent you a Request.`}
                                viewNotice={viewNotice} closeNotice={()=>setOpenNotice(false)}/>}
                            <Routes>
                                <Route path="/" element={<LoansPanel loansData/>}/>
                                <Route path="*" element={<Dashboard/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                {/* Borrowers */}
                                <Route path="/createBorrower" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <CreateBorrower/>}/>
                                <Route path="/manageBorrowers" element={userState ? <BlockedUserNotification value={userTypeAWS}/> :<ManageBorrowers/>}/>
                                <Route path="/manageBorrowers/:borrowerName" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <ManageBorrowers/>}/>
                                <Route path="/viewAndEditBorrower/:id/:firstName/:otherName/:phoneNumber/:otherPhoneNumber" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <ViewAndEditBorrower/>}/>
                                <Route path="/createBorrower/:id/:firstName/:otherName/:phoneNumber/:otherPhoneNumber" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <CreateBorrower/>}/>
                                {/* Loans */}
                                <Route path="/newLoan/" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <NewLoan/>}/>
                                <Route path="/newLoan/:firstname/:othername" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <NewLoan/>}/>
                                <Route path="/loansPanel" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <LoansPanel/>}/>
                                <Route path="/loansPanel/:principal/:firstname" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <LoansPanel/>}/>
                                <Route path="/newLoanByBorrower/:id/:fullname" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <NewLoanByBorrower/>}/>
                                <Route path="/loadLoanIds" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <LoansPanel loadLoanIds/>}/>
                                {/* Payments */}
                                {/* Accounts */}
                                <Route path="/createAccount" element={whatToRender(<CreateAccount/>,[...commonlyAccepted, 'editor'], ['viewer'])}/>
                                <Route path="/manageAccounts" element={whatToRender(<ManageAccounts/>, [...commonlyAccepted, 'editor'], ['viewer'])}/>
                                <Route path="/manageAccounts/:accountSummary" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <ManageAccounts/>}/>
                                {/* Support */}
                                <Route path="/guides" element={<Guides/>}/>
                                <Route path="/contactSupport" element={<ContactSupport/>}/>
                                {/* appScenes */}
                                <Route path="/paymentReceivedPRO" element={<PaymentReceivedPRO/>}/>
                                <Route path="/paymentReceivedCORPORATE" element={<PaymentReceivedCORPORATE/>}/>
                                <Route path="/Packages" element={<Packages/>}/>
                                <Route path="/onboarding" element={<Onboarding/>}/>
                                <Route path="/settings" element={whatToRender(<Settings/>, commonlyAccepted, commonlyDenied)}/>
                                {/* UserAdmin */}
                                <Route path="/createUserAdmin" element={whatToRender(<CreateUserAdmin/>, commonlyAccepted, commonlyDenied)}/>
                                <Route path="/createUserAdmin/:id/:name/:email/:phoneNumber/:type/:accessLevel" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <CreateUserAdmin/>}/>
                                <Route path="/manageUserAdmins" element={whatToRender(<ManageUserAdmins/>, commonlyAccepted, commonlyDenied)}/>
                                <Route path="/underDevelopment" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <UnderDevelopment/>}/>
                                <Route path="/teamLogInAccess" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <TeamLogInAccess/>}/>
                                <Route path="/becomeChild" element={<BecomeChild/>}/>
                                {/* ExpensesAndIncomes */}
                                <Route path="/newExpense" element={whatToRender(<NewExpense/>, [...commonlyAccepted, 'editor'], ['viewer'])}/>
                                {/* Reports */}
                                <Route path="/loansReport" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <LoansReport/>}/>
                                <Route path="/incomeStatement" element={whatToRender(<IncomeStatement/>, [...commonlyAccepted, 'editor'], ['viewer'])}/>
                                <Route path="/paymentsReport" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <PaymentsReport/>}/>
                                <Route path="/borrowersReport" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <BorrowersReport/>}/>
                                <Route path="/reportsPanel" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <ReportsPanel/>}/>
                                <Route path="/loadReports" element={userState ? <BlockedUserNotification value={userTypeAWS}/> : <LoansPanel loadReports={true}/>}/>
                                {/* Reports */}
                                <Route path="/pdfr" element={<MyDocument/>}/>
                                {/* LTadmin */}
                                <Route path="/LTadmin24AndrewMugisha" element={<LoanTabsAdmin dev/>}/>

                            </Routes>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}
export default AppSrc; 