import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

//component imports
import ViewLoansAndPayments from '../ViewLoansAndPayments';
import { maturityFilter, filterByStatus, currentLoansFilter} 
  from '../../../assets/loanPanelCalculations';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CurrentLoans({loans, hidePanel, updateLoans}) {
  const [value, setValue] = React.useState(0);
  const [panelHidden, setPanelHidden] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const hideAllPanels = (val) => {
    hidePanel(val)
    setPanelHidden(val);
  }

  return (
    <Box sx={{ maxWidth: 900, mt: '10px' }}>
      <Box sx = {{bgcolor: 'background.paper',
        display: panelHidden? 'none': 'flex'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="secondary tabs example"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab {...a11yProps(0)} label="ALL CURRENT LOANS" />
            <Tab {...a11yProps(1)} label="HAS A PAYMENT DUE IN 0-7 DAYS" />
            <Tab {...a11yProps(2)} label="HAS A PAYMENT DUE IN 8-14 DAYS" />
            <Tab {...a11yProps(3)} label="MATURING WITHIN 30 DAYS" />
          </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ViewLoansAndPayments loans={filterByStatus(loans, 'current')} 
          loansStatus={'ALL CURRENT LOANS'}
          hidePanel={hideAllPanels} updateLoans={updateLoans}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ViewLoansAndPayments loans={currentLoansFilter(filterByStatus(loans, 'current'), 0, 7)} 
          loansStatus={'CURRENT LOANS WITH A PAYMENT DUE IN 0-7 DAYS'}
          hidePanel={hideAllPanels} updateLoans={updateLoans}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ViewLoansAndPayments loans={currentLoansFilter(filterByStatus(loans, 'current'), 8, 14)} 
          loansStatus={'CURRENT LOANS WITH A PAYMENT DUE IN 8-14 DAYS'}
          hidePanel={hideAllPanels} updateLoans={updateLoans}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <ViewLoansAndPayments loans={maturityFilter(filterByStatus(loans, 'current'), 0, 30)} 
          loansStatus={'CURRENT LOANS MATURING WITHIN 30 DAYS'}
          hidePanel={hideAllPanels} updateLoans={updateLoans}/>
      </CustomTabPanel>
    </Box>
  );
}
