import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import moment from 'moment';
import '@aws-amplify/ui-react/styles.css';
import { numberRegex } from "../assets/currenciesObj";
import { useNavigate } from "react-router-dom";

//component imports
import { tokens } from "../theme";
import {currenciesObj} from "../assets/currenciesObj";
import Header from "../components/Header";

const Onboarding = ({userId, email, setUserName, setBusinessName, setOnBoardUser, signOut,
  setUserTypeAWS, setAccounts, setUserCurrency}) => {

  //constants
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currencies = Object.keys(currenciesObj)
  const today = moment()
  const expiryDate = today.add(30, 'days').format('LL')
  const navigate = useNavigate()
  
  //states
  const [processing, setProcessing] = useState('Save')

  //useEffects

  //functions
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a' 

  const initialValues = {
    accountName: "Cash",
    openingBalance: "",
    currency: "USD",
    username: email ? email.substring(0, email.indexOf('@')) : '',
    businessName: ''
  };

  const createUser = async(accountName, businessName, openingBalance, currency, username)=>{
    try{
      const newUser = await API.graphql(graphqlOperation(`mutation MyMutation {
        createUser(input: {
          id: "${userId}", 
          businessName: "${businessName}",
          email: "${email}", 
          userType: "lite", 
          userTypeChangeDate: "${expiryDate}", 
          username: "${username}",
          userAttribue1: "${currency}"
        })
        {
          id
        }
      }
      `))
      if(newUser){
        createAccount(accountName, openingBalance, currency, businessName, username)
      };
    }
    catch(e){
      console.log('Error Creating new user', e)
    }
  }

  const createAccount = async(accountName, openingBalance, currency, businessName, username)=>{
    try{
      const createdAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
        createAccount(input: {
          accountName: "${accountName}", 
          openingBalance: ${openingBalance}, 
          userAccountsId: "${userId}",
          currency: "${currency}",
          accountStatus: "default"
        }) {
          id
        }
      }
      `))
      if(createdAccount){
        setUserName(username)
        setBusinessName(businessName)
        setAccounts([{
          id: createdAccount.data.createAccount.id,
          accountName,
          openingBalance,
          currency,
        }])
        setUserTypeAWS('lite')
        setUserCurrency(currency)
        setOnBoardUser(false)
      };
    }
    catch(e){
      console.log('Error creating account', e)
    }
  }

  const handleFormSubmit = (values) => {
    const {accountName, openingBalance, currency, username, businessName} = values
    setProcessing('Processing...Please Wait...')
    const opBalance = parseFloat(openingBalance.replace(/,/g, ''))
    // values.decimalPoints = currenciesObj[currency].decimal_digits
    createUser(accountName, businessName, opBalance, currency, username)
  };

  return (
    <Box width="100%" sx={{ display: 'flex', justifyContent: 'center',}}>
      <Box p="20px"
        sx={{width: {xs: '100%', md: '800px'}}}
      >
        <Header 
            title={'Welcome to LoanTabs!'} 
            subtitle={`Customize Your LoanTabs experience:`}/>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                }}
              >
                <Box sx={{ gridColumn: "span 3" }}>
                  <Box display='flex' gap="10px">
                    <p>Select a Currency for your Business</p>
                    <Field
                      className="formSelect"
                      as="select"
                      name="currency"
                      onChange={handleChange}
                    >
                    {currencies.map((currency, i)=>(
                      <option value={currency} key={i}>{currency}</option>
                    ))}
                    </Field>
                  </Box>
                </Box>
                {/* <CssTextField focusColor={labelFocusColor}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Create a Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={!!touched.username && !!errors.username}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 3" }}
                /> */}
                <CssTextField focusColor={labelFocusColor}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Business Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.businessName}
                  name="businessName"
                  error={!!touched.businessName && !!errors.businessName}
                  helperText={touched.businessName && errors.businessName}
                  sx={{ gridColumn: "span 3" }}
                />
                <Box sx={{ gridColumn: "span 3" }} 
                  mt="30px" borderBottom={"0.5px solid"}>
                  <Header subtitle="Set up the first operational account (you can create more accounts later)" />
                </Box>
                <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Account Name (e.g. Cash, Mobile Money, Bank etc.)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.accountName}
                    name="accountName"
                    error={!!touched.accountName && !!errors.accountName}
                    helperText={touched.accountName && errors.accountName}
                    sx={{ gridColumn: "span 3" }}
                />
                <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Opening Balance (put zero if there is no opening balance)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.openingBalance.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    name="openingBalance"
                    error={!!touched.openingBalance && !!errors.openingBalance}
                    helperText={touched.openingBalance && errors.openingBalance}
                    sx={{ gridColumn: "span 3" }}
                />
              </Box>
              <Box display="flex" mt="50px" mb='80px' gap="30px">
                <Button type="submit" color="primary" variant="contained" >
                  {processing}
                </Button>
                <Button color="secondary" variant="outlined" 
                  onClick={()=>{
                    navigate('/')
                    signOut()
                  }}>
                  Sign Out
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

  
const checkoutSchema = yup.object().shape({
  openingBalance: yup.string().matches(numberRegex, "must be a number").required("required"),
  accountName: yup.string().required("required"),
  // username: yup.string().required("required"),
  businessName: yup.string().required("required"),
});

export default Onboarding;