const assetPlaceHolderImage = 'https://www.loantabs.com/wp-content/uploads/2024/08/Portrait_Placeholder.png'

const uploads3 = (file, setURL)=>{
    if(file)
    {
      const formData = new FormData();
      formData.append("upload_preset", "lagiua2k")
      formData.append("file", file);
      fetch('https://api.cloudinary.com/v1_1/djtx8rz4q/upload', {
        body: formData,
        method: "POST", 
      })
      .then(async r => {
        let data = await r.json()
        setURL(data.secure_url)
        console.log('data.secure_url::: ', data);
      })
      .catch(e =>console.log('cloudinary error: ', e))
    }
    else setURL('noValue')
  }
export {uploads3, assetPlaceHolderImage}
