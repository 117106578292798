import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik} from "formik";
import * as yup from "yup";
import React, { useState} from "react";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { API, graphqlOperation } from "aws-amplify";
import { DatePickerField } from './DatePicker';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../assets/custom.css";
import { tokens } from "../theme";
import { numberRegex } from "../assets/currenciesObj";

const NewPenalty = ({loanId, penaltyAdded, cancelPenalty, 
  addNewPenaltyToStatement}) => {
 
  //states
    const [processing, setProcessing] = useState(false)

  //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const initialValues = {
      amount: '',
      comment: "PENALTY: ",
      penaltyDate: '',
    };
        
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const handleFormSubmit = (values) => {
      values.penaltyDate = new Date(values.penaltyDate?.target?.value)
      const penaltyArgs = {
        amount: parseFloat(values.amount.replace(/,/g, '')),
        penaltyDate: values.penaltyDate,
      };
      const comment = values.comment.includes("PENALTY") 
        ? values.comment : `PENALTY: ${values.comment}` 
      createPenalty(penaltyArgs.amount, penaltyArgs.penaltyDate, comment)
    };

    const createPenalty =  async(amount, penaltyDate, comment) =>{
      setProcessing(true)
      try{
        const createdPenalty = await API.graphql(graphqlOperation(`
          mutation MyMutation {
            createPenalty(input: {
              amount: ${amount}, 
              loanPenaltiesId: "${loanId}", 
              penaltyDate: "${penaltyDate}",
              penaltyAttribute1: "${comment}"
            }) 
            {
              id
            }
          }
        `));
        if (createdPenalty){
          const penaltyId = createdPenalty.data.createPenalty.id;
          penaltyAdded()
          addNewPenaltyToStatement(penaltyId, amount, penaltyDate, comment)
          setProcessing(false)
        }
      }catch(e){
        console.log('error creating penalty: ',e)
      }
    }
    
    return (
      <Box p='20px' sx={{backgroundColor: '#071318'}} mb="20px" >
          <Box className='printNot' pt="20px">
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'} mb="20px">
                <Typography variant='h4' >Add a Penalty:</Typography>
                <CancelPresentationIcon fontSize='large' color='info' onClick={cancelPenalty}
                  cursor="pointer" />
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" gap="20px" justifyContent={'flex-start'}
                    sx={{flexDirection: {xs: 'column', md: 'row'}}}
                  >
                    <Box display="flex" flexDirection={'column'} flex="1">
                      <Typography color={colors.greenAccent[300]}>Penalty Amount:</Typography>
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Enter Penalty Amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.amount.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        name="amount"
                        error={!!touched.amount && !!errors.amount}
                        helperText={touched.amount && errors.amount}
                      />
                    </Box>
                    <Box display="flex" flexDirection={'column'} flex="2">
                      <Typography color={colors.greenAccent[300]}>Comment:</Typography>
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Comment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.comment}
                        name="comment"
                        error={!!touched.comment && !!errors.comment}
                        helperText={touched.comment && errors.comment}
                      />
                    </Box>
                    <Box display="flex" flexDirection={'column'} >
                        <Typography color={colors.greenAccent[300]}>Penalty Date:</Typography>
                        <DatePickerField name="penaltyDate" />
                        {values.penaltyDate === '' &&
                        <Typography style={{fontSize: 11, color: '#E64333'}}>Required</Typography>}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent={'space-between'} mt="30px">
                    <Button type="submit" color="success" variant="contained" 
                      disabled={values.penaltyDate === ''}>
                      Confirm Penalty
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          {processing && <Box display='flex'>
            <Typography
              variant="h4"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px 0 60px 0" }}
            >Processing... Please Wait...</Typography>
          </Box>}
      </Box>
    );
  };
  
  const checkoutSchema = yup.object().shape({
    amount: yup.string().matches(numberRegex, "must be a number").required("required"),
    comment: yup.string().required("required"),
  });
  
export default NewPenalty