import React, { useEffect, useContext } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Header from "../../components/Header";
import { UserIDContext } from '../../App';
import { API, graphqlOperation} from 'aws-amplify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'
import SuccessMessage from "../../components/SuccessMessage";
import '../../assets/custom.css'
import PointsManager from '../../components/PointsManager'
import { loadingBorrowers, emptyBorrowers } from "../../assets/loadingArrays";

const ManageBorrowers = () => {
  const [borrowers, setBorrowers] = useState(loadingBorrowers)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userID = useContext(UserIDContext)
  const navigate = useNavigate();
  
  const {borrowerName} = useParams()

  useEffect(()=>{
    const listofB = []
    const listBorrowers = async(nextToken)=>{
      const token = nextToken || ''
      try{
        const listOfBorrowers = await API.graphql(graphqlOperation(`query MyQuery {
          listBorrowers(
            filter: {userBorrowersId: {eq: "${userID}"}},
            limit: 1000
            ${token}
            ) {
            items {
              firstname
              id
              otherPhoneNumber
              othername
              phoneNumber
            }
            nextToken
          }
        }
        `));
        if(listOfBorrowers.data.listBorrowers.items){

          const borrowerDetails = listOfBorrowers.data.listBorrowers.items.map((borrower)=>({
            id: borrower.id,
            firstName: borrower.firstname,
            otherName: borrower.othername,
            phoneNumber: borrower.phoneNumber,
            otherPhoneNumber: borrower.otherPhoneNumber, 
          }))
          listofB.push(borrowerDetails);
          if(listOfBorrowers.data.listBorrowers.nextToken){
            listBorrowers(`,nextToken: "${listOfBorrowers.data.listBorrowers.nextToken}"`)
          }else{
            const mergedList = [].concat(...listofB);
            mergedList.length > 0 ? setBorrowers(mergedList) : setBorrowers(emptyBorrowers) 
          }
        }
      }catch(e){
        console.log('Error getting Borrowers: ',e)
      }
    }
    listBorrowers()
    // eslint-disable-next-line
  },[])


  const handleRowClick =(params)=>{
    if(params.row.firstName !== '-'){
      navigate(`/createBorrower/:${params.id}/:${params.row.firstName}/:${params.row.otherName}/:${params.row.phoneNumber}/:${params.row.otherPhoneNumber}`)
    }
  }

  const columns = [
    
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "otherName",
      headerName: "Other Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: "otherPhoneNumber",
      headerName: "Other Phone Number",
      align: 'center',
      headerAlign: 'center',
      flex: 1.2,
    },
    {
      field: "label",
      headerName: "",
      flex: 2,
      renderCell: (params) => {
        return (params.row.firstName !== '-'
          ? <Box
            m="3px 3px"
            p="5px"
            display="flex"
            cursor='pointer'
            // justifyContent="center"
            backgroundColor= {colors.blueAccent[500]}
            borderRadius="4px"
          >
            <AddCircleOutlineIcon />
            <Typography color={colors.grey[100]}>
              View/Edit
            </Typography>
          </Box>
          : '-'
        );
      },
    },
  ];

  const columnsWithoutFlex = ()=>{
    return columns.map((field, index)=>{
      field.key = index;
      field.flex = '';
      return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          ml: "20px",
          gap: "10px"
        }}
      >
        <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE A NAME TO SEARCH: </Typography>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box mt="20px"
      sx={{width: {xs: '100%', md: '900px'}}}
    >
      <PointsManager />
      {borrowerName && <SuccessMessage message={`${borrowerName} details sussessfully eddited!`}/>}
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} 
        flexWrap={'wrap'} justifyContent={'space-between'}>
        <Header title="Manage Borrowers" subtitle="Click any Borrower to View and Edit" />
        <Button variant="outlined" color='info' startIcon={<AddCircleOutlineIcon />}
          onClick={()=> navigate(`/createBorrower/`)}>Add New Borrower</Button>
      </Box>
      <Box
        m="20px 0 0 0"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            height: "50px"
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        <DataGrid rows={borrowers} columns={initialColumns} 
          onRowClick={handleRowClick}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: QuickSearchToolbar }}
          initialState={{
            sorting: {
                sortModel: [{field: 'firstName', sort: 'asc'}],
            },
          }}
          sx={{
            '@media (max-width: 900px)': {
              width: "100%",
              overflowX: 'auto'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ManageBorrowers;