import { useNavigate } from 'react-router-dom'
import Header from "../../components/Header"
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useRef, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { DatePickerField } from '../../components/DatePicker';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../../assets/custom.css";
import { tokens } from "../../theme";
import PointsManager from '../../components/PointsManager'
import AlertMessage from "../../components/AlertMessage";
import { numberRegex } from '../../assets/currenciesObj';
import QuantityInput from '../../components/QuantityInput';
import { LoansContext } from '../../App';
import { loanIdIsUnique } from '../../assets/loanPanelCalculations';

const EditLoanValues = ({loanId, cancelLoanEdit, updateLoanDetails, uniqueRef,
  initialDetails}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [maxPeriod, setMaxPeriod] = useState()
    const [processing, setProcessing] = useState('Update Loan Details') 
    const [loanEditDone, setLoanEditDone] = useState()
    const ref = useRef(null);
    const topref = useRef() 
    const navigate = useNavigate();
    const [theDate, setTheDate] = useState(initialDetails.startDate)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [repaymentCycle, setRepaymentCycle] = useState(initialDetails.repaymentCycle)
    const [loanDuration, setLoanDuration] = useState(initialDetails.duration)
    const [loanPeriodFormat, setLoanPeriodFormat] = useState(initialDetails.durationFormat)
    const [numberOfPayments, setNumberOfPayments] = useState(initialDetails.initialNumberOfPayments)
    const [instalmentFrequency, setInstalmentFrequency] = useState(initialDetails.instalmentFrequency)
    const [validSubmission, setValidSubmission] = useState(false) 
    const preventDurationFormatChange = initialDetails?.preventDurationFormatChange
    const {loanIdArray} = useContext(LoansContext)
    //state to ensure unique id
    const [idNotUnique, setIdNotUnique] = useState()

    //useEffect to determin number of payments:
    useEffect(()=>{
      if(loanPeriodFormat === 'days') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration/7));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration/30));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/365));
            break;
        }
      }
      if(loanPeriodFormat === 'weeks') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*7);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration/4));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/52));
            break;
        }
      }
      if(loanPeriodFormat === 'months') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*30);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration*4));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/12));
            break;
        }
      }
      if(loanPeriodFormat === 'years') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*365);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration*52));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration*12));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
        }
      }
      repaymentCycle.includes('Sum') && setNumberOfPayments(1)

    
    }, [loanPeriodFormat, instalmentFrequency, loanDuration, repaymentCycle])

    const initialValues = {
      principal: initialDetails.principal ||'',
      fees: initialDetails.fees || '',
      interestRate: initialDetails.interestRate || '',
      startDate: initialDetails.startDate,
      duration: initialDetails.duration || '', 
      rateFormat: initialDetails.rateFormat || 'per month',
      durationFormat: initialDetails.durationFormat || 'months',
      toggle: initialDetails.fees || false,
      repaymentCycle: initialDetails.repaymentCycle || 'instalments',
      instalmentFrequency: initialDetails.instalmentFrequency || 'per month',
      uniqueRef
    };
    
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'

    const handleFormSubmit = (values) => {
      if(validSubmission){
        if(loanIdIsUnique(values.uniqueRef, uniqueRef, loanIdArray)){
          setIdNotUnique(false)
          honorSubmit(values)
        }else {
          setIdNotUnique(true)
        }
      } 
    }
    
    const honorSubmit = (values) => {
      values.startDate = new Date(values.startDate?.target?.value)
      const newLoanDetails = {
        principal: parseFloat(values.principal.replace(/,/g, '')),
        fees: values.fees===''? 0 : parseFloat(values.fees.replace(/,/g, '')),
        interestRate: parseFloat(values.interestRate),
        startDate: values.startDate,
        duration: values.duration,
        rateInterval: (values.repaymentCycle === 'instalments') && (values.rateFormat === 'per loan')
          ? 'per month' : values.rateFormat,
        durationInterval: values.durationFormat,
        repaymentCycle: values.repaymentCycle,
        numberOfPayments,
        instalmentFrequency: values.instalmentFrequency,
        uniqueRef: values.uniqueRef,
      };
      setProcessing('Processing... please wait')
      updateLoan(newLoanDetails)  
    };

    const updateLoan =  async(newLoanDetails) =>{
        const {principal, fees, interestRate, startDate, duration, rateInterval,
          durationInterval, repaymentCycle, instalmentFrequency, uniqueRef} = newLoanDetails
      try{
        // eslint-disable-next-line
        const updatedLoan = await API.graphql(graphqlOperation(`mutation MyMutation {
          updateLoan(input: {
            id: "${loanId}", 
            interestRate: ${interestRate}, 
            principal: ${principal}, 
            startDate: "${startDate}"
            duration: ${duration}
            durationInterval: "${durationInterval}"
            rateInterval: "${rateInterval}"  
            laonType: "${repaymentCycle}" 
            paymentFrequency: "${instalmentFrequency}"
            uniqueRef: "${uniqueRef}", 
          }) {
            id
          }
        }
        `))     
        if(updatedLoan){
          setLoanEditDone(updatedLoan)
          newLoanDetails.interestRate =  newLoanDetails.interestRate + "%"
          updateLoanDetails(newLoanDetails)
        }
      }
      catch(e){
        console.log('Loan update Failed', e)
      }
    }
  
    return (
      <Box>
        <PointsManager />
        {loanEditDone && <Box p="20px">
            <Header title={'LOAN SUCCESSFULLY UPDATED'} />
          </Box>}
        {!loanEditDone && <Box mt="20px">
            <Box className='printNot'>
              <Box ref={topref}>
                <Header title="Edit the Loan Details" />
              </Box>
              <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Loan ID:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" flexDirection={'row'} gap="20px">
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                        <CssTextField focusColor={labelFocusColor}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Update the loan ID"
                            onBlur={handleBlur}
                            onChange={(e)=>{
                              handleChange(e)
                              setIdNotUnique(false)
                            }}
                            value={values.uniqueRef}
                            name="uniqueRef"
                            error={!!touched.uniqueRef && !!errors.uniqueRef}
                            helperText={touched.uniqueRef && errors.uniqueRef}
                          />
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Principal:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" flexDirection={'row'} gap="20px">
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                        <CssTextField focusColor={labelFocusColor}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Update the amount being borrowed"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.principal.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            name="principal"
                            error={!!touched.principal && !!errors.principal}
                            helperText={touched.principal && errors.principal}
                          />
                      </Box>
                    </Box>
                  </Box>
                  {!preventDurationFormatChange && <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Repayment Cycle:</Typography>}
                  {!preventDurationFormatChange && <Box role="group" aria-labelledby="my-radio-group"
                    sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" justifyContent={'space-between'}
                      sx={{flexDirection: {xs: 'column', md: 'row'}}}
                    >
                      <Box gap="10px" sx={{
                        display: 'flex', flexDirection: 'column', 
                        width: {xs: '100%', md: "48%"}}}>
                        <Box sx={{ }}>
                          <label>
                            <Field type="radio" name="repaymentCycle"
                            value="instalments" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                            }}/>
                            {`Equal Instalments - Reducing Balance`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={<Box>
                            <Typography>The interest is calculated on the unpaid balance.</Typography>
                            <Typography>The payments are distributed into equal instalments.</Typography>
                          </Box>}/>
                        </Box>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle"
                            value="flatInstalments" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                            }}/>
                            {`Equal Instalments - Flat Rate`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`The interest is calculated on the Principal and Duration at the start of the loan. \n
                          The payments are distributed into equal instalments.`}/>
                        </Box>
                      </Box>
                      <Box gap="10px" sx={{display: 'flex', flexDirection: 'column', 
                        width: {xs: '100%', md: "48%"}, mt: {xs: '10px', md: 0}}}>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle" 
                            value="lumpSum" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                              setNumberOfPayments(1)
                            }}/>
                            {`Lump-sum Payment - Compound Interest`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`Interest is compounded per month: \n(NewMonthPricipal = PastMonthPricipal + Interest).\n
                          One lumpsum payment is made at the end of the loan duration.`}/>
                        </Box>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle" 
                            value="simpleLumpSum" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                              setNumberOfPayments(1)
                            }}/>
                            {`Lump-sum Payment - Simple Interest`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`Interest is calculated on the Principal and Duration.\n
                          One lumpsum payment is made at the end of the loan duration.`}/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>}

                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Interest Rate:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" flexDirection={'row'} gap="20px"
                      flexWrap={'wrap'}>
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Loan Interest %"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.interestRate}
                        name="interestRate"
                        error={!!touched.interestRate && !!errors.interestRate}
                        helperText={touched.interestRate && errors.interestRate}
                        sx={{ width: {xs: "100%", md: "48%"} }}
                      />
                      <Box display='flex' gap="20px">
                        <Box role="group" aria-labelledby="my-radio-group"
                          sx={{display: 'flex', flexDirection: 'row'}} >
                          <Box gap="10px" mr="10px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per day" />
                                Per Day
                              </label>
                            </Box>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per week" />
                                Per Week
                              </label>
                            </Box>
                          </Box>
                          <Box gap="10px" mr="15px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per month" />
                                Per Month
                              </label>
                            </Box>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per year" />
                                Per Year
                              </label>
                            </Box>
                          </Box>
                          {repaymentCycle !== 'instalments' && repaymentCycle !== 'lumpSum'
                          && <Box gap="10px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per loan" />
                                Per Loan
                              </label>
                            </Box>
                          </Box>}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Start Date:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="15px"
                      sx={{flexDirection: {xs: 'column', md: 'row'}, 
                        alignItems: {xs: 'flex-start', md: 'center'}}}>
                        <Typography >Set the Start Date of the loan: </Typography>
                        <DatePickerField name="startDate" setTheDate={setTheDate}/>
                    </Box>
                    {values.startDate === '' &&
                    <Typography fontSize="11px" color="red" mt="10px">required</Typography>}
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Loan Duration:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="20px"
                      sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                      <Box display="flex" gap="10px"
                        sx={{flexDirection: {xs: 'column', md: 'row'},
                          alignItems: {xs: 'flex-start', md: 'center' },
                          justifyContent: {xs: 'flex-start', md: 'center' }
                        }}>
                        <Typography>Set the duration of the loan: </Typography>
                        <Box>
                          <QuantityInput
                            number={loanDuration}
                            setNumber={setLoanDuration}
                            setValidSubmission={setValidSubmission}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                      </Box>
                      <Box display='flex' width="23%" flexDirection={'column'}
                        sx={{width: {xs: '130px', md: '23%'}}}
                        justifyContent={'center'}>
                          {/* <Typography>Period:</Typography> */}
                          <Field
                            as="select"
                            name="durationFormat"
                            onChange={(e)=>{
                              setLoanPeriodFormat(e.target.value)
                              setFieldValue('durationFormat', e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value="days">Day(s)</option>
                            <option value="weeks">Week(s)</option>
                            <option value="months">Month(s)</option>
                            <option value="years">Year(s)</option>
                          </Field>
                          {maxPeriod && <p style={{color: 'red'}}>Max Period is 8 Years</p>}
                      </Box>
                    </Box>
                    
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Number of Payments (Instalments):</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="20px"
                      sx={{flexDirection: {xs: 'column', md: 'row'},
                      alignItems: {xs: 'flex-start', md: 'center' }
                      }}>
                      {!repaymentCycle.includes('Sum') &&
                      <Box display='flex' gap="20px"
                          sx={{flexDirection: {xs: 'column', md: 'row'},
                          alignItems: {xs: 'flex-start', md: 'center' },
                        }}>
                          <Typography sx={{width: {xs: '100%', md: '30%'}}}
                            >How often should a payment be made?</Typography>
                          <Box role="group" aria-labelledby="my-radio-group" gap="10px"
                            sx={{display: 'flex', flexDirection: 'column', width: {xs: '100%', md: '68%'}}}>
                              <Box display="flex" flexDirection={'row'} gap="20px">
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                    setFieldValue('instalmentFrequency', e.target.value)
                                  }}
                                  value="per day" />
                                  1 per Day
                                </label>
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per week" />
                                  1 per Week
                                </label>
                              </Box>
                              <Box display="flex" flexDirection={'row'} gap="20px">
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per month" />
                                  1 per Month
                                </label>
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per year" />
                                  1 per Year
                                </label>
                              </Box>
                          </Box>
                      </Box>}
                      <Box sx={{width: {xs: '100%', md: '48%'}}} display="flex" flexDirection={'row'} 
                        gap="10px" alignItems={'baseline'}>
                        <Typography variant="h3" color={colors.greenAccent[300]}
                          >{numberOfPayments}</Typography>
                        <Typography variant="h5">INSTALMENT(S) SCHEDULED</Typography>
                      </Box>
                    </Box>
                  </Box>
    {/* submit button */}
                  </Box>
                    {idNotUnique && <Typography variant="h5" color='red'>
                      This Loan ID has already been assigned. Please use another Loan ID.
                    </Typography>}
                  <Box display="flex" mt="30px" gap="30px">
                      <Button type="submit" color="success" variant="contained"
                        onClick={()=>setValidSubmission(true)}
                        disabled={theDate?.length !== 10}>
                        {processing}
                      </Button>
                      <Button onClick={cancelLoanEdit} color="info" variant="outlined">
                        CANCEL
                      </Button>
                    </Box>
                </form>
              )}
            </Formik>
            </Box>
        </Box>}
      </Box>
    );
  };
  const durationRegex = /^(?:[1-9]\d{0,2}|1000)$/;
  const interestRateRegEx = /^(0*(\d{1,2}(\.\d+)?)|\.(\d+)|100(\.0+)?)$/
  const uniqueRefRegex = /^[a-zA-Z0-9]{1,20}$/
  
  const checkoutSchema = yup.object().shape({
    uniqueRef: yup.string().matches(uniqueRefRegex, "Only numbers and letters. Max 20 characters").required("required"),
    principal: yup.string().matches(numberRegex, "must be a number").required("required"),
    interestRate: yup.string().matches(interestRateRegEx, "Must be a number. Min 0. Max 100.").required("required"),
  });
  
export default EditLoanValues