import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { API, graphqlOperation} from 'aws-amplify';

//componet imports
import ReportDateRange from '../../components/ReportDateRange';
import { tokens } from '../../theme';
import { LoansContext } from '../../App';
import { UserTypeContext, UserIDContext } from '../../App';
import { harvestTokens } from '../../assets/configureAccounts';
import { configureTransactions } from '../../assets/incomeStatmentCalculations';

function IncomeStatement() {

    //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const {loansContext} = useContext(LoansContext)
    const {decimalPoints} = useContext(UserTypeContext);
    const userID = useContext(UserIDContext)

    //states
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [rows, setRows] = useState()
    const [nextTokens, setNextTokens] = useState()
    const [moreTransactions, setMoreTransactions] = useState()

    //useEffect
    useEffect(() =>{
        const getAllTransactions = async(transactionsNextToken1, loanFeesNextToken1, loansNextToken1)=>{
          
            const transactionsNextToken = transactionsNextToken1 || ''
            const loanFeesNextToken = loanFeesNextToken1 || ''
            const loansNextToken = loansNextToken1 || ''
          
            try{
            const listOfAccounts = await API.graphql(graphqlOperation(`query MyQuery {
              listAccounts(
                filter: {userAccountsId: {eq: "${userID}"}},
                limit: 1000
              ) {
                items {
                  currency
                  transactions(filter: {id: {attributeExists: true}}, limit: 1000
                    ${transactionsNextToken}
                    ) {
                    nextToken
                    items {
                      attribute1
                      date
                      details
                      id
                      type
                      amount
                    }
                  }
                  loanFees(
                    filter: {loanFeesId: {attributeExists: true}, id: {attributeExists: true}},
                    limit: 1000
                    ${loanFeesNextToken}
                  ) {
                    nextToken
                    items {
                      loanFees {
                        amount
                        loan {
                          startDate
                        }
                      }
                    }
                  }
                  loans(filter: {loanId: {attributeExists: true}}, limit: 1000
                    ${loansNextToken}
                    ) {
                    nextToken
                    items {
                      loan {
                        fees
                        startDate
                      }
                    }
                  }
                }
              }
            }
            `));
            if(listOfAccounts.data.listAccounts.items.length > 0) {
              setMoreTransactions(listOfAccounts.data.listAccounts.items)
              setNextTokens(harvestTokens(listOfAccounts.data.listAccounts.items))
            }
          }catch(e){
            console.log('Error Listing Accounts: ',e)
            if(e.data.listAccounts.items.length > 0) {
              setMoreTransactions(e.data.listAccounts.items)
            }
          }
        }
        getAllTransactions()
    }, [])

    useEffect(() => {
        const configureReport = () => {
            const loansInPeriod = loansContext.filter((loan) => { 
                return moment(loan.startDate).isSameOrAfter(moment(startDate))
                    && moment(loan.startDate).isSameOrBefore(moment(endDate))
            })
            const moreTransactionsInRange = configureTransactions(moreTransactions, startDate, endDate)
            const rowsArray = []
            const valueSum = (value) => {
                return loansInPeriod.reduce((acc, loan) => {      
                  return acc + loan[value]
                },0)
              }
            //income header
            rowsArray.push({id: "I", item: 'INCOME / REVENUE'})
            //revenue row:
            rowsArray.push({
                id: 'I1',
                item: `Revenue (Interest on Loans + Penalties)`,
                amount: valueSum('balance') + valueSum('totalPaid') - valueSum('principal') 
            })
            //get all incomes
            moreTransactionsInRange && rowsArray.push(...moreTransactionsInRange.iRows)
            //total income
            const totalRevenueRow ={
                id: 'TI',
                amount: 'TOTAL INCOME / REVENUE',
                total: rowsArray.reduce((acc, row) => {
                    if(typeof(row.amount)=== 'number'){
                        return acc + row?.amount
                    }else return acc + 0
                },0)
            }
            rowsArray.push(totalRevenueRow)
            //get all expenses
            const expenses = moreTransactionsInRange?.eRows || []
            moreTransactionsInRange && rowsArray.push(...expenses)
            //total expenses
            const totalExpensesRow ={
                id: 'TE',
                amount: 'TOTAL EXPENSES',
                total: expenses.reduce((acc, row) => {
                    if(typeof(row.amount)=== 'number'){
                        return acc + row?.amount
                    }else return acc + 0
                },0) 
            }
            rowsArray.push(totalExpensesRow)
            
            //Net Profit
            rowsArray.push({id: "P/L", item: 'PROFIT / LOSS'})
            rowsArray.push({
                id: 'T NP/NL',
                amount: 'NET PROFIT',
                total: totalRevenueRow.total - totalExpensesRow.total
            })

            setRows(rowsArray);
        }
        loansContext && moreTransactions && configureReport()
    },[startDate, endDate, loansContext, moreTransactions])
    

    //columns
    const columns = [
        {
            field: "id",
            sortable: false,
            headerName: "#",
            flex: 0.4,
            align: 'left',
            headerAlign: 'left',
            type: 'text',
        },
        {
            field: "item",
            sortable: false,
            headerName: "Item",
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            type: 'text',
        },
        {
            field: "amount",
            sortable: false,
            headerName: "Amount",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            cellClassName: 'name-column--cell',
            renderCell: (params) => {
              if((params.row.id.indexOf('E') !== -1) && (typeof(params.row?.amount) === 'number')) {
                  return `(${params.row?.amount?.toLocaleString('en-US', {
                      maximumFractionDigits: decimalPoints
                    })})`
                }else{
                    return params.row?.amount?.toLocaleString('en-US', {
                    maximumFractionDigits: decimalPoints
                    })
              }
            }       
        },
        {
            field: "total",
            sortable: false,
            headerName: "Total",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            cellClassName: 'name-column--cell',
            renderCell: (params) => {
                if((params.row.id.indexOf('E') !== -1) && (typeof(params.row?.total) === 'number')) {
                    return `(${params.row?.total?.toLocaleString('en-US', {
                        maximumFractionDigits: decimalPoints
                      })})`
                  }else{
                      return params.row?.total?.toLocaleString('en-US', {
                      maximumFractionDigits: decimalPoints
                      })
                }
            }        
        },
    ] 

    const columnsWithoutFlex = ()=>{
      return columns.map((field, index)=>{
        field.key = index; 
        field.flex = ''
        return field;
      })
    }
    
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  return (
    <Box  display="flex" flexDirection={'column'} mt="20px"
    sx={{ 
        width: {xs:'100%', md:'900px'},
      }}
    >
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}
          gap={'20px'} mb='20px' justifyContent={'space-between'}>
            <Typography variant="h2" >PROFIT / LOSS (INCOME STATEMENT)</Typography>
            <Button color='error' variant='outlined' 
                onClick={()=>navigate('/reportsPanel')}>Close Report</Button>
        </Box>
        <Box>
            {startDate && endDate && <ReportDateRange startDate={startDate} endDate={endDate}
                setStartDate={setStartDate} setEndDate={setEndDate}/>}
        </Box>
        {rows && <Box mt='20px' mb="60px"
            sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important"
            },
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                height: "50px"
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            ".bottomRow": {
                backgroundColor: colors.blueAccent[900],
                borderBottom: "3px solid white",
                fontWeight: "bold",
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .normalLineHeight": {
                height: `200px !important`,
            },
            ".contentRow": {
                marginLeft: '10px',
            }
            }}>
          {initialColumns && <DataGrid rows={rows} columns={initialColumns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            autoHeight={true}
            hideFooter={true}
            getRowClassName={(params) => {
                if(params.row.id.includes('T')){
                    return 'bottomRow'
                }
            }}
            sx={{
              '@media (max-width: 900px)': {
                  width: "100%",
                  overflowX: 'auto'
              }
          }}
          />}
        </Box>}
    </Box>
  )
}

export default IncomeStatement