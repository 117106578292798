import { Formik, Field } from 'formik'
import React, { useState } from 'react'
import { DatePickerField } from './DatePicker'
import { Box, Button, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
//componets
import { tokens } from '../theme'

function ReportDateRange({startDate, endDate, setStartDate, setEndDate, notReport}) {

    //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const initialValues = {
        startDate, 
        endDate,
        period: '30'
    }
    const maxStartDate = moment(endDate).format('YYYY-MM-DD')
    const maxEndDate = moment().format('YYYY-MM-DD')
    const minEndDate = moment(startDate).format('YYYY-MM-DD')
    const triggerButtonText = notReport ? 'Filter by Date' : 'Edit Period'

    //states
    const [editDate, setEditDate] = useState();
    const [selectedOption, setSelectedOption] = useState();

    //functions
    const setDateRange = (end, start, period) => {
        start && setStartDate(moment().subtract(start, 'days').format('YYYY-MM-DD'))
        end && setEndDate(moment().subtract((end-1), 'days').format('YYYY-MM-DD'))
        if(period === 'thisMonth'){
            setEndDate(moment().format('YYYY-MM-DD'))
            setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        }
        if(period === 'thisYear'){
            setEndDate(moment().format('YYYY-MM-DD'))
            setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
        }
        if(period === 'lastMonth'){
            setEndDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
            setStartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
        }
    } 

    const handleChange = (event) => {
        switch(event.target.value){
            case "Past 7 Days": 
                setSelectedOption("Past 7 Days")
                setDateRange(1, 7);
                break;
            case "Past 30 Days": 
                setSelectedOption("Past 30 Days")
                setDateRange(1, 30);
                break;
            case "This Month": 
                setSelectedOption("This Month")
                setDateRange(null, null, 'thisMonth');
                break;
            case "Previous Month": 
                setSelectedOption("Previous Month")
                setDateRange(null, null, 'lastMonth');
                break;
            case "Past 3 Months": 
                setSelectedOption("Past 3 Months")
                setDateRange(1, 91);
                break;
            case "Past 6 Months": 
                setSelectedOption("Past 6 Months")
                setDateRange(1, 182);
                break;
            case "This Year": 
                setSelectedOption("This Year")
                setDateRange(null, null, 'thisYear');
                break;
            default: 
                setSelectedOption("Past 30 Days")
                setDateRange(1, 30);
        }
    }

  return (
    <Formik
        onSubmit={()=>{}}
        initialValues={initialValues}
        // validationSchema={checkoutSchema}
    >
        {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
    
        }) => (
            <form onSubmit={handleSubmit}>
                <Box display={'flex'} mb="20px" alignItems={'baseline'}
                    sx={{
                        flexDirection: {xs: 'column', md: 'row'},
                        gap: {xs: '10px', md: '30px'}
                    }}
                >
                    {!notReport && <>
                    <Typography variant="h5" color={colors.greenAccent[300]}>
                        Report Period: </Typography>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                        <Typography variant="h6">START: </Typography>
                        <Typography variant="h4" color={colors.greenAccent[300]} ml="7px">
                            {moment(startDate).format('DD-MMM-YYYY')} </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                        <Typography variant="h6">END: </Typography>
                        <Typography variant="h4" color={colors.greenAccent[300]} ml="7px">
                            {moment(endDate).format('DD-MMM-YYYY')} </Typography>
                    </Box>
                    </>}
                    {!editDate && <Button color={'info'} fontSize="small" onClick={()=>setEditDate(true)}
                        startIcon={<EditCalendarIcon fontSize='small'/>} 
                        variant={notReport ? 'text' : 'outlined'}> {triggerButtonText}
                    </Button>}
                </Box>
                {editDate && <Box backgroundColor={'#071318'} display="flex" 
                    flexDirection={'column'} >
                    
                    <Box display="flex" flexDirection="row" justifyContent='flex-end'>
                        <IconButton size='large'
                            onClick={()=>{
                                notReport && setStartDate(moment('2020-01-01').format("YYYY-MM-DD"));
                                setEditDate(false)
                            }} color='error'>
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection={'row'} pl="20px" pr="20px"
                        flexWrap={'wrap'}
                        sx={{
                            justifyContent: {xs: 'flex-start', md: 'space-between'},
                            gap: '20px',
                        }}
                        justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="Past 7 Days"
                                checked={selectedOption === 'Past 7 Days'}
                            />Past 7 Days
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="Past 30 Days"
                                checked={selectedOption === 'Past 30 Days'}
                            />Past 30 Days
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="This Month"
                                checked={selectedOption === 'This Month'}
                            />This Month
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="Previous Month"
                                checked={selectedOption === 'Previous Month'}
                            />Previous Month
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="Past 3 Months"
                                checked={selectedOption === 'Past 3 Months'}
                            />Past 3 Months
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="Past 6 Months"
                                checked={selectedOption === 'Past 6 Months'}
                            />Past 6 Months
                        </Typography>
                        <Typography>
                            <input type="radio" onChange={handleChange} value="This Year"
                                checked={selectedOption === 'This Year'}
                            />This Year
                        </Typography>
                        
                    </Box>
                    <Box display={'flex'} p="20px"
                        sx={{
                            flexDirection: {xs: 'column', md: 'row'},
                            gap: {xs: '10px', md: '30px'},
                            alignItems: {xs: 'flex-start', md: 'center'}
                        }}
                    >
                        <Typography variant='h5'color={colors.greenAccent[200]} >Set Custom Period:</Typography>
                        <Box display={'flex'} flexDirection={'row'} gap="3px" alignItems={'center'}>
                            <Typography color={colors.blueAccent[200]} variant="h6">
                                START:
                            </Typography>
                            <DatePickerField name="startDate" setTheDate={setStartDate} max={maxStartDate}/>
                            {values.startDate === '' &&
                            <p style={{fontSize: 12, color: '#E64333'}}>Start Date Required</p>}
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} gap="3px" alignItems={'center'}>
                            <Typography color={colors.blueAccent[200]} variant="h6"
                                sx={{mr: {xs: '12px', md: 0}}}
                            >
                                END:
                            </Typography>
                            <DatePickerField name="endDate" setTheDate={setEndDate} min={minEndDate}
                                max={maxEndDate}/>
                            {values.endDate === '' &&
                            <p style={{fontSize: 12, color: '#E64333'}}>End Date Required</p>}
                        </Box>
                    </Box>                        
                </Box>}
                {notReport && editDate && <Box p="20px" backgroundColor={'#071318'} display="flex" 
                    mb="20px" alignItems={'baseline'}
                    sx={{
                        flexDirection: {xs: 'column', md: 'row'},
                        gap: {xs: '10px', md: '30px'},
                    }}
                >
                    <Typography variant="h5" color={colors.blueAccent[200]}
                    >ONLY DISPLAYING LOANS TAKEN BETWEEN:  </Typography>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                        <Typography variant="h6">START: </Typography>
                        <Typography variant="h4" color={colors.greenAccent[300]} ml="7px">
                            {moment(startDate).format('DD-MMM-YYYY')} </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                        <Typography variant="h6">END: </Typography>
                        <Typography variant="h4" color={colors.greenAccent[300]} ml="7px">
                            {moment(endDate).format('DD-MMM-YYYY')} </Typography>
                    </Box>
                </Box>}
            </form>
        )}
    </Formik>
  )
}

export default ReportDateRange