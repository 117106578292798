import { Box, Button, Typography } from "@mui/material";
import React from "react";
import generatePDF, { Margin } from "react-to-pdf";

const MyDocument = () => {
  const downloadPDF = () => {
    // you can also pass React refs, e.g. `generatePDF(ref, options)`
    generatePDF(() => document.getElementById("container"), {
      method: "save",
      filename: "function-example.pdf",
      page: { margin: Margin.MEDIUM },
    });
  };
  return (
    <Box>
      <Button color="error" onClick={downloadPDF}>Download PDF</Button>
      <div id="container">
        <Typography color="blue">
        Important Notes
Not vectorized - the pdf is created from a screenshot of the component and therefore is not vectorized. If you are looking for something more advanced to generate pdf using React components, please check out other popular alternatives packages listed below.
No SSR
Alternatives and Similars Packages
@react-pdf/renderer - React renderer to create PDF files on the browser and server
react-pdf - Display PDFs in your React app as easily as if they were images.
Examples
Code Sandbox demo
Using usePDF hook
Using default function
Multipage support
Advanced options
Usage
Using usePDF hook


        </Typography>
      </div>
    </Box>
  );
};

export default MyDocument;