import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

export default function BasicPie({data}) {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const width = window.innerWidth >= 480 ? 400 : 300

  return (
    <PieChart
      colors={[colors.greenAccent[600], colors.redAccent[500], 'orange' ]}
      series={[
        {
          data,
        },
      ]}
      width={width}
      height={300}
    />
  );
}