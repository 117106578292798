import { useParams, useNavigate } from 'react-router-dom'
import Header from "../../components/Header"
import { Box, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useRef, useEffect} from "react";
import { styled } from "@mui/material/styles";
import { API, graphqlOperation} from 'aws-amplify';
import { formatNumber, formatGraphQLDate } from '../../assets/loanStatementCalculations';
import LoanStatement from '../../components/LoanStatement';
import "../../assets/custom.css";
import LoanStatementForLumpSum from '../../components/LoanStatementForLumpSum';
import { tokens } from "../../theme";
import PointsManager from "../../components/PointsManager";
import SuccessMessage from '../../components/SuccessMessage';

const ViewLoanStatementAsChild = ({loan, viewLoans, updateLoans}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [loanSchedule, setLoanSchedule] = useState()
    const [loanScheduleForLumpSum, setLoanScheduleForLumpSum] = useState()
    const payments = loan.payments.map((item) => {
      return({
        id: item.id,
        paymentAmount: item.amount, 
        paymentDate: formatGraphQLDate(item.paymentDate)
      })
    })
    const penalties = loan.penalties.map((item) => {
      return({
        id: item.id,
        penaltyAmount: item.amount, 
        penaltyDate: formatGraphQLDate(item.penaltyDate),
        comment: item.penaltyAttribute1 || "PENALTY"
      })
    })
    const [paymentsData, setPaymentsData] = useState(payments)
    const [penaltiesData, setPenaltiesData] = useState(penalties)
    const [updatedStatus, setUpdatedStatus] = useState()
    const [updatedPayments, setUpdatedPayments] = useState([false, false])
    const ref = useRef(null);
    const topref = useRef()
    const navigate = useNavigate();
    const {loanId, borrowerName, principal, interestRate, borrowerID, schedule, 
      startDate, duration, rateInterval, durationInterval, loanType, status,
      lateInterestBySystem, uniqueRef,
      numberOfPayments, paymentFrequency} = loan

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor" 
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'

    const listofP = []
    const listOfPenalties = []
    const paymentsArrayGroup = []
    const penaltiesArrayGroup = []
    
    const getPaymentsByLoanId = async(nextToken) =>{
      const token = nextToken || ''
      try{
        const paymentsList = await API.graphql(graphqlOperation(`
          query MyQuery {
            listPayments(
              filter: {loanPaymentsId: {eq: "${loanId}"}},
              limit: 1000
              ${token}
            ) {
              nextToken
              items {
                amount
                paymentDate
                id
              }
            }
          }
        `));
        if(paymentsList){
          const paymentsArray = paymentsList.data.listPayments.items
          paymentsArrayGroup.push(paymentsArray);
          const formatedPaymentsArray = paymentsArray.map((item) => {
            return({
              id: item.id,
              paymentAmount: item.amount, 
              paymentDate: formatGraphQLDate(item.paymentDate)
            })
          })
          listofP.push(formatedPaymentsArray)
          if(paymentsList.data.listPayments.nextToken){
            getPaymentsByLoanId(`,nextToken: "${paymentsList.data.listPayments.nextToken}"`)
          }else{
            const mergedList = [].concat(...listofP); 
            const mergedPaymentsArray = [].concat(...paymentsArrayGroup);
            setPaymentsData(mergedList)
            updateLoans(mergedPaymentsArray, loan.counter)
            setUpdatedPayments([mergedPaymentsArray, loan.counter])
          }
        }
      }catch(e){
        console.log('Error getting payments: ',e)
      }
    }
    
    const getPenaltiesByLoanId = async(nextToken) =>{
      const token = nextToken || ''
      try{
        const penaltiesList = await API.graphql(graphqlOperation(`
          query MyQuery {
            listPenalties(
              filter: {loanPenaltiesId: {eq: "${loanId}"}},
              limit: 1000
              ${token}
            ) {
              nextToken
              items {
                amount
                penaltyDate
                id
                penaltyAttribute1
              }
            }
          }
        `));
        if(penaltiesList){
          const penaltiesArray = penaltiesList.data.listPenalties.items
          penaltiesArrayGroup.push(penaltiesArray);
          const formatedPenaltiesArray = penaltiesArray.map((item) => {
            return({
              id: item.id,
              penaltyAmount: item.amount, 
              penaltyDate: formatGraphQLDate(item.penaltyDate),
              comment: item.penaltyAttribute1 || 'PENALTY'
            })
          })
          listOfPenalties.push(formatedPenaltiesArray)
          if(penaltiesList.data.listPenalties.nextToken){
            getPenaltiesByLoanId(`,nextToken: "${penaltiesList.data.listPenalties.nextToken}"`)
          }else{
            const mergedList = [].concat(...listOfPenalties); 
            const mergedPenaltiesArray = [].concat(...penaltiesArrayGroup);
            setPenaltiesData(mergedList)
            updateLoans(null, null, loanId, null, null, null, mergedPenaltiesArray)
            //continue here
            // setUpdatedPayments([mergedPenaltiesArray, loan.counter])
          }
        }
      }catch(e){
        console.log('Error getting penalties: ',e)
      }
    }

    const updateStatus = async(newStatus) => {
      try{
        const clearedLoan = await API.graphql(graphqlOperation(`
          mutation MyMutation {
            updateLoan(input: {id: "${loanId}", loanStatus: "${newStatus}"})
            {
              id
            }
          }        
        `));
        if(clearedLoan){
          setUpdatedStatus(newStatus)
        }

      }catch(e){
        console.log('Error updating Loan Status: ',e)
      }
    }

    const stopInterest = async(newStatus) => {
      try{
        const modifiedLoan = await API.graphql(graphqlOperation(`
          mutation MyMutation {
            updateLoan(input: {id: "${loanId}", loanAttribute1: "${newStatus}"})
            {
              id
            }
          }        
        `));
        if(modifiedLoan){
          updateLoans(null, null, loanId, null, null, {value: newStatus})
        }

      }catch(e){
        console.log('Error updating Loan Status: ',e)
      }
    }

    const addPayment = () => {
      getPaymentsByLoanId()
    }
    const addPenalty = () => {
      getPenaltiesByLoanId()
    }

    useEffect(() => {
      if (loanType === 'instalments'){
        setLoanScheduleForLumpSum(null)
        setLoanSchedule(schedule)
      }else if (loanType === 'lumpSum'){
        setLoanSchedule(null)
        setLoanScheduleForLumpSum(schedule)
      }else if (loanType === 'flatInstalments'){
        setLoanScheduleForLumpSum(null)
        setLoanSchedule(schedule)
        // setLoanScheduleForLumpSum(schedule)
      }else if (loanType === 'simpleLumpSum'){
        setLoanSchedule(null)
        setLoanScheduleForLumpSum(schedule)
      }
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    },[])

    return (
      <Box >
        <PointsManager />
          <Box className='printNot'>
            <Box ref={topref} sx={{marginBottom: 5}}>
              {updatedStatus && <SuccessMessage message={`Loan Status Updated`}/>}
              <Header title={`LOAN DETAILS`}/>
            </Box>
          </Box>
          <Box>
            <div ref={ref}>
            { loanSchedule && paymentsData && penaltiesData &&
                  <LoanStatement
                  loanData={loanSchedule[0]}
                  LoanAmount={formatNumber(principal)}
                  InterestRate={parseFloat(interestRate.slice(0,-1))}
                  Period={rateInterval}
                  Installment={loanSchedule[0][0].totalPayment}
                  noOfInstallments={loanSchedule[1]}
                  borrowerID={borrowerID}
                  borrower={borrowerName}
                  viewLoans={viewLoans}
                  startDate={loanSchedule[3]}
                  LoanEndDate={loanSchedule[0][loanSchedule[0].length-1].date}
                  paymentsData={paymentsData}
                  penaltiesData={penaltiesData}
                  duration={duration}
                  loanId={loanId}
                  addPayment={addPayment}
                  addPenalty={addPenalty}
                  stopInterest={stopInterest}
                  updateStatus={updateStatus}
                  status={status}
                  updatedStatus={updatedStatus}
                  repaymentCycle={loanType}
                  updatedPayments={updatedPayments}
                  updateLoans={updateLoans}
                  currency={loan.currency || ''}
                  amountDue={loan.amountDue}
                  lIBS={lateInterestBySystem}
                  loanPeriodFormat={durationInterval}
                  grossLoanValue={loanSchedule[6]}
                  paymentFrequency={paymentFrequency}
                  initialNumberOfPayments={loan.initialNumberOfPayments}
                  uniqueRef={uniqueRef}
                  />
            
              }
            { loanScheduleForLumpSum && paymentsData && penaltiesData &&
                  <LoanStatementForLumpSum
                  loanData={loanScheduleForLumpSum[0]}
                  LoanAmount={formatNumber(principal)}
                  InterestRate={parseFloat(interestRate.slice(0,-1))}
                  Period={rateInterval}
                  noOfInstallments={loanScheduleForLumpSum[1]}
                  borrowerID={borrowerID}
                  borrower={borrowerName}
                  viewLoans={viewLoans}
                  startDate={loanScheduleForLumpSum[3]}
                  LoanEndDate={loanScheduleForLumpSum[0][loanScheduleForLumpSum[0].length-1].date}
                  paymentsData={paymentsData}
                  penaltiesData={penaltiesData}
                  duration={duration}
                  loanId={loanId}
                  addPayment={addPayment}
                  addPenalty={addPenalty}
                  stopInterest={stopInterest}
                  updateStatus={updateStatus}
                  status={status}
                  updatedStatus={updatedStatus}
                  repaymentCycle={loanType}
                  updatedPayments={updatedPayments}
                  updateLoans={updateLoans}
                  currency={loan.currency || ''}
                  lIBS={lateInterestBySystem}
                  loanPeriodFormat={durationInterval}
                  initialNumberOfPayments={loan.initialNumberOfPayments}
                  uniqueRef={uniqueRef}
                  />
              }
            </div>
          </Box>
      </Box>
    );
  };
  
export default ViewLoanStatementAsChild