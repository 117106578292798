import React, { useState, useContext} from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import '../../assets/custom.css'
import PointsManager from "../../components/PointsManager";
import ViewLoanStatementAsChild from "./ViewLoanStatementAsChild";
import { UserTypeContext } from "../../App";
import { emptyBorrowers } from "../../assets/loadingArrays";
import generatePDF, { Margin } from "react-to-pdf";
import { dateToday } from "../../assets/getDate";

const ViewLoansAndPayments = ({loans, hidePanel, updateLoans, deleteLoan, filter, loansStatus}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const options = { day: "2-digit", month: "short", year: "2-digit" };
  const [loanParams, setLoanParams] = useState()
  const {decimalPoints} = useContext(UserTypeContext);
  const loanRows = loans.length > 0? loans : emptyBorrowers

  //for pdf export
  const [pdfSaving, setPdfSaving] = useState()
  const [printWidth, setPrintWidth] = useState('900px')
  const {businessName} = useContext(UserTypeContext)
  const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 

  const handleRowClick =(params)=>{
    if(params.id !== 'noDataAvailable'){
      hidePanel(true)
      setLoanParams(params.row)
    }
  }

  const viewLoans = (loanId) => {
    loanId && deleteLoan(loanId)
    setLoanParams(null)
    hidePanel(false)
  }

  const columnTotal = (column) => {
    return loans.reduce((acc, loan) => {      
      return acc + loan[column]
    },0)
  }
 
  const columns = [
    
    {
      field: "borrowerName", 
      headerName: "Borrower",
      flex: 1.8,
      cellClassName: "name-column--cell",
      headerClassName: "printBlackHeader",
    },
    {
      field: "uniqueRef", 
      headerName: "Loan ID",
      flex: 0.3,
      cellClassName: "name-column--cell",
      headerClassName: "printBlackHeader",
      renderCell: (params)=>(<Typography
        sx={{
          fontSize: "10px", color: colors.blueAccent[200], fontStyle: 'italic'
        }}>
        {params.row.uniqueRef}</Typography>)
    },
    {
      field: "principal",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Principal Released",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.principal) ? params.row.principal.toLocaleString('en-US', {
           maximumFractionDigits: decimalPoints
        })
        : 0
      }
    },
    {
      field: "startDate",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Date Taken",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'date',
      renderCell: (params)=>{
        return params.row.startDate ? params.row.startDate.toLocaleDateString("en-UK", options) : ''
      }
    },
    {
      field: "maturityDate",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Maturity Date",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      // type: 'date',
      renderCell: (params)=>{
        return params.row.maturityDate ? params.row.maturityDate.toLocaleDateString("en-UK", options) : ''
      }
    },
    {
      field: "interestRate",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Interest",
      align: 'center',
      headerAlign: 'center',
      flex: 0.6,
    },
    {
      field: "duration",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Duration",
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.durationInterval
          ? `${params.row.duration}-${params.row.durationInterval.slice(0,1).toUpperCase()} `
          : params.row.duration
      }
    },
    {
      field: "amountDue",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Amount Due",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.amountDue) ? 
          params.row.amountDue.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
          : 0
      }
    },
    {
      field: "totalPaid",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Total Paid",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.totalPaid)
        ? params.row.totalPaid.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
        : 0
      }
    },
    {
      field: "balance",
      cellClassName: "printBlack",
      headerClassName: "printBlackHeader",
      headerName: "Loan Balance",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.balance)
        ? params.row.balance.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
        : 0
      }
    },
    {
      field: "status",
      headerName: "Loan Status",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      headerClassName: "printBlackHeader",
      renderCell: (params) => {
        if(params.row.status){
          const loanStatus = params.row.status.includes('override') 
            ? params.row.status.slice(12) + ` (OR)` : params.row.status
          return loanStatus.includes('current') 
          ? <Typography color={printWidth === '1080px' ? '#000' : `${colors.greenAccent[300]}`} 
            sx={{fontSize: '10px'}}>{loanStatus.toUpperCase()}</Typography>
          : loanStatus.includes('cleared') ?
            <Typography color={printWidth === '1080px' ? '#000' : `${colors.blueAccent[300]}`} 
              sx={{fontSize: '10px'}}>{loanStatus.toUpperCase()}</Typography>
            : loanStatus && 
            <Typography color={printWidth === '1080px' ? '#000' : `${colors.redAccent[300]}`} 
              sx={{fontSize: '10px'}}>
              {loanStatus.toUpperCase()}</Typography>
        }else return ''
      }
    },
    {
      field: "label",
      headerName: "View / Edit",
      flex: 0.4,
      cellClassName: 'hideToPrint',
      headerClassName: 'hideToPrint',
      renderCell: (params) => {
        return (params.row?.principal >0 ?
          <Box
            m="2px 2px"
            p="4px 5px 4px 5px"
            display="flex"
            sx={{cursor: 'pointer'}}
            // justifyContent="center"
            backgroundColor= {colors.blueAccent[500]}
            borderRadius="4px"
          >
            <ModeEditOutlineIcon fontSize="small" />
          </Box>
          : ''
        );
      },
    },
  ];

  const columnsWithoutFlex = ()=>{
    return columns.map((field, index)=>{
      field.flex = ''
      return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  function QuickSearchToolbar() {
    return (<>
      {!pdfSaving && <Box
        sx={{
          mt: {xs: 0, md: "-10px"},
          pl: "20px", display: "flex",
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: {xs: 'flex-start', md: 'space-between'},
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: '5px'
          }}
        >
          <Typography sx={{color: `${colors.blueAccent[200]} !important`,}}>Type to Search by Borrower or Loan ID</Typography>
          <Box backgroundColor='#121212'>
            <GridToolbarQuickFilter />
          </Box>
        </Box>
        <Box>
          <Button color="info" variant="text"
            onClick={downloadPDF}>Download PDF</Button>
          <GridToolbarExport printOptions={{ disableToolbarButton: true }}
            sx={{color: colors.blueAccent[300]}} />
        </Box>
      </Box>}
     </>
    );
  }

  const downloadPDF = () => {

    setPdfSaving(true)
    setPrintWidth('1080px')
    setTimeout(()=>{
      generatePDF(() => document.getElementById("loansDG"), {
        method: "save",
        filename: `${loansStatus.toUpperCase()}`,
        page: { margin: Margin.MEDIUM },
      });
      setTimeout(()=>{
        setPdfSaving(false)
        setPrintWidth('900px')
      },200)
    }, 500)
  };

  return (
    <Box 
      sx={{ 
        width: printWidth === '1080px' ? '1080px' : {xs:'100%', md:'900px'},
      }}
    > 
      <PointsManager />
      {!loanParams && <Box mt='20px'
        sx={{
          '& .hideToPrint': {
            display: printWidth === '1080px' ? 'none' : 'flex',
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            height: "50px"
          },
          "& .printBlack": {
            color: printWidth === '1080px' ? '#000': colors.grey[100],
          },
          "& .printBlackHeader": {
            color: printWidth === '1080px' ? '#000': colors.grey[100],
            fontWeight: 'bold',
          },
          "& .name-column--cell": {
            color: printWidth === '1080px' ? '#000': colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: printWidth === '1080px' ? '#fff': colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: printWidth === '1080px' ? '#fff': colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: printWidth === '1080px' ? '#fff': colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: printWidth === '1080px' ? '#000': `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        {loans && <Box
          
        >
          <Box mb="40px" display="flex" flexDirection={'row'}
            flexWrap={'wrap'}
            sx={{
              gap: {xs: '20px', md: '40px'},
              justifyContent: {xs: 'center', md: 'space-between'}
            }}
          >
            <Box gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Principal Released:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('principal').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Amount Due:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('amountDue').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Paid:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('totalPaid').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Balance:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('balance').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Interest Charged:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${(columnTotal('balance') + columnTotal('totalPaid')
                - columnTotal('principal')).toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
          </Box>
          <Box id="loansDG">
            {printWidth === '1080px' && <Box>
              <Box display='flex' justifyContent='space-between' gap='20px' 
                mb='10px'>
                <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
                <p style={{color: 'grey'}}>Printed on: {dateToday}</p>
              </Box>
              <Box display='flex' flexDirection={'column'} mb="20px">
              <Typography variant="h2" color={'black'}  
                sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
                {`${hasBusinessName.toUpperCase()}`}</Typography>
              <Typography variant='h3' color={'black'}> {loansStatus.toUpperCase()}</Typography>
              </Box>
            </Box>}
            {initialColumns && <DataGrid rows={loanRows} columns={initialColumns}
              sx={{
                '@media (max-width: 900px)': {
                  width: "100%",
                  overflowX: 'auto'
                }
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: QuickSearchToolbar }}
              initialState={{
                sorting: {
                    sortModel: [{field: 'startDate', sort: 'desc'}],
                },
                filter: {
                  filterModel: {
                    items: [{ field: 'status', value: filter }],
                  },
                },
                // columns: {
                //   columnVisibilityModel: {
                //     status: printWidth !== '1080px',
                //   },
                // },
              }}
              onRowClick={handleRowClick}
            />}
          </Box>
        </Box>}
      </Box>} 
      {loanParams && <Box>
        <ViewLoanStatementAsChild loan={loanParams} viewLoans={viewLoans} 
        updateLoans={updateLoans}/>  
      </Box>}
    </Box>
  );
};

export default ViewLoansAndPayments;