import React, {useEffect} from "react";
import { Box, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import '../../../assets/custom.css'
import ViewTransactions from "./ViewTransactions";
import { useState } from "react";
import SuccessMessage from "../../../components/SuccessMessage";

const ViewAccounts = ({accounts, columns, informationHeading, transactions, accountName, hidePanel,
    openingBalance, createdAt, accountId, includeNewTransaction, deleteTransaction, updateTransactions}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [successMessage, setSuccessMessage] = useState()
  const includeNewAccountTransactions = (accountId, transaction, message) => {
    accountId && includeNewTransaction(accountId, transaction)
    setSuccessMessage(message)
    setTimeout(()=> {
      setSuccessMessage(null)
    }, 6000)
  }
  
  const updateAccountTransactions = (accountId, transaction, message) => {
    accountId && updateTransactions(accountId, transaction)
    setSuccessMessage(message)
    setTimeout(()=> {
      setSuccessMessage(null)
    }, 6000)
  }

  return (
    <Box width="100%">
      {!transactions && informationHeading}
      <Box
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: 0.2,
            borderColor: colors.grey[100],
          },
          "& .MuiDataGrid-cell": {
            borderTop: '0.5px solid',
            borderColor: colors.grey[100],
            paddingTop: "3px",
            // minHeight: "50px !important",
            paddingBottom: "3px",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        {successMessage && <SuccessMessage message={successMessage}/>}
        {!transactions && <DataGrid 
          rows={accounts} 
          columns={columns} 
          sx={{'@media (max-width: 900px)': {
            width: "100%",
            overflowX: 'auto',
            mt: '10px'
          }}}
          getRowHeight={() => 'auto'}
        />}
        {transactions && <ViewTransactions includeNewTransaction={includeNewAccountTransactions}
          transactions={transactions} accountName={accountName} accountId={accountId}
          openingBalance={openingBalance} createdAt={createdAt} hidePanel={hidePanel} 
          updateTransactions={updateAccountTransactions} deleteTransaction={deleteTransaction}/>}
      </Box>
    </Box>
  );
};

export default ViewAccounts;