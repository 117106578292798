import React, { useEffect, useState, useContext } from "react";
import { Box,  Button, Checkbox,  FormControlLabel,  FormGroup, Typography,  useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { API, graphqlOperation } from "aws-amplify";
import { tokens } from "../theme";
import { useNavigate } from 'react-router-dom'
import '../assets/custom.css'
import '../index.css'
import { dateToday } from "../assets/getDate";
import Header from "./Header";
import {createLoanStatement} from "../assets/loanStatementArrays";
import EditLoanDetails from "../scenes/Loans/EditLoanDetails";
import EditLoanValues from "../scenes/Loans/EditLoanValues";
import NewPaymentOnStatement from "./NewPaymentOnStatement";
import SuccessMessage from "./SuccessMessage";
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from "../assets/AmortizedLoanStatementArrays";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintIcon from '@mui/icons-material/Print';
import Download from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import moment from "moment";
import { LoansContext, UserTypeContext } from "../App";
import PaymentReceipt from "../scenes/Payments/PaymentReceipt";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NewPenalty from "./NewPenalty";
import generatePDF, { Margin } from "react-to-pdf";
import { Cancel } from "@mui/icons-material";

const LoanStatementForLumpSum = ({loanId, LoanAmount, InterestRate, startDate,  
  Period, paymentsData, viewLoans, borrower, duration, status, updateStatus, currency,
  addPayment, borrowerID, repaymentCycle, updatedPayments, updateLoans, updatedStatus,
  stopInterest, lIBS, addPenalty, penaltiesData, loanPeriodFormat, uniqueRef,
  initialNumberOfPayments}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const gridRef = useGridApiRef()
  const printerWidth = window.innerWidth >= 900 ? 61 : 90; 
  const borderMargin = '16px'
  const [printWidth, setPrintWidth] = useState(printerWidth) 
  const [paymentWindow, setPaymentWindow] = useState()
  const [paymentMessage, setPaymentMessage] = useState()
  const [amountPaid, setAmountPaid] = useState()
  const [loanRepaymentCycle, setLoanRepaymentCycle] = useState()
  const [overrideStatus, setOverrideStatus] = useState()
  //new for payments on datagrid
  const [rowModesModel, setRowModesModel] = useState({});
  const [loanStatement, setLoanStatement] = useState()
  const [rows, setRows] = useState()
  const [totalPayments, setTotalPayments] = useState()
  const [disableEditButton, setDisableEditButton] = useState(false)
  const [amountDue, setAmountDue] = useState()
  const LoanEndDate = moment(startDate).add(duration, loanPeriodFormat).format('DD-MMM-YYYY')
  const {businessName, userTypeAWS} = useContext(UserTypeContext)
  const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 
  const [lateInterestBySystem, setLateInterestBySystem] = useState(lIBS)
  const [paymentReceiptDetails, setPaymentReceiptDetails] = useState()
  const [penaltyWindow, setPenaltyWindow] = useState()
  const [editingPenalty, setEditingPenalty] = useState(false)
  const buttonDisabled = userTypeAWS === 'viewer' ? true : false
  const {setLoansContext} = useContext(LoansContext)

  // eslint-disable-next-line 
  const [printColor, setPrintColor] = useState(colors.grey[100])
  //Loan mutations states
  const [editLoan, setEditLoan] = useState()
  const [loanDeleted, setLoanDeleted] = useState()

  const [pdfSaving, setPdfSaving] = useState()
  const [deleteConfirm, setDeleteConfirm] = useState()

  //initial Details
  const initialDetails = {
    principal: LoanAmount, 
    interestRate: InterestRate,
    startDate: new Date(formatDate(startDate)),
    duration: duration, 
    rateFormat: Period,
    repaymentCycle: repaymentCycle,
    initialNumberOfPayments,
    durationFormat: loanPeriodFormat,
    preventDurationFormatChange: true,
  }

  //functions to enable the new datagrid payments
  const handleRowEditStop = (params, event) => {
    setEditingPenalty(false)
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (params) => {
    params.row.description.includes('PENALTY') && setEditingPenalty(true)
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setEditingPenalty(false)
  };

  // handling delete
  const handleDeleteClick = ({id, description}) =>()=> {
    (description === 'Payment Made') && getPaymentAccount(id);
    description.includes("PENALTY") && deletePenalty(id)
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setEditingPenalty(false)
  };

  const processRowUpdate = (updatedRow) => {
    setEditingPenalty(false)
    if(updatedRow.description === 'Payment Made'){
      updatePayment(updatedRow)
    }else {
      if(!updatedRow.description.includes("PENALTY")){
        updatedRow.description = `PENALTY: ${updatedRow.description}`
      }
      updatePenalty(updatedRow);
    }
    return updatedRow;
  };


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const editableRow = (params) => {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      
      return [
        <GridActionsCellItem
          disabled={buttonDisabled}
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: '#fff',
          }}
          onClick={()=>{
            handleSaveClick(params.id)
            setDisableEditButton(false)
          }}
        />,
        <GridActionsCellItem
          disabled={buttonDisabled}
          icon={<CancelIcon />}
          label="Cancel"
          className="textInfo"
          onClick={()=>{
            handleCancelClick(params.id)
            setDisableEditButton(false)
          }}
          color="inherit"
        />
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textInfo"
        disabled={disableEditButton || buttonDisabled}
        onClick={()=>{
          handleEditClick(params)
          setDisableEditButton(true)
        }}
        color="#fff"
      />,
      <GridActionsCellItem
        icon={<DeleteOutlineIcon />}
        label="Delete"
        onClick={handleDeleteClick(params.row)}
        color="inherit"
        disabled={buttonDisabled}
      />
    ];
  }

  useEffect(()=>{
    switch(repaymentCycle) {
      case 'instalments': 
        setLoanRepaymentCycle('Equal Instalments - Reducing Balance');
        break;
      case 'lumpSum': 
        setLoanRepaymentCycle('Lump-sum Payment - Compound Interest');
        break;
      case 'flatInstalments': 
        setLoanRepaymentCycle('Equal Instalments - Flat Rate');
        break;
      case 'simpleLumpSum': 
        setLoanRepaymentCycle('Lump-sum Payment - Simple Interest');
        break;
    }
  }, [])

  //loan statement computations 
  useEffect(()=>{
    const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
      paymentsData, duration, repaymentCycle, lateInterestBySystem, penaltiesData,
      Period, loanPeriodFormat,true, status)
      updateRows(loanDetails)
    },[lateInterestBySystem])
  //loan mutation functions 
    
  const updateRows = (loanDetails)=>{
    setRows(loanDetails[0])
    setTotalPayments(loanDetails[1])
    setAmountDue(loanDetails[6])
    setLoanStatement(loanDetails)
  }

  const editLoanDetails = ()=>{
    setEditLoan(true)
  }
  const cancelPayment = ()=>{
    setPaymentWindow(false)
  }
  const cancelPenalty = ()=>{
    setPenaltyWindow(false)
  }

  const paymentAdded =()=>{
    setPaymentWindow(false)
    setPaymentMessage(`Payment Successfully Added`)
    setTimeout(()=>{setPaymentMessage(false)}, 4000)
    addPayment()
  }
  const penaltyAdded =()=>{
    setPenaltyWindow(false)
    setPaymentMessage(`Penalty Successfully Added`)
    setTimeout(()=>{setPaymentMessage(false)}, 4000)
    addPenalty()
  }
//update Datagrid after a payment has been added
  const addNewPaymentToStatement = (paymentId, amount, paymentDate)=>{
    const updatedPaymentsData = [ ...paymentsData, {id: paymentId, paymentDate, amount}]
    const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
      updatedPaymentsData, duration, repaymentCycle, lateInterestBySystem, penaltiesData,
      Period, loanPeriodFormat,true , status)
    updateRows(loanDetails)
  }
  
  const addNewPenaltyToStatement = (penaltyId, amount, penaltyDate, comment)=>{
    const updatedPenaltiesData = [ ...penaltiesData, {id: penaltyId, penaltyDate, penaltyAmount: amount, 
      comment}]
    const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
      paymentsData, duration, repaymentCycle, lateInterestBySystem, updatedPenaltiesData,
      Period, loanPeriodFormat,true, status)
    updateRows(loanDetails)
  }

  const getLoanAccount = async()=>{
    try{
      const loanAccount = await API.graphql(graphqlOperation(`query MyQuery {
        listLoanAccounts(filter: {loanId: {eq: "${loanId}"}}) {
          items {
            id
          }
        }
      }
      `))     
      if(loanAccount.data.listLoanAccounts.items.length > 0) {
        deleteLoanAccount(loanAccount.data.listLoanAccounts.items[0].id);
      }else {
        deleteLoan()
      }
    }
    catch(e){
      console.log('Error getting loanAccount', e)
    }
  }

  const deleteLoanAccount = async(loanAccountId) => {
    try{
      const deletedLoanAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteLoanAccount(input: {id: "${loanAccountId}"}) {
          id
        }
      }
      `))     
      if(deletedLoanAccount){
        deleteLoan()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete LoanAccount:::', e)
    }
  }

  const deleteLoan = async()=>{
    try{
      const deletedLoan = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteLoan(input: {id: "${loanId}"}) {
          id
        }
      }
      `))     
      if(deletedLoan){
        setLoansContext(null)
        setTimeout(()=> {
          setLoanDeleted(deletedLoan)
          setTimeout(()=> viewLoans(loanId), 1500)
        }, 500)
      }
    }
    catch(e){
      console.log('Loan deleting failed', e)
    }
  }

  const updatePayment = async(updatedRow) => {
    const {id, paymentAmount, date} = updatedRow
    const amount = parseFloat(paymentAmount.replace(/,/g, ''))
    const newDate = date ? `paymentDate: "${date}"` : ''
    try{
      const updatedPayment = await API.graphql(graphqlOperation(`mutation MyMutation {
        updatePayment(input: {
          id: "${id}", 
          amount: ${amount}, ${newDate}
        }) {
          id
        }
      }
      `))     
      if(updatedPayment){
        const updatedPaymentsData = paymentsData.map(payment =>
          (id === payment.id ? {id, paymentAmount: amount, paymentDate: date} : payment))
        const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
          updatedPaymentsData, duration, repaymentCycle, lateInterestBySystem, penaltiesData,
          Period, loanPeriodFormat,true, status)
        updateRows(loanDetails)
        addPayment()
      }
    }
    catch(e){
      console.log('ERROR: Unable to update Payment:::', e)
    }
  }

  const updatePenalty = async(updatedRow) => {
    const {id, instalmentDue, date, description} = updatedRow
    const amount = parseFloat(instalmentDue.replace(/,/g, ''))
    const newDate = date ? `penaltyDate: "${date}"` : ''
    try{
      const updatedPenalty = await API.graphql(graphqlOperation(`mutation MyMutation {
        updatePenalty(input: {
          id: "${id}", 
          penaltyAttribute1: "${description}", 
          amount: ${amount}, ${newDate}
        }) {
          id
        }
      }
      `))     
      if(updatedPenalty){
        const updatedPenaltyData = penaltiesData.map(penalty =>
          (id === penalty.id ? 
            {id, penaltyAmount: amount, penaltyDate: date, comment: description} 
            : penalty))
        const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
          paymentsData, duration, repaymentCycle, lateInterestBySystem, updatedPenaltyData,
          Period, loanPeriodFormat,true, status)
        updateRows(loanDetails)
        addPenalty()
      }
    }
    catch(e){
      console.log('ERROR: Unable to update Penalty:::', e)
    }
  }

  const deletePaymentAccount = async(paymentAccountId, paymentId) => {
    try{
      const deletedPaymentAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePaymentAccount(input: {id: "${paymentAccountId}"}) {
          id
        }
      }
      `))     
      if(deletedPaymentAccount){
        deletePayment(paymentId)
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete PaymentAccount:::', e)
    }
  }

  const getPaymentAccount = async(paymentId) => {
    //get Payment Accont 
    try{
      const paymentAccount = await API.graphql(graphqlOperation(`
      query MyQuery {
        listPaymentAccounts(filter: {paymentId: {eq: "${paymentId}"}}) {
          items {
            id
          }
        }
      }
      `))
      if(paymentAccount.data.listPaymentAccounts.items.length > 0) {
        deletePaymentAccount(paymentAccount.data.listPaymentAccounts.items[0].id, paymentId);
      }else {
        deletePayment(paymentId)
      }
    }catch(e){
      console.log('ERROR getting paymentAccount:::', e)
    }
  }

  const deletePayment = async(paymentId) => {
    try{
      const deletedPayment = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePayment(input: {id: "${paymentId}"}) 
        {
          id
        }
      }
      `))     
      if(deletedPayment){
        const updatedPaymentsData = paymentsData.filter(payment => (paymentId !== payment.id))
        const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
          updatedPaymentsData, duration, repaymentCycle, lateInterestBySystem, penaltiesData,
          Period, loanPeriodFormat,true, status)
        updateRows(loanDetails)
        addPayment()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete Payment:::', e)
    }
  }

  const deletePenalty = async(penaltyId) => {
    try{
      const deletedPenalty = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePenalty(input: {id: "${penaltyId}"}) 
        {
          id
        }
      }
      `))     
      if(deletedPenalty){
        const updatedPenaltyData = penaltiesData.filter(penalty => (penaltyId !== penalty.id))
        const loanDetails = createLoanStatement(LoanAmount, startDate, InterestRate, 
          paymentsData, duration, repaymentCycle, lateInterestBySystem, updatedPenaltyData,
          Period, loanPeriodFormat,true, status)
        updateRows(loanDetails)
        addPenalty()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete Penalty:::', e)
    }
  }

  const goBack = () => {
    updatedPayments[0] && updateLoans(updatedPayments[0], updatedPayments[1])
    viewLoans()
  }

  const updateLoanDetails = (newLoanDetails) => {
    if(updatedPayments[0]){
      updateLoans(updatedPayments[0], updatedPayments[1], loanId, newLoanDetails)
    }else{
      updateLoans(null, null, loanId, newLoanDetails)
    }
    viewLoans()
  }

  const enableSystemInterest =(e)=>{
    setLateInterestBySystem(e.target.checked);
    stopInterest(e.target.checked);
  }

  const DataGridTitle = ()=>{
    return(
      <Box className="dataGridTitle" mb='30px'>
        <Box display='flex' flexDirection='column' alignItems='space-between'>
          {((printWidth !== printerWidth) || pdfSaving) && 
          <Box color="black">
            <Box display='flex' justifyContent='space-between' gap='20px' 
              mt = {pdfSaving ? 0 : '-30px'}
              mb='10px'>
                <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
                <p style={{color: 'grey'}}>Printed on: {dateToday}</p>
            </Box>
            <Box display='flex' flexDirection={'column'} mb="20px">
              <Typography variant="h2"
                sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
                {`${hasBusinessName.toUpperCase()}`}</Typography>
              <Typography variant='h3'> LOAN STATEMENT</Typography>
            </Box>
          </Box>
          }
          <Box 
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '20px',
              '@media (max-width: 768px)': {
                gridTemplateColumns: pdfSaving ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',
              },
              '@media print': {
                gridTemplateColumns: 'repeat(4, 1fr)',
              },
              color: (pdfSaving || (printWidth !== printerWidth)) ? 'black' : 'white',
            }}
          >
              <Typography >Borrower:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{borrower.toUpperCase()}</Typography></Typography>
              <Typography >Loan ID:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{uniqueRef}</Typography></Typography>
              <Typography>Loan Start Date:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{startDate}</Typography></Typography>
              <Typography >Loan Duration:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${duration} ${loanPeriodFormat}`}</Typography></Typography>
              <Typography >Maturity Date:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{LoanEndDate}</Typography></Typography>
              <Typography >Loan Amount:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${LoanAmount}`}</Typography></Typography>
              <Typography >Interest Rate:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{InterestRate}% {Period}</Typography></Typography>
              <Typography >Amount Due:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${amountDue.toLocaleString()}`}</Typography></Typography>
              {loanStatement[4] <0.1
                && <Typography >Due on Maturity<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${loanStatement[0][loanStatement[0].length - 1].newPrincipal}`}</Typography></Typography>
              }
              <Typography >Repayment Cycle:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{loanRepaymentCycle}</Typography></Typography>
              <Typography >Total Payments Made:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${totalPayments}`}</Typography></Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  const columns = [
    {
      field: "date",
      sortable: false,
      headerName: "Date",
      type: 'date',
      width: printWidth*1.2,
      cellClassName: printWidth === printerWidth ? "name-column--cell": '' ,
      align: 'left',
      headerAlign: 'left',
      flex: printWidth === printerWidth ? 1 : '',
      editable: true,
      renderCell: (params)=>{
        const options = { day: "2-digit", month: "short", year: "2-digit" };
        return params.row.date ? params.row.date.toLocaleDateString("en-UK", options) : ''
      },
    },
    {
      field: "description",
      sortable: false,
      headerName: "Description",
      width: printWidth *2.5,
      editable: editingPenalty,
      align: 'left',
      headerAlign: 'left',
      flex: printWidth === printerWidth ? 1.5 : '',
      marginTop: borderMargin, marginBottom: borderMargin,
    },
    {
      field: "principal",
      sortable: false,
      headerName: "Principal",
      width: printWidth *1.3,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : ''
    },
    {
      field: "paymentAmount",
      sortable: false,
      headerName: "Payment Made",
      width: printWidth *1.3,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : '',
      editable: !editingPenalty,
      cellClassName: 'payment'
    },
    {
      field: "interestAmount",
      sortable: false,
      headerName: "Interest",
      width: printWidth *1.6,
      editable: editingPenalty,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : '',
      cellClassName: 'interest',
      renderCell: (params)=>( params.row.description === 'Payment Made' 
        ? (!pdfSaving && <Button variat="text" color={"info"} startIcon={<PrintIcon/>}
          size="small" className="printNot" 
          onClick={()=>{
            setPaymentReceiptDetails(params)
            setTimeout(()=>downloadReceipt(), 300)
            setTimeout(()=>{
              setPaymentReceiptDetails(null)
            }, 500)
          }}>Receipt</Button>)
        : params.row.interestRate
      )
    },
    {
      field: "newPrincipal",
      sortable: false,
      headerName: "New Principal",
      width: printWidth *1.6,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : ''
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: printWidth === printerWidth ? 'Edit/Delete' : '',
      // width: 100,
      cellClassName: pdfSaving ? 'actions' : 'printNot',
      getActions: (params) => {
        return ((params.row.description === 'Payment Made')||
          (params.row.description.includes("PENALTY"))) ? editableRow(params) : []
      }
    }
  ]

  const columnsWithoutFlex = ()=>{
    return columns.map((field, index)=>{
      field.key = index;
      field.flex = '';
      return field
    })
  }
  
  const initialColumns = window.innerWidth >= 900 ? columns : columnsWithoutFlex();

  const downloadPDF = () => {

    setPdfSaving(true)
    setPrintWidth(82)
    setPrintColor('black')
    setTimeout(()=>{
      generatePDF(() => document.getElementById("schedule"), {
        method: "save",
        filename: `${borrower.toUpperCase()} LOAN STATEMENT.pdf`,
        page: { margin: Margin.MEDIUM },
      });
      setTimeout(()=>{
        setPdfSaving(false);
        setPrintWidth(printerWidth); 
        setPrintColor(colors.grey[100])
      }, 100)
    }, 500)
  };

  const downloadReceipt = () => {
    generatePDF(() => document.getElementById("receipt"), {
      method: "save",
      filename: `${borrower.toUpperCase()} PAYMENT RECEIPT.pdf`,
      page: { margin: Margin.MEDIUM },
    });
  }
  
  return (<>
  {/* payment reciept*/}
  {paymentReceiptDetails && <Box id='receipt' width="900px">
    <PaymentReceipt paymentDetails={paymentReceiptDetails.row}
      borrower={borrower.toUpperCase()} principal={LoanAmount} startDate={startDate} currency={currency}
      maturityDate={LoanEndDate} amountDue={amountDue} totalPayments={totalPayments}/>
    </Box>}

  {/*other content*/}
    {loanStatement && !paymentReceiptDetails && <Box>
      {loanDeleted && <Box m="60px">
            <Header title={'LOAN DELETED SUCCESSFULLY'} />
          </Box>}
      {!loanDeleted && <Box id='schedule'
        sx={{
          width: pdfSaving ? '830px' : {xs: '100%', md: '900px'}
        }}
      >
      {!editLoan && <Box> 
        {!pdfSaving && <Box display='flex'
            sx={{
              m: {xs: '-20px 0 20px 0', md: '-73px 0 40px 300px'},
              gap: {xs: '10px', md: "30px"},
              justifyContent: {xs: 'space-between', md: 'flex-start'}
            }}
            className='printNot'>
          <Button startIcon={<Download />}
            onClick={downloadPDF}
            color='info' 
            type="button">
            Download PDF</Button>
          <Button startIcon={<PrintIcon />}
            onClick={()=>{
              setPrintWidth(60)
              setPrintColor('black')
              setTimeout(()=>window.print(), 300)
              setTimeout(()=>{
                setPrintWidth(printerWidth);
                setPrintColor(colors.grey[100])
              }, 500)
            }} color="info" className="desktopOnly"
            type="button">
            PRINT STATEMENT</Button>
            <Button
            onClick={goBack} type="button" color="warning" variant="outlined">
              GO BACK
          </Button>
        </Box>}
        </Box>}
          <DataGridTitle />
          {editLoan && <EditLoanValues loanId={loanId} addPayment={addPayment} status={status}
            cancelLoanEdit={()=> setEditLoan(false)} borrower={borrower} borrowerID={borrowerID}
            updateLoanDetails={updateLoanDetails} initialDetails={initialDetails}
            uniqueRef={uniqueRef}/>}
          {!editLoan && <Box
            m="40px 0 40px 0"
            sx={{
              marginTop: "-10px",
              "& .actions": {
                display: pdfSaving ? 'none' : 'flex'
              },
              "& .MuiDataGrid-root": {
                border: 'none',
                color: pdfSaving ? 'black' : ''
              },
              "& .MuiDataGrid-cell": {
                borderColor: printColor,
                height: "50px !important"
              },
              "& .name-column--cell": {
                color: printWidth === printerWidth ? colors.greenAccent[300] : printColor
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: pdfSaving ? 'white' : colors.blueAccent[700],
                color: printWidth === printerWidth ? colors.greenAccent[300] : printColor
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: pdfSaving ? 'white' : colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: pdfSaving ? 'white' : colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: pdfSaving ? 'white' : `${colors.greenAccent[200]} !important`,
              },
              " .payment": {
                color: printWidth === printerWidth ? colors.greenAccent[200] : printColor,
              },
              " .interest": {
                color: printWidth === printerWidth ? colors.redAccent[200] : printColor,
              },
            }}
          >
          {!pdfSaving && (printWidth === printerWidth) && <>
          
          <Box display="flex" gap="10px" alignItems={'center'}
            className='printNot'>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}} mr='40px'>
              <Typography>LOAN STATUS:<br/>
                {!updatedStatus && <Typography
                  component={'span'} color={colors.blueAccent[300]}
                  variant="h3">{(function(){
                    return loanStatement[5].includes('override') 
                      ? loanStatement[5].toUpperCase().slice(12) + ` (OR)`: loanStatement[5].toUpperCase() 
                  })()}
                </Typography>}
                {updatedStatus && <Typography component={'span'} color={colors.blueAccent[300]}
                    variant="h3">{updatedStatus.toUpperCase().slice(12)}</Typography>} 
              </Typography>
            </Box>
            {!overrideStatus && !loanStatement[5].includes('override') &&
              !updatedStatus?.includes('override') &&
              <Button variant='outlined' color="error"
                disabled={buttonDisabled}
                onClick={()=>setOverrideStatus(true)}>
                Override Loan Status (Not recommended)</Button>}
            {overrideStatus && <div>
              <Typography color={colors.redAccent[300]}>Override Loan Status to:</Typography>
              <Button disabled={buttonDisabled}
                onClick={()=>{
                updateLoans(null, null, loanId, null, 'override to cleared')
                updateStatus('override to cleared')
                setTimeout(()=>setOverrideStatus(null), 1000)
              }} type="button" color="warning" variant="outlined">
                "CLEARED"
              </Button>
            </div>}
            {(loanStatement[5].includes('override') || updatedStatus?.includes('override')) && 
            <Button variant='outlined' color="success" disabled={buttonDisabled}
            onClick={()=>{
              updateLoans(null, null, loanId, null, 'overdue') 
              updateStatus('overdue')
              setTimeout(()=>{viewLoans()}, 2000)
            }} type="button"
           >Cancel Status override (recommended)</Button>}
          </Box>
          {(loanStatement[5].includes('overdue') || updatedStatus?.includes('overdue')) &&
          <FormGroup className='printNot' >
            <FormControlLabel control={
              <Checkbox
                disabled={buttonDisabled}
                color="info"
                defaultChecked={lIBS} 
                onChange={enableSystemInterest}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
              />} 
            label="Continue to charge Interest on the Outstanding Amount past the Maturity Date" />
          </FormGroup>}
          <Box mb="20px" mt="20px" display="flex" gap="20px" flexWrap={'wrap'}
            justifyContent='space-between' className='printNot' >
            <Button onClick={()=>{
                setPaymentWindow(true)
                setPenaltyWindow(false)
              }} type="button" disabled={buttonDisabled} 
              startIcon={<AddIcon />} color="info" variant="outlined">
              ADD PAYMENT
            </Button>
            <Button onClick={()=>{
                setPenaltyWindow(true)
                setPaymentWindow(false)
              }} type="button" disabled={buttonDisabled} 
              startIcon={<ErrorOutlineIcon />} color="warning" variant="outlined">
              ADD PENALTY
            </Button>
            <Button onClick={editLoanDetails} type="button" disabled={buttonDisabled} 
              startIcon={<EditIcon />} color="info" variant="outlined">
              EDIT LOAN DETAILS
            </Button>
            {!deleteConfirm && <Button onClick={()=>setDeleteConfirm(true)} 
                type="button" disabled={buttonDisabled}
                startIcon={<DeleteOutlineIcon />} color="error" variant="outlined">
                DELETE LOAN
              </Button>}
              {deleteConfirm && <Box display="flex" gap="20px">
                <Button onClick={getLoanAccount} type="button" disabled={buttonDisabled}
                  startIcon={<DeleteOutlineIcon />} color="error" variant="outlined">
                  YES, DELETE!
                </Button>
                <Button onClick={()=>setDeleteConfirm(false)} type="button" disabled={buttonDisabled}
                  startIcon={<Cancel />} color="warning" variant="outlined">
                  DON'T DELETE
                </Button>
              </Box>}
          </Box>
          {paymentWindow && <Box mt="20px">
            <NewPaymentOnStatement loanId={loanId} paymentAdded={paymentAdded} 
            addNewPaymentToStatement={addNewPaymentToStatement}
            setAmountPaid={setAmountPaid} cancelPayment={cancelPayment} currency={currency}/>
          </Box>}
          {penaltyWindow && <Box mt="20px">
            <NewPenalty loanId={loanId} penaltyAdded={penaltyAdded} 
              addNewPenaltyToStatement={addNewPenaltyToStatement}
              setAmountPaid={setAmountPaid} cancelPenalty={cancelPenalty} currency={currency}/>
          </Box>}
          {paymentMessage && <Box>
            <SuccessMessage message={paymentMessage} />
          </Box>}
          </>}
          <Typography variant="h4"
            color={printWidth === printerWidth ? colors.grey[100] : colors.grey[900]}>
            Transactions:</Typography>
          <Box marginTop={"-60px"} >
            {initialColumns && <DataGrid rows={rows} columns={initialColumns}
                apiRef={gridRef}
                autoHeight={true}
                hideFooter={true}
                getRowHeight={() => 'auto'}
                sx={{
                  marginTop: 10,
                  '@media print': {
                    '.MuiDataGrid-main': {
                      color: 'rgba(0, 0, 0, 0.87)',
                    },
                    '.dataGridTitle': {display: 'block'},
                  },
                  '@media (max-width: 900px)': {
                    width: "100%",
                    overflowX: 'auto'
                  }
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                initialState={{
                  sorting: {
                      sortModel: [{field: 'date', sort: 'asc'}],
                  },
                }}
            />}
          </Box>
        </Box>}
      </Box>}
    </Box>}
  </>);
};

export default LoanStatementForLumpSum;