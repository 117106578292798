import { useParams, useNavigate } from 'react-router-dom'
import Header from "../../components/Header"
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useRef, useEffect, useContext } from "react";
import { LoansContext, UserIDContext, UserTypeContext } from '../../App';
import { API, graphqlOperation } from "aws-amplify";
import { DatePickerField } from '../../components/DatePicker';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
// import { scheduleLoan} from '../../assets/loanCalculations';
import { scheduleLoan } from '../../assets/loanCalculationsPerDayInt';
import { formatNumber } from '../../assets/loanCalculations';
// import { scheduleFlatLoans } from '../../assets/moreLoanCalculations';
import LoanSchedule from '../../components/LoanSchedule';
import "../../assets/custom.css";
import LoanScheduleForLumpSum from '../../components/LoanScheduleForLumpSum';
import { tokens } from "../../theme";
import PointsManager from "../../components/PointsManager";
import AlertMessage from "../../components/AlertMessage"; 
import { currenciesObj } from '../../assets/currenciesObj';
import { numberRegex } from '../../assets/currenciesObj';
import QuantityInput from '../../components/QuantityInput';
import { loanIdIsUnique } from '../../assets/loanPanelCalculations';

const NewLoanByBorrower = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {id, fullname} = useParams()
    const [loanSchedule, setLoanSchedule] = useState()
    const [flatLoanSchedule, setFlatLoanSchedule] = useState()
    const [flatLumpSumSchedule, setFlatLumpSumSchedule] = useState()
    const [loanScheduleForLumpSum, setLoanScheduleForLumpSum] = useState()
    const [loanDetails, setLoanDetails] = useState()
    const [maxPeriod, setMaxPeriod] = useState()
    const [theDate, setTheDate] = useState()
    const [processing, setProcessing] = useState(false) 
    const [validSubmission, setValidSubmission] = useState(false) 
    const ref = useRef(null);
    const topref = useRef()
    const navigate = useNavigate();
    const {setLoansContext, loanIdArray} = useContext(LoansContext);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userID = useContext(UserIDContext)
    const {accounts, userCurrency, userTypeAWS, decimalPoints} = useContext(UserTypeContext);
    const corporate = userTypeAWS === 'ENTERPRISE' ? true : false
    const currencies = Object.keys(currenciesObj)

    const filteredAccounts = (currency) => {
      const loanAccounts = accounts.filter(
        account => ((account.accountStatus !== 'inactive') && (account.currency === currency))
      )
      return loanAccounts.length > 0 ? loanAccounts : [{noAccountFound: true, id: ''}]
    }
    const [activeAccounts, setActiveAccounts] = useState(filteredAccounts(userCurrency))
    const [repaymentCycle, setRepaymentCycle] = useState('instalments')
    const [loanDuration, setLoanDuration] = useState(1)
    const [loanPeriodFormat, setLoanPeriodFormat] = useState('months')
    const [numberOfPayments, setNumberOfPayments] = useState(1)
    const [instalmentFrequency, setInstalmentFrequency] = useState('per month')
    const [idNotUnique, setIdNotUnique] = useState()

    //useEffect for number of payments
    useEffect(()=>{
      if(loanPeriodFormat === 'days') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration/7));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration/30));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/365));
            break;
        }
      }
      if(loanPeriodFormat === 'weeks') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*7);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration/4));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/52));
            break;
        }
      }
      if(loanPeriodFormat === 'months') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*30);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration*4));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration/12));
            break;
        }
      }
      if(loanPeriodFormat === 'years') {
        switch(instalmentFrequency) {
          case 'per day': 
            setNumberOfPayments(loanDuration*365);
            break;
          case 'per week': 
            setNumberOfPayments(Math.ceil(loanDuration*52));
            break;
          case 'per month': 
            setNumberOfPayments(Math.ceil(loanDuration*12));
            break;
          case 'per year': 
            setNumberOfPayments(Math.ceil(loanDuration));
            break;
        }
      }
      repaymentCycle.includes('Sum') && setNumberOfPayments(1)
      
    }, [loanPeriodFormat, instalmentFrequency, loanDuration, repaymentCycle])

    const initialValues = {
      principal: '',
      fees: '',
      interestRate: '',
      borrowerLoanID: id.slice(1),
      startDate: '',
      rateFormat: "per month",
      durationFormat: 'months',
      repaymentCycle: 'instalments',
      loanAccount: activeAccounts[0].id,
      feesAccount: activeAccounts[0].id,
      loanCurrency: userCurrency,
      instalmentFrequency: 'per month', 
      duration: 1,
      uniqueRef: loanIdIsUnique(null, null, loanIdArray)
    };
    const goToLoans =(principal) =>{
      setLoansContext(null)
      navigate(`/loansPanel/${principal}/${fullname}`)
    }
    
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const editLoan = () => {
      topref.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleFormSubmit = (values) => {
      if(loanIdIsUnique(values.uniqueRef, null, loanIdArray)){
        setIdNotUnique(false)
        validSubmission && honorSubmit(values)
      }else {
        setIdNotUnique(true)
      }
    }

    const honorSubmit = (values) => {
      setValidSubmission(false)
      values.startDate = new Date(theDate)
      const newLoan = {
        principal: parseFloat(values.principal.replace(/,/g, '')),
        fees: values.fees===''? 0 : parseFloat(values.fees.replace(/,/g, '')),
        interestRate: parseFloat(values.interestRate),
        borrower: id.slice(1),
        startDate: values.startDate,
        duration: values.duration,
        rateInterval: (values.repaymentCycle === 'instalments') && (values.rateFormat === 'per loan')
          ? 'per month' : values.rateFormat,
        durationInterval: values.durationFormat,
        repaymentCycle: values.repaymentCycle,
        feesAccountId: values.feesAccount.length >1 ? values.feesAccount : activeAccounts[0].id,
        loanAccountId: values.loanAccount.length >1 ? values.loanAccount : activeAccounts[0].id,
        loanCurrency: values.loanCurrency,
        numberOfPayments,
        instalmentFrequency: values.instalmentFrequency,
        uniqueRef: values.uniqueRef,
      };
      setLoanDetails(newLoan)
      const nullify = () => {
        setLoanSchedule(null)
        setFlatLoanSchedule(null)
        setLoanScheduleForLumpSum(null)
        setFlatLumpSumSchedule(null)
      }
        
      const schedule = scheduleLoan(
        newLoan.principal, newLoan.interestRate, newLoan.duration, 
        newLoan.startDate, newLoan.rateInterval, newLoan.durationInterval,
        newLoan.repaymentCycle, decimalPoints, numberOfPayments, 
        newLoan.instalmentFrequency
      )
      setMaxPeriod(schedule[4])

      if(newLoan.repaymentCycle === 'instalments'){
        nullify()
        setLoanSchedule(schedule)
      }
      if(newLoan.repaymentCycle.includes('Sum')){
        nullify()
        setLoanScheduleForLumpSum(schedule)
      }
      if(newLoan.repaymentCycle === 'flatInstalments'){
        nullify()
        setFlatLoanSchedule(schedule)
      }
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    };

    const createLoanFeesAccount = async(loanFeesId) => {
      try{
        const createdLoanFeesAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
          createLoanFeesAccount(input: {
            accountId: "${loanDetails.feesAccountId}", 
            loanFeesId: "${loanFeesId}", 
          }) {
            id
          }
        }
        `))     
        if(createdLoanFeesAccount){
          goToLoans(loanDetails.principal)
        }
      }
      catch(e){
        console.log('Error Creating LoanFeesAccount', e)
      }
    }

    const createLoanFees = async(loanId) => {
      try{
        const createdLoanFees = await API.graphql(graphqlOperation(`mutation MyMutation {
          createLoanFees(input: {
            amount: ${loanDetails.fees}, 
            loanLoanFeesId: "${loanId}"
          }) {
            id
          }
        }
        `))     
        if(createdLoanFees){
          createLoanFeesAccount(createdLoanFees.data.createLoanFees.id)
        }
      }
      catch(e){
        console.log('Error Creating LoanFees', e)
      }
    }

    const createLoanAccount = async(loanId) =>{
      try{
        const createdLoanAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
          createLoanAccount(input: {
            accountId: "${loanDetails.loanAccountId}", 
            loanId: "${loanId}",
          }) {
            id
          }
        }
        `))     
        if(createdLoanAccount){
          if((loanDetails.loanAccountId != loanDetails.feesAccountId) && (loanDetails.fees >0)){
            createLoanFees(loanId)
          }else {
            goToLoans(loanDetails.principal)
          }
        }
      }
      catch(e){
        console.log('Error Creating LoanAccount', e)
      }
    }

    const createLoan =  async() =>{
      setProcessing(true)
      const checkFeesSameAccount = ()=>{
        if((loanDetails.loanAccountId === loanDetails.feesAccountId) && (loanDetails.fees >0)){
          return loanDetails.fees
        }else return 0
      }
      const feesAccount = checkFeesSameAccount()
      try{
        // eslint-disable-next-line
        const createdLoan = await API.graphql(graphqlOperation(`mutation MyMutation {
          createLoan(input: {
            borrowerLoansId: "${loanDetails.borrower}", 
            fees: ${feesAccount}, 
            interestRate: ${loanDetails.interestRate}, 
            principal: ${loanDetails.principal}, 
            startDate: "${loanDetails.startDate}",
            duration: ${loanDetails.duration},
            durationInterval: "${loanDetails.durationInterval}",
            rateInterval: "${loanDetails.rateInterval}",  
            laonType: "${loanDetails.repaymentCycle}",  
            loanStatus: "current",
            loanCurrency: "${loanDetails.loanCurrency}",
            numberOfPayments: ${numberOfPayments}, 
            paymentFrequency: "${instalmentFrequency}",
            uniqueRef: "${loanDetails.uniqueRef}"
          })  
          {
            id
          }
        }
        `))     
        if(createdLoan){
          console.log('createdLoan::: ', createdLoan);
          createLoanAccount(createdLoan.data.createLoan.id)
        }
      }
      catch(e){
        console.log('Loan creation Failed', e)
      }
    }
  
    return (
      <Box pt="20px" mb="80px" 
        sx={{width: {xs: '100%', md: '800px', overflow: 'hidden'}}}
        // sx={{overflow: 'hidden'}}
      >
          <PointsManager />
          <Box className='printNot'>
            <Box ref={topref}>
              <Header title={`New Loan for ${fullname}`} subtitle="Enter the Loan Details" />
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                  <Box sx={{ gridColumn: "span 2", pr: {md: '10px'} }} >
                    <Typography
                      color={colors.greenAccent[300]}>Loan ID:</Typography>
                    <Box  className="newLoanBox" >
                      <Box sx={{width: '100%'}}>
                        <CssTextField focusColor={labelFocusColor}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Set a Unique Loan ID for this loan"
                            onBlur={handleBlur}
                            onChange={(e)=>{
                              handleChange(e)
                              setIdNotUnique(false)
                            }}
                            value={values.uniqueRef}
                            name="uniqueRef"
                            error={!!touched.uniqueRef && !!errors.uniqueRef}
                            helperText={touched.uniqueRef && errors.uniqueRef}
                          />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ gridColumn: "span 2", pl: {md: '10px'} }} >
                    <Typography
                      color={colors.greenAccent[300]}>Currency:</Typography>
                    <Box  className="newLoanBox" >
                        <Box display="flex" flexDirection={'row'} gap="20px"
                          flexWrap={'wrap'}>
                          <Typography >
                            Select a Currency for this loan</Typography>
                          <Field
                            as="select"
                            name="loanCurrency"
                            disabled={!corporate}
                            onChange={(e)=>{
                              setActiveAccounts(filteredAccounts(e.target.value))
                              handleChange(e)
                            }}
                          >{currencies.map((currency, i)=>(
                            <option value={currency} key={i}>{currency}</option>
                          ))}
                          </Field>
                      </Box>
                    </Box>
                    {!corporate && <Box mt="-20px" mb="30px">
                      <AlertMessage buttonText={'Enable Multiple Currencies'}
                        message={<Box>
                          <Typography>Your system currency is {userCurrency}.</Typography>
                          <Typography>Multiple Currencies are only Available on Enterprise Packages</Typography>
                        </Box>}
                        button1={<Button
                          onClick={()=> window.open('https://www.loantabs.com/enterprise', '_blank')} variant="contained"
                          color="info" m="20px">Upgrade to Enterprise</Button>}
                      />
                    </Box>}
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Principal:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" flexDirection={'row'} gap="20px" flexWrap={'wrap'}>
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                        <CssTextField focusColor={labelFocusColor}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="How much is being borrowed?"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.principal.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            name="principal"
                            error={!!touched.principal && !!errors.principal}
                            helperText={touched.principal && errors.principal}
                          />
                      </Box>
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                          <Box>
                            <Typography>
                              Which Account should this money be disbursed from?</Typography>
                            <Field
                              as="select"
                              name="loanAccount"
                              onChange={handleChange}
                            >{activeAccounts.map((item)=>{
                              const loanCurrency = item.currency || 'UGX'
                              return(
                                item?.noAccountFound
                                ? <option value={item.id} key={item.id}>
                                    No Active Accounts found for Selected Currency
                                  </option>
                                : <option value={item.id} key={item.id}>
                                  {`${item.accountName} (${loanCurrency})`}
                                </option>
                              )
                            })}
                            </Field>
                          </Box>
                      </Box>
                      {/* <Box width="12%">
                        {activeAccounts[0].noAccountFound &&
                          <Button size="small" variant='contained' color="info"
                            onClick={()=> navigate('/createAccount')}>Create New Account</Button>}
                      </Box> */}
                    </Box>
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Repayment Cycle:</Typography>
                  <Box role="group" aria-labelledby="my-radio-group"
                    sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" justifyContent={'space-between'}
                      flexWrap={'wrap'} sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                      <Box gap="10px" sx={{display: 'flex', flexDirection: 'column', 
                        width: {xs: '100%', md: '48%'}}}>
                        <Box >
                          <label>
                            <Field type="radio" name="repaymentCycle"
                            value="instalments" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                            }}/>
                            {`Equal Instalments - Reducing Balance`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          // message={`The interest is calculated on the unpaid balance.\n
                          // The payments are distributed into equal instalments.`}
                          message={<Box>
                            <Typography>The interest is calculated on the unpaid balance.</Typography>
                            <Typography>The payments are distributed into equal instalments.</Typography>
                          </Box>}/>
                        </Box>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle"
                            value="flatInstalments" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                            }}/>
                            {`Equal Instalments - Flat Rate`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`The interest is calculated on the Principal and Duration at the start of the loan. \n
                          The payments are distributed into equal instalments.`}/>
                        </Box>
                      </Box>
                      <Box gap="10px" sx={{display: 'flex', flexDirection: 'column', 
                        width: {xs: '100%', md: '48%'}, mt: {xs: '20px', md: 0}}}>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle" 
                            value="lumpSum" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                              setNumberOfPayments(1)
                            }}/>
                            {`Lump-sum Payment - Compound Interest`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`Interest is compounded per month: \n(NewMonthPricipal = PastMonthPricipal + Interest).\n
                          One lumpsum payment is made at the end of the loan duration.`}/>
                        </Box>
                        <Box>
                          <label>
                            <Field type="radio" name="repaymentCycle" 
                            value="simpleLumpSum" onChange={(e)=>{
                              handleChange(e)
                              setRepaymentCycle(e.target.value)
                              setFieldValue('repaymentCycle', e.target.value);
                              setNumberOfPayments(1)
                            }}/>
                            {`Lump-sum Payment - Simple Interest`}
                          </label>
                          <AlertMessage buttonText={'Explain more'}
                          message={`Interest is calculated on the Principal and Duration.\n
                          One lumpsum payment is made at the end of the loan duration.`}/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Interest Rate:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" flexDirection={'row'} gap="20px"
                      flexWrap={'wrap'}>
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Loan Interest %"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.interestRate}
                        name="interestRate"
                        error={!!touched.interestRate && !!errors.interestRate}
                        helperText={touched.interestRate && errors.interestRate}
                        sx={{ width: {xs: "100%", md: "48%"} }}
                      />
                      <Box display='flex' gap="20px">
                        <Box role="group" aria-labelledby="my-radio-group"
                          sx={{display: 'flex', flexDirection: 'row'}} >
                          <Box gap="10px" mr="10px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per day" />
                                Per Day
                              </label>
                            </Box>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per week" />
                                Per Week
                              </label>
                            </Box>
                          </Box>
                          <Box gap="10px" mr="15px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per month" />
                                Per Month
                              </label>
                            </Box>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per year" />
                                Per Year
                              </label>
                            </Box>
                          </Box>
                          {repaymentCycle !== 'instalments' && repaymentCycle !== 'lumpSum'
                          && <Box gap="10px" sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box>
                              <label>
                                <Field type="radio" name="rateFormat" value="per loan" />
                                Per Loan
                              </label>
                            </Box>
                          </Box>}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Start Date:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="15px"
                      sx={{flexDirection: {xs: 'column', md: 'row'}, 
                        alignItems: {xs: 'flex-start', md: 'center'}}}>
                        <Typography >Set the Start Date of the loan: </Typography>
                        <DatePickerField name="startDate" setTheDate={setTheDate}/>
                    </Box>
                    {values.startDate === '' &&
                    <Typography fontSize="11px" color="red" mt="10px">required</Typography>}
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Loan Duration:</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="20px"
                      sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                      <Box display="flex" gap="10px"
                        sx={{flexDirection: {xs: 'column', md: 'row'},
                          alignItems: {xs: 'flex-start', md: 'center' },
                          justifyContent: {xs: 'flex-start', md: 'center' }
                        }}>
                        <Typography>Set the duration of the loan: </Typography>
                        <Box>
                          <QuantityInput
                            number={loanDuration}
                            setNumber={setLoanDuration}
                            setValidSubmission={setValidSubmission}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                      </Box>
                      <Box display='flex' width="23%" flexDirection={'column'}
                        sx={{width: {xs: '130px', md: '23%'}}}
                        justifyContent={'center'}>
                          {/* <Typography>Period:</Typography> */}
                          <Field
                            as="select"
                            name="durationFormat"
                            onChange={(e)=>{
                              setLoanPeriodFormat(e.target.value)
                              setFieldValue('durationFormat', e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value="days">Day(s)</option>
                            <option value="weeks">Week(s)</option>
                            <option value="months">Month(s)</option>
                            <option value="years">Year(s)</option>
                          </Field>
                          {maxPeriod && <p style={{color: 'red'}}>Max Period is 8 Years</p>}
                      </Box>
                    </Box>
                    
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Number of Payments (Instalments):</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="20px"
                      sx={{flexDirection: {xs: 'column', md: 'row'},
                      alignItems: {xs: 'flex-start', md: 'center' }
                      }}>
                      {!repaymentCycle.includes('Sum') &&
                      <Box display='flex' gap="20px"
                          sx={{flexDirection: {xs: 'column', md: 'row'},
                          alignItems: {xs: 'flex-start', md: 'center' },
                        }}>
                          <Typography sx={{width: {xs: '100%', md: '30%'}}}
                            >How often should a payment be made?</Typography>
                          <Box role="group" aria-labelledby="my-radio-group" gap="10px"
                            sx={{display: 'flex', flexDirection: 'column', width: {xs: '100%', md: '68%'}}}>
                              <Box display="flex" flexDirection={'row'} gap="20px">
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                    setFieldValue('instalmentFrequency', e.target.value)
                                  }}
                                  value="per day" />
                                  1 per Day
                                </label>
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per week" />
                                  1 per Week
                                </label>
                              </Box>
                              <Box display="flex" flexDirection={'row'} gap="20px">
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per month" />
                                  1 per Month
                                </label>
                                <label>
                                  <Field type="radio" name="instalmentFrequency"
                                  disabled={repaymentCycle.includes('Sum')}
                                  onChange={(e)=>{
                                    setInstalmentFrequency(e.target.value);
                                    handleChange(e)
                                  }}
                                  value="per year" />
                                  1 per Year
                                </label>
                              </Box>
                          </Box>
                      </Box>}
                      <Box sx={{width: {xs: '100%', md: '48%'}}} display="flex" flexDirection={'row'} 
                        gap="10px" alignItems={'baseline'}>
                        <Typography variant="h3" color={colors.greenAccent[300]}
                          >{numberOfPayments}</Typography>
                        <Typography variant="h5">INSTALMENT(S) SCHEDULED</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ gridColumn: "span 4" }}
                    color={colors.greenAccent[300]}>Loan Fees (optional):</Typography>
                  <Box sx={{ gridColumn: "span 4" }} className="newLoanBox">
                    <Box display="flex" gap="20px"
                      sx={{flexDirection: {xs: 'column', md: 'row'},
                        alignItems: {xs: 'flex-start', md: 'center' }
                      }}>
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                        <CssTextField focusColor={labelFocusColor}
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Enter the value of any additional fees"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fees.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            name="fees"
                          />
                      </Box>
                      <Box sx={{width: {xs: '100%', md: '48%'}}}>
                          <Box>
                            <Typography>
                              Select Account to receive the Loan Fees</Typography>
                            <Field
                              as="select"
                              name="loanAccount"
                              onChange={handleChange}
                            >{activeAccounts.map((item)=>{
                              const loanCurrency = item.currency || 'UGX'
                              return(
                                item?.noAccountFound
                                ? <option value={item.id} key={item.id}>
                                    No Active Accounts found for Selected Currency
                                  </option>
                                : <option value={item.id} key={item.id}>
                                  {`${item.accountName} (${loanCurrency})`}
                                </option>
                              )
                            })}
                            </Field>
                          </Box>
                      </Box>
                      {/* <Box width="12%">
                        {activeAccounts[0].noAccountFound &&
                          <Button size="small" variant='contained' color="info"
                            onClick={()=> navigate('/createAccount')}>Create New Account</Button>}
                      </Box> */}
                    </Box>
                  </Box>
    {/* submit button */}
                  </Box>
                  <Box display="flex" mt="10px" flexDirection={'column'}>
                    {idNotUnique && <Typography variant="h5" color='red'>
                      This Loan ID has already been assigned. Please use another Loan ID.
                    </Typography>}
                    <Box>
                      <Button type="submit" color="info" variant="contained"
                        disabled={ activeAccounts[0].noAccountFound || !theDate}
                        onClick={()=>setValidSubmission(true)}
                        >
                        {loanSchedule || loanScheduleForLumpSum || flatLoanSchedule ? 'Update Loan Schedule':'View Loan Shedule'}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          {processing && <Box display='flex'>
            <Typography
              variant="h4"
              color={colors.grey[100]} 
              fontWeight="bold"
              sx={{ m: "10px 0 60px 0" }}
            >Processing... Please Wait...</Typography>
          </Box>}
          <Box>
            <div ref={ref}>
            { loanSchedule &&
                  <LoanSchedule
                  loanData={loanSchedule[0]}
                  LoanAmount={formatNumber(loanDetails.principal)}
                  InterestRate={loanDetails.interestRate}
                  loanCurrency={loanDetails.loanCurrency}
                  Period={loanDetails.rateInterval}
                  Installment={loanSchedule[0][0].totalPayment}
                  noOfInstallments={loanSchedule[1]}
                  borrower={fullname}
                  editLoan={()=>editLoan()}
                  createLoan={()=>createLoan()}
                  Fees={formatNumber(loanDetails.fees)}
                  startDate={loanSchedule[3]}
                  LoanEndDate={loanSchedule[0][loanSchedule[0].length-1].date}
                  durationToDisplay={loanDuration}
                  durationFormatToDisplay={loanPeriodFormat}
                  />
              }
            { loanScheduleForLumpSum &&
                  <LoanScheduleForLumpSum
                  loanData={loanScheduleForLumpSum[0]}
                  LoanAmount={formatNumber(loanDetails.principal)}
                  InterestRate={loanDetails.interestRate}
                  loanCurrency={loanDetails.loanCurrency}
                  Period={loanDetails.rateInterval}
                  TotalPayment={loanScheduleForLumpSum[0][loanScheduleForLumpSum[0].length-1].amountToPay}
                  noOfInstallments={loanScheduleForLumpSum[1]}
                  borrower={fullname}
                  editLoan={()=>editLoan()}
                  createLoan={()=>createLoan()}
                  Fees={formatNumber(loanDetails.fees)}
                  startDate={loanScheduleForLumpSum[3]}
                  LoanEndDate={loanScheduleForLumpSum[0][loanScheduleForLumpSum[0].length-1].date}
                  durationToDisplay={loanDuration}
                  durationFormatToDisplay={loanPeriodFormat}
                  />
              }
              { flatLoanSchedule &&
                  <LoanSchedule
                  loanData={flatLoanSchedule[0]}
                  LoanAmount={formatNumber(loanDetails.principal)}
                  InterestRate={loanDetails.interestRate}
                  loanCurrency={loanDetails.loanCurrency}
                  Period={loanDetails.rateInterval}
                  Installment={flatLoanSchedule[0][0].totalPayment}
                  noOfInstallments={flatLoanSchedule[1]}
                  borrower={fullname}
                  editLoan={()=>editLoan()}
                  createLoan={()=>createLoan()}
                  Fees={formatNumber(loanDetails.fees)}
                  startDate={flatLoanSchedule[3]}
                  LoanEndDate={flatLoanSchedule[0][flatLoanSchedule[0].length-1].date}
                  durationToDisplay={loanDuration}
                  durationFormatToDisplay={loanPeriodFormat}
                  />
              }
            </div>
          </Box>
      </Box>
    );
  };
  const durationRegex = /^(?:[1-9]\d{0,2}|1000)$/;
  const interestRateRegEx = /^(0*(\d{1,2}(\.\d+)?)|\.(\d+)|100(\.0+)?)$/
  const uniqueRefRegex = /^[a-zA-Z0-9]{1,20}$/
  
  const checkoutSchema = yup.object().shape({
    principal: yup.string().matches(numberRegex, "must be a number").required("required"),
    interestRate: yup.string().matches(interestRateRegEx, "Must be a number. Min 0. Max 100.").required("required"),
    fees: yup.string().matches(numberRegex, "must be a number"),
    uniqueRef: yup.string().matches(uniqueRefRegex, "Only numbers and letters. Max 20 characters").required("required"),
  });
  
export default NewLoanByBorrower